import React, { useContext, useEffect } from "react";
import LoaderColored from "$/components/loaderColored";
import Column from "$/components/schedule/column";
import { ScheduleContext } from "$/contexts/schedule";
import { CurrentUserContext } from "$/contexts/currentUser";
import useFetch from "$/hooks/useFetch";
import moment from "moment";
import { IAppointment } from "$/models";
import bem from "bem-ts";
import "../index.scss";

const b = new bem("Schedule-Presentation", { strict: false });

const Week = () => {
  const [userState] = useContext(CurrentUserContext);
  const [scheduleStore, dispatchSchedule] = useContext(ScheduleContext);

  const apiUrl = "/doctor/appointments";
  const [{ response, isLoading }, fetchAppointments] = useFetch(apiUrl);

  useEffect(() => {
    if(typeof scheduleStore.currentTime === "number") {
      console.warn('Deprecated usage of number current time');
    }
    if (
      userState.currentUser &&
      typeof scheduleStore.currentTime !== "number"
    ) {
      // Get first and last day in a week:
      const firstIsoDay = moment(scheduleStore.currentTime)
        .isoWeekday(1)
        .format("YYYY-MM-DDT00:00");
      const lastIsoDay = moment(scheduleStore.currentTime)
        .isoWeekday(7)
        .format("YYYY-MM-DDT23:59");
      fetchAppointments &&
        fetchAppointments({ url: `${apiUrl}/${firstIsoDay}/${lastIsoDay}` });
    }
  }, [fetchAppointments, scheduleStore.currentTime, userState.currentUser]);

  useEffect(() => {
    if (response) {
      const columns = calculateWeekAppointments(
        response,
        scheduleStore.currentTime
      );
      dispatchSchedule({
        type: "SET_COLUMNS",
        payload: columns,
      });
    }
  }, [response]);

  return isLoading ? (
    <LoaderColored className={b("presentation__columns__loader")} />
  ) : (
    <div className={b("presentation__columns")}>
      {scheduleStore.columns.map((column) => {
        return (
          <Column
            date={column.date}
            key={column.keyColumn}
            keyColumn={column.keyColumn}
            title={column.title}
            participant={userState.currentUser}
            appointments={column.appointments}
          />
        );
      })}
    </div>
  );
};

export default Week;

const calculateWeekAppointments = (
  appointments: IAppointment[],
  timeNow: Date
): any => {
  const currentMoment = moment(timeNow);
  if(currentMoment.isoWeekday() === 7) {
    // currentMoment.subtract(1, 'week'); // for for NA locale
  }
  let weekdays: { title: string; appointments: IAppointment[] }[] = [
    // 0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
  ].map((day) => {
    return {
      keyColumn: moment(currentMoment).isoWeekday(day).format("ddd_D"),
      date: moment(currentMoment).isoWeekday(day).format("YYYY-MM-DD"),
      title: moment(currentMoment).isoWeekday(day).format("ddd, D MMM YYYY"),
      appointments: [],
    };
  });

  appointments.forEach((appointment: IAppointment) => {
    const date = moment(appointment.startDate);
    const weekday = date.isoWeekday() - 1;
    weekdays[weekday].appointments.push(appointment);
  });

  return weekdays;
};
