import {ChatStore} from '$/contexts/chat';
import {CurrentUserContext} from '$/contexts/currentUser';
import {NotificationsStore} from '$/contexts/notifications';
import {ScheduleContext} from '$/contexts/schedule';
import {WebSocketStore} from '$/contexts/webSocket';
import axios from 'axios';
import moment from 'moment';
import {useContext, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';

export default ({children}) => {
  const [scheduleState, dispatchSchedule] = useContext(ScheduleContext);
  const [, dispatchWebSockets] = useContext(WebSocketStore);
  const [notificationsStore, dispatchNotification] = useContext(
    NotificationsStore
  );
  const [, dispatchChat] = useContext(ChatStore);
  const [userState] = useContext(CurrentUserContext);

  const handlerNotification = (msg) => {
    const key = uuidv4();
    const payload = {};
    payload[key] = msg;
    const isSticky = msg?.sticky ?? false;
    console.debug(msg);

    dispatchNotification({
      type: isSticky ? 'ADD_NOTIFICATION' : 'ADD_NOTIFICATION_AUTO_CLEAR',
      payload,
    });

    if (msg.source === 'APPOINTMENT') {
      axios
        .get(
          `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/appointment/${msg.objectId}`,
          {withCredentials: true}
        )
        .then((res) => res.data)
        .then((res) => {
          const keyColumn =
            scheduleState.view === 'day'
              ? res.doctor.sithsid
              : moment(res.startDate).format('ddd_D');
          dispatchSchedule({
            type: 'ADD_APPOINTMENT',
            payload: {
              keyColumn,
              value: res,
            },
          });
        });
    } else if (msg.source === 'CHAT') {
      axios
        .get(
          `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/chat/${msg.objectId}`,
          {withCredentials: true}
        )
        .then((res) => res.data)
        .then((res) => {
          dispatchChat({
            type: 'UPDATE_CHAT',
            payload: res,
          });
        });

      //

    }

    const timer = setTimeout(() => {
      dispatchNotification({
        type: 'REMOVE_NOTIFICATION_AUTO_CLEAR',
        payload: key,
      });
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    if (userState.currentUser) {
      const payload = {};
      const id = uuidv4();
      payload[id] = handlerNotification;
      dispatchWebSockets({
        type: 'ADD_SUBSCRIBE',
        payload,
      });
    }

    return () => {
      dispatchWebSockets({
        type: 'UNSUBSCRIBE'
      });
    }
  }, [userState.currentUser]);

  return children;
};
