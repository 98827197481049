import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';

interface ITimePickerProps {
  date: Date;

  className?: string;

  onTimeChange: (date: Date) => void;
}

export const TimePicker: React.FC<ITimePickerProps> = (props: ITimePickerProps) => {
  const { date, className, onTimeChange } = props;

  const mDate = useMemo(() => moment(date), [ date ]);
  const [ tempDate, setTempDate ] = useState<string>(mDate.format('HH:mm'));
  useEffect(() => setTempDate(mDate.format('HH:mm')), [ mDate ]);

  const updateValue = () => {
    const [ hours, minutes ] = tempDate.replace(/_/g, '').split(':').map(Number);
    const newDate = moment(mDate).set({ hours, minutes });
    onTimeChange(newDate.toDate());
    setTempDate(newDate.format('HH:mm'));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempDate(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => updateValue();


  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateValue();
    }
  };

  return (
    <InputMask
      mask={'99:99'}
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      value={tempDate}
    />
  );
};
