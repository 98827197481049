import React from "react";
import { observer } from "mobx-react";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
  ComboboxPopover,
} from "@reach/combobox";

import "./index.scss";
import bem from "bem-ts";
const WIDTH = 350;
const b = new bem("AppointmentComments", { strict: false });

const Comments = ({ appointmentStore, ...props }) => {
  const onInput = (event) => {
    const target = event.target;
    const text = target && target.innerText;
    appointmentStore.setField("description", text);
  };

  return (
    <div className={b()}>
      <div className={b("prefix")}>
        <i className="far fa-comment" />
      </div>
      <Combobox style={{ width: WIDTH }}>
        <span
          role={"textarea"}
          type={"text"}
          onInput={onInput}
          contentEditable
          className={b("field")}
          style={{ width: WIDTH }}
        />
      </Combobox>
    </div>
  );
};

export default observer(Comments);
