import { NotificationsStore } from '$/contexts/notifications';
import Portal from '@reach/portal';
import { Link } from '@reach/router';
import axios from 'axios';
import bem from 'bem-ts';
import React, { useContext, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './index.scss';

const b = new bem('Notifications', { strict: false });

interface INotificationsProps {
  isGlobal?: boolean;
}

export default ({ isGlobal }: INotificationsProps) => {
  const [ notificationsStore ] = useContext(NotificationsStore);
  const notificationsList = isGlobal
    ? notificationsStore.notificationsAutoClear
    : notificationsStore.notifications;

  return (
    <Portal>
      {
        <div className={`${b()} ${isGlobal && b('isGlobal')}`}>
          <TransitionGroup>
            {Object.entries(notificationsList).map(([ id, object ]) => {
              return (
                <CSSTransition
                  unmountOnExit
                  key={id}
                  classNames={b('item')}
                  timeout={{ enter: 500, exit: 500 }}
                >
                  <NotificationItem
                    data={object}
                    id={id}
                    key={`notificationItem-${id}`}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </div>
      }
    </Portal>
  );
};

const NotificationItem = ({ id, data, ...props }: { id: string, data: any, key: string }) => {
  const [ , dispatchNotifications ] = useContext(NotificationsStore);
  const { message } = data;

  useEffect(() => {
    const root = document.getElementById('root');
    const handler = () => {
      dispatchNotifications({
        type: 'REMOVE_NOTIFICATION_AUTO_CLEAR',
        payload: id,
      });
    };
    root.addEventListener('click', handler);

    return () => {
      root.removeEventListener('click', handler);
    };
  }, []);

  const removeNotificationLocal = (id: string) => {
    dispatchNotifications({
      type: 'REMOVE_NOTIFICATION_AUTO_CLEAR',
      payload: id,
    });
  };

  const removeNotification = (id: string) => {
    dispatchNotifications({
      type: 'REMOVE_NOTIFICATION',
      payload: id,
    });
    removeNotificationLocal(id);
  };

  const undo = (url: string) => {
    axios
      .get(url, { withCredentials: true })
      .then((res) => window.location.reload());
  };

  const resolveUrlAction = (url: string) => {
    switch (true) {
      case url.indexOf('/doctor/appointment/') === 0:
      case url.indexOf('/doctor/chat') === 0:
        return url.substring('/doctor'.length);
    }
    return url;
  };

  return (
    <div className={b('item')} key={props.key}>
      {message}

      {Object.entries(data.actionOwnerUrl).map(([ text, url ], index) => (
        <div
          className={`${b('item__buttons')}`}
          key={`notification-${id}-action-${index}`}
        >
          {text === 'Take a look' ? (
            resolveUrlAction(url as string) && (
              <Link
                to={`${resolveUrlAction(url as string)}`}
                onClick={() => removeNotification(id)}
              >
                {text}
              </Link>
            )
          ) : (
            <span
              onClick={() => {
                text === 'Undo'
                  ? undo(
                    `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}${url}`
                  )
                  : {};
              }}
            >
              {text}
            </span>
          )}
        </div>
      ))}

      <i
        className={'icon far fa-times'}
        onClick={() => removeNotification(id)}
      />
    </div>
  );
};
