import React, { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import bem from "bem-ts";

import "./index.scss";
import { AppointmentTypeEnum } from "$/models";
import { ScheduleContext } from "$/contexts/schedule";
import { Dialog } from "@reach/dialog";
import ScheduleEditingForm from "$/components/scheduleEditingForm";
import { IAppointment } from "../../models/index";

const b = new bem("Schedule-Editing-Slot", { strict: false });

export interface IScheduleEditingSlotProps {
  appointment: IAppointment;
  onChange: (appointment: IAppointment) => void;
}

const ScheduleEditingSlot: React.FC<IScheduleEditingSlotProps> = (props) => {
  const [scheduleStore] = useContext(ScheduleContext);
  const [appointment, setAppointment] = useState(props.appointment);
  const type = useMemo(() => appointment.appointmentType.toLowerCase(), [
    appointment,
  ]);
  const [showEditForm, setShowEditForm] = useState(false);
  const isLocked = appointment.status !== "WORK_TIME";
  const [isDeleted, setIsDeleted] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    const startDate = moment.utc(appointment.startDate).local();
    const endDate = moment.utc(appointment.endDate).local();

    const countMinutesBefore = moment(startDate).diff(
      moment(startDate).hour(scheduleStore.minHour).minute(0),
      "minutes"
    );
    const sizeRow = 41;
    const countMinutes = moment(endDate).diff(moment(startDate), "minutes");
    const height = (sizeRow / scheduleStore.zoom) * countMinutes;
    const top = (sizeRow / scheduleStore.zoom) * countMinutesBefore;

    setStyle({
      top: `${top}px`,
      minHeight: `${height}px`,
    });
  }, [appointment, scheduleStore.zoom]);

  let icon = useMemo(() => {
    switch (type.toUpperCase()) {
      case AppointmentTypeEnum[0]:
        return <i className="far fa-shoe-prints" />;
      case AppointmentTypeEnum[2]:
        return <i className="far fa-phone-alt" />;
      case AppointmentTypeEnum[1]:
        return <i className="far fa-video" />;
    }
    return <i className="far fa-shoe-prints" />;
  }, [appointment]);

  const { startDate, endDate } = appointment;
  const countMinutes = moment(endDate).diff(moment(startDate), "minutes");
  const hours = countMinutes / 60;

  const openEditForm = () => {
    !isLocked && setShowEditForm(true);
  };
  const closeEditForm = () => {
    setShowEditForm(false);
  };

  const onSend = (appointment) => {
    setAppointment(appointment);
    props?.onChange(appointment);
  };

  const onDelete = () => {
    setIsDeleted(true);
    props?.onChange(appointment);
  };

  return (
    <div
      data-id={appointment.id}
      data-time={`${appointment.startDate}  -  ${appointment.endDate}`}
      className={b([type, isLocked && "isLocked"])}
      style={{ ...style, display: isDeleted ? "none" : "flex" }}
      onClick={openEditForm}
    >
      <div style={props.style} className={b("container")} />
      <div style={props.style} className={b("background")} />

      <span className={b("time")}>
        <span>
          {icon}&nbsp;
          {isLocked ? (
            "Appointment"
          ) : (
            <>
              {moment.utc(appointment.startDate).local().format("HH:mm")}-
              {moment.utc(appointment.endDate).local().format("HH:mm")}
            </>
          )}
        </span>
        <span>{hours.toFixed(2)}h</span>
      </span>

      {showEditForm && (
        <Dialog aria-label={"EditTemplateForm"} onDismiss={closeEditForm}>
          <div>
            <ScheduleEditingForm
              isEdit={true}
              initialValues={appointment}
              onSend={onSend}
              onDelete={onDelete}
              dismissAction={closeEditForm}
            />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default ScheduleEditingSlot;
