import React, { useContext, useEffect, useRef, useState } from "react";
import bem from "bem-ts";
import classNames from "classnames";

import "./index.scss";
import { ScheduleContext } from "$/contexts/schedule";
import moment from "moment";
import { Resizable } from "re-resizable";
import Button from "$/components/common/button";
import { Dialog } from "@reach/dialog";
import TemplateCreateForm from "$/components/templateCreateForm";

const b = bem("TemplateEditing-Column-FreeSlot", { strict: false });

interface IFreeSlotProps {
  slot: {
    startWorkTime: string;
    endWorkTime: string;
    maxTime: string;
    minTime: string;
  };
  day: number;
  templateState: any[];
  discardState: any[];
  onCreateFreeSlot: () => void;
}

const FreeSlot: React.FC<IFreeSlotProps> = ({ slot, ...props }) => {
  const [currentTemplate, setCurrentTemplate] = props.templateState;
  const [discard, setDiscard] = props.discardState || [];

  const [showForm, setShowForm] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [editing, setEditing] = useState(false);
  const [currentSlot, setCurrentSlot] = useState(slot);
  const [initTop, setInitTop] = useState();
  const [top, setTop] = useState(0);
  const [height, setHeight] = useState(0);
  const [initHeight, setInitHeight] = useState();
  const [styleResizeBox, setStyleResizeBox] = useState({
    position: "absolute",
  });
  const [delayHandler, setDelayHandler] = useState(null);

  const [scheduleStore] = useContext(ScheduleContext);

  const refSlot = useRef(null);
  const refCreate = useRef(null);
  const refCancel = useRef(null);
  const pixelsInMinute = 41 / scheduleStore.zoom;

  const handleMouseEnter = () => {
    !editing &&
      setDelayHandler(
        setTimeout(() => {
          setShowButton(true);
        }, 1000)
      );
  };

  const handleMouseLeave = () => {
    !editing && setShowButton(false);
    clearTimeout(delayHandler);
  };

  useEffect(() => {
    setHeight(initHeight);
    setTop(initTop);
  }, [initTop, initHeight]);

  useEffect(() => {
    const minutesBefore = moment
      .utc(slot.startWorkTime)
      .local()
      .diff(
        moment
          .utc(slot.startWorkTime)
          .local()
          .hours(scheduleStore.minHour)
          .minute(0),
        "minutes"
      );

    setInitHeight(41);
    const top = (41 / scheduleStore.zoom) * minutesBefore;
    setInitTop(top);
  }, [slot, scheduleStore.zoom]);

  const onResizeStart = (e, dir, refToElement) => {
    if (dir === "top") {
      setStyleResizeBox({ ...styleResizeBox, bottom: "0" });
    } else {
      setStyleResizeBox({ ...styleResizeBox, bottom: "auto" });
    }
  };

  const onResize = (e, direction, ref, d) => {
    const heightInMinutes = (scheduleStore.zoom / 41) * (height + d.height);
    if (direction === "top") {
      const newStartDate = moment(currentSlot.endWorkTime).subtract(
        Math.round(heightInMinutes),
        "minutes"
      );
      setCurrentSlot({
        ...currentSlot,
        startWorkTime: newStartDate.format("YYYY-MM-DDTHH:mm:SS"),
      });
    } else {
      if (heightInMinutes) {
        const newEndDate = moment(currentSlot.startWorkTime).add(
          Math.round(heightInMinutes),
          "minutes"
        );
        setCurrentSlot({
          ...currentSlot,
          endWorkTime: newEndDate.format("YYYY-MM-DDTHH:mm:SS"),
        });
      }
    }
  };

  const onResizeStop = (e, direction, ref, d) => {
    if (direction === "top") {
      setTop(top - d.height);
    }
    setHeight(height + d.height);
  };

  const startEdit = () => {
    setEditing(true);
  };

  const stopEdit = (save: boolean = false) => {
    if (!showForm) {
      setEditing(false);
      setShowButton(false);
      if (!save) {
        setCurrentSlot(slot);
        setTop(initTop);
        setHeight(initHeight);
      }
    }
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const openForm = () => {
    setShowForm(true);
  };

  useEffect(() => {
    const handleClickDocument = (event) => {
      if (
        !refSlot.current.contains(event.target) ||
        event.target == refCancel.current
      ) {
        stopEdit();
      }
    };

    if (editing) {
      document.addEventListener("click", handleClickDocument);
    } else {
      document.removeEventListener("click", handleClickDocument);
    }

    return () => {
      document.removeEventListener("click", handleClickDocument);
    };
  }, [editing]);

  const onClickDiscard = () => {
    setCurrentTemplate(discard);
  };

  const onSaveSlot = (newSlot) => {
    const { schedule } = currentTemplate;
    Object.entries(schedule).forEach(([day, slots]) => {
      schedule[day] = slots?.map((slot) => {
        if (slot.id === newSlot.id) {
          return {
            id: newSlot.id,
            startWorkTime: moment(newSlot.startWorkTime).format("HH:mm:00"),
            endWorkTime: moment(newSlot.endWorkTime).format("HH:mm:00"),
            appointmentType: newSlot.appointmentType,
          };
        } else {
          return slot;
        }
      });
    });

    setCurrentTemplate({
      ...currentTemplate,
      schedule,
    });
  };

  const onDeleteSlot = (id) => {
    const schedule = currentTemplate.schedule;
    Object.entries(schedule).forEach(([day, slots]) => {
      schedule[day] = slots.filter((slot) => {
        return slot.id !== id;
      });
    });
    setCurrentTemplate({ ...currentTemplate, schedule });
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-time={slot.startWorkTime}
      className={classNames(b({ editing }))}
      style={{ height: `${height}px`, top: `${top}px` }}
      ref={refSlot}
    >
      {showButton && (
        <Resizable
          className={b("resizableBox")}
          enable={{ top: editing, bottom: editing }}
          minHeight={40}
          style={styleResizeBox}
          size={{ height }}
          grid={[0, pixelsInMinute * 5]}
          onResize={onResize}
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
        >
          <Button className={b("button", { editing })} onClick={startEdit}>
            {!editing && (
              <>
                <i className="far fa-plus-circle"></i> &ensp;New
              </>
            )}
            {editing && (
              <>
                <span className={b("button__output")}>
                  <span>
                    {moment
                      .utc(currentSlot.startWorkTime)
                      .local()
                      .format("HH:mm")}
                  </span>
                  -
                  <span>
                    {moment
                      .utc(currentSlot.endWorkTime)
                      .local()
                      .format("HH:mm")}
                  </span>
                </span>
                <span onClick={openForm}>Create</span>
                <span>
                  <i className={"far fa-times"} onClick={stopEdit} />
                </span>
              </>
            )}
          </Button>
        </Resizable>
      )}
      <Dialog
        aria-label={"EditTemplateForm"}
        isOpen={showForm}
        onDismiss={closeForm}
      >
        <div>
          {!!currentTemplate && (
            <TemplateCreateForm
              delete={props.delete}
              type={"PHYSICAL"}
              selectTime={{
                day: props.day,
                startWorkTime: currentSlot.startWorkTime.split("T")[1],
                endWorkTime: currentSlot.endWorkTime.split("T")[1],
              }}
              template={currentTemplate}
              save={props.onCreateFreeSlot}
              dismissAction={closeForm}
            />
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default FreeSlot;
