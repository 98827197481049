import Button from '$/components/common/button';
import LoaderPlain from '$/components/loaderPlain';
import { NewTemplatesForm } from '$/components/scheduleEditingTemplates/NewTemplatesForm/NewTemplatesForm';
import { ScheduleContext } from '$/contexts/schedule';
import useFetch from '$/hooks/useFetch';
import { Link } from '@reach/router';
import axios from 'axios';
import bem from 'bem-ts';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import './index.scss';

const b = bem('Schedule-Editing-Templates', {strict: false});

interface ITemplateProps {
  currentWeek: number;

  dismissAction: () => void;
  onSuccessApply: () => void;
}

const Templates = (props: ITemplateProps) => {
  const [templates, setTemplates] = useState([]);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [{response, isLoading}, fetchTemplates] = useFetch(
    '/admin/settings/templates'
  );

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  useEffect(() => {
    if (response) {
      setTemplates(response);
    }
  }, [response]);

  const openNewTemplateForm = () => {
    setIsNewTemplate(true);
  };
  const closeNewTemplateForm = () => {
    setIsNewTemplate(false);
    fetchTemplates();
  };

  const comeBackFromApplyAppointment = () => {
    setSelectedTemplate(null);
  };

  const openApplyForm = (template) => {
    setSelectedTemplate(template);
  };

  const onApplyThisWeek = (template: any) => {
    props.onSuccessApply();
    props.dismissAction();
  };

  const onSuccessApplyForm = () => {
    props.onSuccessApply();
    props.dismissAction();
  };

  return (
    <div className={b()}>
      <span className={b('close')} onClick={props.dismissAction}>
        <i className={'far fa-times'}/>
      </span>
      {isNewTemplate ? (
          <NewTemplatesForm currentWeek={props.currentWeek} onCancel={closeNewTemplateForm}/>
        ) :
        selectedTemplate ? (
          <ApplyTemplate
            template={selectedTemplate}
            backAction={comeBackFromApplyAppointment}
            onSuccess={onSuccessApplyForm}
            onCancel={props.dismissAction}
          />
        ) : (
          <TemplateList
            templates={templates}
            openApplyForm={openApplyForm}
            onApplyThisWeek={onApplyThisWeek}
            openNewTemplateForm={openNewTemplateForm}
            onCancel={props.dismissAction}
          />
        )}
    </div>
  );
};

export default Templates;

interface ITemplateListProps {
  templates: any[];
  openApplyForm: (template: any) => void;
  onApplyThisWeek: (template: any) => void;
  openNewTemplateForm: (template: any) => void;
  onCancel: () => void;
}

const TemplateList = React.memo((props: ITemplateListProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [templates, setTemplates] = useState<any[]>([]);
  const [scheduleStore] = useContext(ScheduleContext);
  const [{response: thisWeekAppliedResponse, isLoading: thisWeekAppliedIsLoading}, fetchApply] = useFetch(
    '/doctor/apply/template'
  );

  useEffect(() => {
    setTemplates(props.templates);
  }, [props]);

  const onClickOnTemplate = (template: any) => {
    if (template.id === selectedTemplate?.id) setSelectedTemplate(null);
    else setSelectedTemplate(template);
  };

  const applyThisWeek = () => {
    const currentWeek = moment(scheduleStore.currentTime);
    fetchApply({
      url: `/doctor/apply/template/?template=${
        selectedTemplate.id
      }&date=${currentWeek.format('YYYY')}-${currentWeek.isoWeek()}`,
      onFinish: () => {
        props.onApplyThisWeek(selectedTemplate);
      }
    });
  };

  const applySeveralWeeks = () => {
    props.openApplyForm(selectedTemplate);
  };

  const deleteTemplate = (template) => {
    axios
      .get(
        `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/admin/settings/delete/template/?templateId=${template.id}`,
        {
          withCredentials: true,
        }
      )
      .then(() => {
        setTemplates(templates.filter((t) => t.id !== template.id));
      });
  };


  return (
    <>
      <div className={b('content')}>
        <h2>Templates</h2>

        <div className={b('content__list')}>
          <div
            className={b('template', ['create'])}
            onClick={props.openNewTemplateForm}
          >
            <div className={b('template__icon')}>NT</div>
            <div className={b('template__info')}>
              New template from current week
              <div className={b('template__preview')}>
                <i className={'far fa-plus-circle'}/>
              </div>
            </div>
          </div>
          {templates?.map((template, indexTemplate) => {
            return (
              <div
                className={b('template__wrapper', {
                  deleted: template.deleted,
                })}
                key={`template-${indexTemplate}`}
              >
                <Template
                  template={template}
                  key={`template-${indexTemplate}`}
                  displayIcon={true}
                  onClick={() => onClickOnTemplate(template)}
                  isSelected={selectedTemplate?.id === template.id}
                />
                <div className={b('template__buttons')}>
                  <Link
                    to={`/template-editing/${template.id}`}
                    className={b('template__buttons__button', ['edit'])}
                  >
                    <i className="icon far fa-edit"></i>
                  </Link>
                  <div
                    className={b('template__buttons__button', ['delete'])}
                    onClick={() => deleteTemplate(template)}
                  >
                    <i className="icon far fa-trash-alt"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={b('footer')}>
        <Button
          className={b('footer__button')}
          view={'cancel'}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <Button
          className={b('footer__button')}
          view={!selectedTemplate ? 'disabled' : 'cancel'}
          onClick={applySeveralWeeks}
        >
          Apply to several weeks
        </Button>
        <Button
          className={b('footer__button')}
          view={!selectedTemplate && 'disabled'}
          onClick={applyThisWeek}
        >
          {thisWeekAppliedIsLoading ? <LoaderPlain/> : 'Apply to this week'}
        </Button>
      </div>
    </>
  );
});

const Template = ({template, ...props}) => {
  const [scheduleStore] = useContext(ScheduleContext);
  const [resultTime, setResultTime] = useState(0);
  const [renderedTemplate, setRenderedTemplate] = useState();

  const nameArr = template.scheduleName.split(' ');
  const showName = `${nameArr[0][0]}${nameArr[1] ? nameArr[1][0] : ''}`;
  useEffect(() => {
    setRenderedTemplate(getRenderedTemplate());
  }, [template]);

  const getRenderedTemplate = () => {
    let time = 0;
    const result = [1, 2, 3, 4, 5, 6, 7].map((day) => {
      const dayString = moment().isoWeekday(day).format('ddd');
      const heightColumn = 125;
      const schedule = template.schedule[day];
      let scheduleMinutes = 0;
      const renderSchedule = schedule?.map((slot, indexSlot) => {
        const slotStartHour = slot.startWorkTime.split(':')[0];
        const slotStartMinute = slot.startWorkTime.split(':')[1];
        const momentStartSlot = moment
          .utc()
          .hour(slotStartHour)
          .minutes(slotStartMinute)
          .local();

        const slotEndHour = slot.endWorkTime.split(':')[0];
        const slotEndMinute = slot.endWorkTime.split(':')[1];
        const momentEndSlot = moment
          .utc()
          .hour(slotEndHour)
          .minutes(slotEndMinute)
          .local();

        const countMinutes =
          momentEndSlot.diff(momentStartSlot, 'minutes').toFixed() - 2;
        scheduleMinutes += countMinutes;

        const countsMinutesInDay = moment()
          .hour(scheduleStore.maxHour)
          .minutes(0)
          .diff(moment().hour(scheduleStore.minHour).minutes(0), 'minutes');
        const countMinutesBefore = momentStartSlot.diff(
          moment(momentStartSlot).hour(scheduleStore.minHour).minutes(0),
          'minutes'
        );

        const heightMinute = heightColumn / countsMinutesInDay;
        const height = countMinutes * heightMinute;
        const topSlot = countMinutesBefore * heightMinute;

        const style = {
          height: `${height}px`,
          top: `${topSlot}px`,
        };
        return (
          <div
            key={`day-${day}-schedule-${indexSlot}`}
            style={style}
            className={b('template__preview__day__content__slot', [
              slot.appointmentType?.toLowerCase(),
            ])}
          ></div>
        );
      });

      time += scheduleMinutes;
      return (
        <div key={`day-${day}`} className={b('template__preview__day')}>
          <span className={b('template__preview__day__title')}>
            {dayString} {(scheduleMinutes / 60).toFixed()}h
          </span>
          <div className={b('template__preview__day__content')}>
            {renderSchedule}
          </div>
        </div>
      );
    });
    setResultTime(time);
    return result;
  };

  return (
    <div className={b('template')} onClick={props.onClick}>
      {props.displayIcon && (
        <div
          className={b('template__icon', props.isSelected && ['isSelected'])}
        >
          {props.isSelected ? <i className="icon far fa-check"></i> : showName}
        </div>
      )}
      <div className={b('template__info')}>
        {template.scheduleName} {(resultTime / 60).toFixed(2)}h
        <div className={b('template__preview')}>{renderedTemplate}</div>
      </div>
    </div>
  );
};

const ApplyTemplate = ({template, ...props}) => {
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [{response, isLoading}, fetchApply] = useFetch(
    '/doctor/apply/template'
  );

  const currentWeek = moment().isoWeek();
  const getWeeksList = () => {
    const result = [];
    for (
      let i = moment().isoWeek(currentWeek), j = 0;
      j < 6;
      i.add(1, 'week'), j++
    ) {
      result.push(i.isoWeek());
    }
    return result;
  };

  const weeks = getWeeksList();

  const onClick = (weekNumber) => {
    if (selectedWeeks.includes(weekNumber)) {
      const index = selectedWeeks.findIndex((week) => week === weekNumber);
      const newArr = [...selectedWeeks];
      newArr.splice(index, 1);
      setSelectedWeeks(newArr);
    } else {
      setSelectedWeeks([...selectedWeeks, weekNumber]);
    }
  };

  const onApply = () => {
    fetchApply({
      url: `/doctor/apply/template/?template=${
        template.id
      }&date=${selectedWeeks.map(i => i).join(',')}`,
    });
  };

  useEffect(() => {
    if (response) {
      props.onSuccess();
    }
  }, [response]);

  return (
    <>
      <div className={b('back')} onClick={props.backAction}>
        <i className="icon far fa-angle-left"></i> Back
      </div>
      <div className={b('content')}>
        <div className={b('applyTemplate')}>
          <Template template={template}/>

          <h2 className={b('applyTemplate__title')}>Apply template to:</h2>
          <div className={b('applyTemplate__weeks')}>
            {weeks?.map((week) => {
              return (
                <div
                  key={`week-${week}`}
                  className={b(
                    'applyTemplate__weeks__week',
                    selectedWeeks.includes(week) && ['isSelected']
                  )}
                  onClick={() => onClick(week)}
                >
                  <div className={b('applyTemplate__weeks__week__icon')}>
                    {selectedWeeks.includes(week) ? (
                      <i className="icon far fa-check"></i>
                    ) : (
                      week
                    )}
                  </div>
                  <div className={b('applyTemplate__weeks__week__info')}>
                    {week} week
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={b('footer')}>
        <Button
          className={b('footer__button')}
          view={'cancel'}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <Button
          className={b('footer__button')}
          view={!selectedWeeks.length && 'disabled'}
          disabled={isLoading}
          onClick={onApply}
        >
          {isLoading ? <LoaderPlain/> : 'Ok'}
        </Button>
      </div>
    </>
  );
};
