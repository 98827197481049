import React, { useState } from "react";
import bem from "bem-ts";
import InputMask from "react-input-mask";

import "./index.scss";
import Button from "$/components/common/button";
import moment from "moment";

const b = new bem("TemplateEditForm", { strict: false });

const TemplateEditForm = ({ template, ...props }) => {
  const [type, setType] = useState(template.appointmentType);
  const [startTimeTerm, setStartTimeTerm] = useState<string>(
    moment.utc(template.startWorkTime).local().format("HH:mm")
  );
  const [endTimeTerm, setEndTimeTerm] = useState<string>(
    moment.utc(template.endWorkTime).local().format("HH:mm")
  );
  const [time, setTime] = useState<{ startWorkTime: Date; endWorkTime: Date }>({
    startWorkTime: moment.utc(template.startWorkTime).toDate(),
    endWorkTime: moment.utc(template.endWorkTime).toDate(),
  });

  const onClickType = (e) => {
    setType(e.target.value);
  };

  const onChangeStartTime = (e) => {
    setStartTimeTerm(e.target.value);
  };
  const onChangeEndTime = (e) => {
    setEndTimeTerm(e.target.value);
  };
  const onBlurStartTime = (e) => {
    const hour = parseInt(e.target.value.split(":")[0]);
    const minutes = parseInt(e.target.value.split(":")[1]);
    if (
      /**
       * @info Timeslot shrinking is only available for unknown reason
       */
      e.target.value >=
        moment.utc(template.prevTimeForLock).local().format("HH:mm") &&
      e.target.value <= moment.utc(time.endWorkTime).local().format("HH:mm")
    ) {
      setTime({
        ...time,
        startWorkTime: moment
          .utc(time.startWorkTime)
          .local()
          .hour(hour)
          .minutes(minutes)
          .utc()
          .toDate(),
      });
      setStartTimeTerm(e.target.value);
    } else {
      setStartTimeTerm(moment.utc(time.startWorkTime).local().format("HH:mm"));
    }
  };
  const onBlurEndTime = (e) => {
    const hour = parseInt(e.target.value.split(":")[0]);
    const minutes = parseInt(e.target.value.split(":")[1]);
    if (
      /**
       * @info Timeslot shrinking is only available for unknown reason
       */
      e.target.value <=
        moment.utc(template.nextTimeForLock).local().format("HH:mm") &&
      e.target.value >= moment.utc(time.startWorkTime).local().format("HH:mm")
    ) {
      setTime({
        ...time,
        endWorkTime: moment
          .utc(time.endWorkTime)
          .local()
          .hour(hour)
          .minutes(minutes)
          .utc()
          .toDate(),
      });
      setEndTimeTerm(e.target.value);
    } else {
      setEndTimeTerm(moment.utc(time.endWorkTime).local().format("HH:mm"));
    }
  };

  const onSave = () => {
    props.save({ time, type });
    props.dismissAction();
  };

  const onDelete = () => {
    props.delete(template.id);
    props.dismissAction();
  };

  return (
    <div className={b()}>
      <div className={b("content")}>
        <h2>Edit slot</h2>
        <div className={"type__buttons"}>
          <Button
            value={"PHYSICAL"}
            onClick={onClickType}
            className={b("type", ["physical", type === "PHYSICAL" && "active"])}
          >
            Physical
          </Button>
          <Button
            value={"VIDEO"}
            onClick={onClickType}
            className={b("type", ["video", type === "VIDEO" && "active"])}
          >
            Video
          </Button>
          <Button
            value={"CALL"}
            onClick={onClickType}
            className={b("type", ["call", type === "CALL" && "active"])}
          >
            Call
          </Button>
        </div>

        <div className={b("time")}>
          <InputMask
            className={b("time__start")}
            mask={"99:99"}
            onChange={onChangeStartTime}
            onBlur={onBlurStartTime}
            value={startTimeTerm}
          />{" "}
          –
          <InputMask
            className={b("time__end")}
            mask={"99:99"}
            onChange={onChangeEndTime}
            onBlur={onBlurEndTime}
            value={endTimeTerm}
          />
        </div>
      </div>

      <div className={b("footer")}>
        <div className={b("footer__buttons")}>
          {props.isEdit && (
            <Button
              className={b("footer__buttons__button", ["delete"])}
              onClick={onDelete}
              view="danger"
            >
              Delete
            </Button>
          )}

          <Button
            className={b("footer__buttons__button", ["cancel"])}
            view="cancel"
            onClick={(e) => {
              props.dismissAction();
            }}
          >
            Cancel
          </Button>

          <Button
            className={b("footer__buttons__button", ["save"])}
            onClick={onSave}
          >
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TemplateEditForm;
