import {
  AppointmentStateEnum,
  AppointmentTypeEnum,
  IAppointment,
  IAppointmentType,
  IDoctorInfo,
  IDoctorInfoWithAvailability,
  IPatient,
  IRoom,
  TimeSlotsStatusEnum,
} from "$/models";
import GlobalStore from "$/stores/global";
import { AxiosPromise } from "axios";
import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";

export class Appointment {
  id?: string;
  patients: IPatient[] = [];
  participants: IDoctorInfoWithAvailability[] = [];
  events = {
    reasons: [],
    symptoms: [],
    observations: [],
    diagnosis: [],
    medicines: [],
  };

  preparations = [];
  appointmentType: string | AppointmentTypeEnum = AppointmentTypeEnum.PHYSICAL;

  appointmentState: string | AppointmentStateEnum = "BOOKED";
  isFirstVisit: boolean = true;
  startDate: string = moment.utc().format("YYYY-MM-DDTHH:00:00");
  endDate: string = moment
    .utc()
    .minutes(0)
    .add(15, "minutes")
    .format("YYYY-MM-DDTHH:mm:00");
  status: string = TimeSlotsStatusEnum.PATIENT_APPOINTMENT;
  place: IRoom | null = null;

  appointmentTopic?: string = "";

  description?: string = "";

  patientChildInfo = null;

  stateHistory: any = [];

  doctor: IDoctorInfo | null = null;

  attachments: any = null;

  otherError: string = "";

  constructor(
    private globalStore: GlobalStore,
    private initialData?: IAppointment | Appointment
  ) {
    makeObservable(this, {
      patients: observable,
      participants: observable,
      events: observable,
      preparations: observable,
      appointmentType: observable,
      appointmentState: observable,
      isFirstVisit: observable,
      startDate: observable,
      endDate: observable,
      status: observable,
      place: observable,
      appointmentTopic: observable,
      description: observable,
      patientChildInfo: observable,
      stateHistory: observable,
      doctor: observable,
      otherError: observable,
      isLoadingSave: observable,
      isLoadingChangeStatus: observable,

      errors: computed,
      busyMembers: computed,

      setOtherError: action,
      setField: action,
      setAppointment: action,
      changeStatus: action,
      reopenAppointment: action,
      cancelAppointmentReopen: action,
    });

    if (initialData) {
      Object.entries(initialData).forEach(([key, value]) => {
        //FIXME: remove @ts-ignore
        // @ts-ignore
        this[key] = value;
      });
    }
    this.doctor = initialData?.doctor ?? this.globalStore.currentUser ?? null;
    window.appointment = this;
  }

  public get busyMembers(): Promise<{
    patients: string[];
    participants: string[];
  }> {
    return this.globalStore.http
      .get(
        `/doctor/appointments/${moment(this.startDate).format(
          "YYYY-MM-DDTHH:mm:00"
        )}/${moment(this.endDate).format("YYYY-MM-DDTHH:mm:00")}`
      )
      .then(({ data }) => {
        const patients: string[] = [];
        const participants: string[] = [];

        (data || []).forEach((appointment: IAppointmentType) => {
          !!appointment.patients?.length &&
            patients.concat(appointment.patients.map((p) => p.bankId));
          !!appointment.participants?.length &&
            participants.concat(appointment.participants.map((p) => p.sithsid));
        });

        return {
          patients,
          participants,
        };
      });
  }

  public get errors(): any {
    const errors: { [key: string]: string } = {};
    if (this.status === TimeSlotsStatusEnum.PATIENT_APPOINTMENT) {
      if (!this.patients?.length) {
        errors.patients = "Patients must be filled";
      } else {
        const hasBusyPatients = this.patients.find(
          (patient) =>
            !!patient.availability && !patient.availability.isAvailable
        );
        if (hasBusyPatients)
          errors.patients = " Some patients are busy during selected time";
      }

      if (!this.participants?.length) {
        errors.participants = "Participants must be filled";
      }
    } else if (this.status === TimeSlotsStatusEnum.ADMIN_APPOINTMENT) {
      if (!this.participants?.length) {
        errors.participants = "Participants must be filled";
      }
      /*
              else {
                const hasBusyParticipants = values.participants.find(participant => !participant.availability.isAvailable)
                if (hasBusyParticipants) errors.participants += 'Some participants are busy during selected time'
              }
        */

      if (!this.appointmentTopic?.length) {
        errors.appointment = "Appointment topic must be specified";
      }
    }

    if (this.participants?.some((p) => !p?.availability?.isAvailable)) {
      errors.participants = "Some of the participants are busy!";
    }

    return errors;
  }

  public setOtherError(err: string) {
    this.otherError = err;
  }

  public setAppointment(appointment: Appointment) {
    Object.entries(appointment).forEach(([key, value]) => {
      this.setField(key, value);
    });
  }

  public setField<K extends keyof IAppointmentType>(field: string, value: any) {
    this[field] = value;
  }

  public isLoadingSave: boolean = false;

  public saveAppointment(): AxiosPromise<IAppointment> {
    const data: IAppointment = {
      id: this.id,
      participants: this.participants,
      patients: this.patients,
      events: this.events,
      preparations: this.preparations,
      appointmentType: this.appointmentType,
      appointmentState: this.appointmentState,
      isFirstVisit: this.isFirstVisit,
      startDate: this.startDate,
      endDate: this.endDate,
      status: this.status,
      place: this.place,
      appointmentTopic: this.appointmentTopic,
      description: this.description,
      patientChildInfo: this.patientChildInfo,
      doctor: this.doctor,
      stateHistory: this.stateHistory,
      chat: this.chat?.chatId ? {
        chatId: this.chat.chatId
      } : undefined,
    };

    this.isLoadingSave = true;
    return this.globalStore.http
      .post<IAppointment>(`/doctor/appointment`, data)
      .then((res) => res)
      .catch((e) => {
        if (e.response?.data?.code === "A0003") {
          return this.globalStore.http
            .post("/doctor/appointment", {
              ...data,
              status: "WORK_TIME",
              id: null,
              participants: [],
              patient: [],
            })
            .then(() => {
              return this.globalStore.http.post(`/doctor/appointment`, data);
            })
            .catch((e) => {
              this.setOtherError(e?.response?.data?.description);
              throw e;
            });
        }
        return Promise.reject(e.response?.data?.description);
      })
      .finally(() => {
        this.isLoadingSave = false;
      });
  }

  isLoadingChangeStatus: boolean = false;

  public changeStatus(val: AppointmentStateEnum) {
    this.isLoadingChangeStatus = true;
    return this.globalStore.http
      .get(`/doctor/appointment/${this.id}/state/?status=${val}`)
      .then((res) => {
        if (res) {
          this.appointmentState = val;
          return true;
        }
        return false;
      })
      .finally(() => {
        this.isLoadingChangeStatus = false;
      });
  }

  public reopenAppointment = (startDate: Appointment["startDate"]) => {
    this.isLoadingChangeStatus = true;
    return this.globalStore.http
      .get<Appointment>(`/doctor/appointment/${this.id}/reopen`)
      .then((res) => {
        if (res && res.data) {
          const {
            startDate: _sd,
            endDate: _ed,
            ...restoreAppointmentData
          } = res.data;
          this.setAppointment({
            ...restoreAppointmentData,
            startDate,
          } as Appointment);
        }
      })
      .finally(() => {
        this.isLoadingChangeStatus = false;
      });
  };

  public cancelAppointmentReopen = () => {
    this.appointmentState = "CANCELED";
  };

  public downloadAttachment(file: any) {
    this.globalStore.http
      .get(`/doctor/appointment/${this.id}/document/?file=${file.fileName}`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", file.fileName); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }
}
