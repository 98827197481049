import * as React from "react";
import axios from "axios";

import bem from "bem-ts";
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button";
import { useContext, useEffect, useState } from "react";
import { Combobox, ComboboxInput } from "@reach/combobox";
import "@reach/combobox/styles.css";

import { ChatStore } from "$/contexts/chat";
import "./index.scss";
import Button from "$/components/common/button";
import { CurrentUserContext } from "$/contexts/currentUser";
import useFetch from "$/hooks/useFetch";
import LoaderPlain from "$/components/loaderPlain";

const b = new bem("Chat-Menu", { strict: false });

const MenuChat: React.FunctionComponent<any> = (props) => {
  const [userState] = useContext(CurrentUserContext);
  const [, dispatchChat] = useContext(ChatStore);
  const [readyForChat, setReadyForChat] = useState(false);
  const [{ response, isLoading }, fetchReadyButton] = useFetch(
    "/doctor/chat/ready"
  );

  useEffect(() => {
    setReadyForChat(userState?.currentUser?.readyForChat);
  }, [userState?.currentUser?.readyForChat]);

  const onChange = (e) => {
    dispatchChat({
      type: "SET_SEARCHING",
      payload: e.target.value,
    });
  };
  useEffect(() => {
    response && window.location.reload();
  }, [response]);

  return (
    <nav className={`${b("container")} ${props.className}`}>
      <div className={b("container__item")}>
        <Combobox>
          <i className="far fa-search" />
          &ensp;
          <ComboboxInput
            onChange={onChange}
            placeholder={"Search by doctor or patient"}
          />
        </Combobox>
      </div>
      <div className={b("container__item")}>
        <Menu className={b("container__item")}>
          <MenuButton
            className={`${b("container__item__text")} ${b(
              "container__item__button"
            )}`}
          >
            <i className="icon far fa-sort-size-down-alt" />
            &ensp;Sort by
          </MenuButton>
          <MenuList data-chat-list-menu className={`${"sorting__list"}`}>
            <MenuItem
              onSelect={() => {
                dispatchChat({
                  type: "SET_SORTING",
                  payload: "DEFAULT",
                });
              }}
            >
              <i className="far fa-comment"></i>&ensp;Default
            </MenuItem>
            <MenuItem
              onSelect={() => {
                dispatchChat({
                  type: "SET_SORTING",
                  payload: "PATIENT",
                });
              }}
            >
              <i className="far fa-user-alt"></i>&ensp;Patients
            </MenuItem>
            <MenuItem
              onSelect={() => {
                dispatchChat({
                  type: "SET_SORTING",
                  payload: "SYMPTOMS",
                });
              }}
            >
              <i className={"far fa-align-left"} />
              &ensp;Symptoms
            </MenuItem>
            <MenuItem
              onSelect={() => {
                dispatchChat({
                  type: "SET_SORTING",
                  payload: "APPOINTMENT",
                });
              }}
            >
              <i className="far fa-clock"></i>&ensp;Appointment time
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
      <div className={b("container__item")}>
        <span className={b("container__item__text")}>
          <Button
            view={!readyForChat && "completed"}
            className={b("container__item__button--ready")}
            onClick={fetchReadyButton}
          >
            {isLoading ? (
              <LoaderPlain />
            ) : (
              <>
                <i className="far fa-hand-paper"></i> &ensp;{" "}
                {`${readyForChat ? "Stop" : "Start"} consulting
            patients from chatbot`}
              </>
            )}
          </Button>
        </span>
      </div>
    </nav>
  );
};

export default MenuChat;
