import { useEffect, useContext } from "react";

import useFetch from "$/hooks/useFetch";
import useLocalStorage from "$/hooks/useLocalStorage";
import { CurrentUserContext } from "$/contexts/currentUser";
import { GlobalContext } from "$/contexts/global";
import useSessionStorage from "$/hooks/useSessionStorage";

const isProduction = process.env.NODE_ENV === "production";

const CurrentUserChecker = ({ children }) => {
  const [, dispatch] = useContext(CurrentUserContext);
  const globalStore = useContext(GlobalContext);
  const [{ response }, doFetch] = useFetch("/doctor/info");
  const [token] = useLocalStorage("token");
  const [authorized] = useSessionStorage("authorized");

  useEffect(() => {
    if (!authorized) {
      dispatch({ type: "SET_UNAUTHORIZED" });

      if(location.pathname !== '/'){
        location.href = '/';
      }

      return;
    }

    doFetch();
    dispatch({ type: "LOADING" });
  }, [doFetch, dispatch, authorized]);

  useEffect(() => {
    if (!response) {
      // If we don't have response or auth failed we should return error
      isProduction && dispatch({ type: "ERROR" });
      return;
    }

    // globalStore.setCurrentUser(
    //   Array.isArray(response) ? response[0] : response
    // );
    dispatch({
      type: "SET_AUTHORIZED",
      payload: Array.isArray(response) ? response[0] : response,
    });
  }, [response, dispatch]);
  return children;
};

export default CurrentUserChecker;
