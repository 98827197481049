import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import axios from "axios";
window.axios = axios;
import useLocalStorage from "$/hooks/useLocalStorage";
import {globalHeaders} from '../services/http-client.service';

// const API_URL = 'https://staging.vibblaby/api/'
const API_URL = `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}`;

const instanceAxios = axios.create({
  withCredentials: true,
  headers: {
    // 'authorization': token ? `Token ${token}` : ''
    ...globalHeaders,
  },
});

instanceAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      if (window.location.pathname != "/") {
        window.location.href = window.location.href.slice(
          0,
          -1 * window.location.pathname.length
        );
      }
    }
    return Promise.reject(error);
  }
);

export default (url) => {
  const baseUrl = API_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState({});
  const [token] = useLocalStorage("token");

  const doFetch = useCallback((options = {}) => {
    setOptions(options);
    setIsLoading(true);
  }, []);

  useEffect(() => {
    let skipGetResponseAfterDestroy = false;
    if (!isLoading) {
      return;
    }

    const requestOptions = {
      ...options,

      headers: {
        ...(requestOptions?.headers || {}),
        ...globalHeaders,
      },
    };

    const otherUrl = requestOptions.url;

    instanceAxios(baseUrl + (otherUrl || url), requestOptions)
      .then((res) => {
        if (!skipGetResponseAfterDestroy) {
          setResponse(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!skipGetResponseAfterDestroy) {
          const resultError = error.response ? error.response.data : error;
          setError(resultError);
          setIsLoading(false);
        }
      })
      .finally((result) => {
        if (options.onFinish) {
          options.onFinish(result);
        }
      });
    return () => {
      skipGetResponseAfterDestroy = true;
    };
  }, [isLoading, url, options, token]);

  return [{ isLoading, response, error }, doFetch];
};
