import PhotoIcon from '$/components/common/photoIcon';
import ScrolledComboboxList from '$/components/common/scrolledComboboxList';
import Group from '$/components/participantSelector/group';
import { useThrottle } from '$/hooks/useThrottle';
import { IAppointment, IDoctorInfo, IDoctorInfoWithAvailability, IProfession } from '$/models';
import { globalHeaders, HttpClient } from '$/services/http-client.service';
import { Appointment } from '$/stores/appointment';
import { Accordion, } from '@reach/accordion';
import '@reach/accordion/styles.css';
import { Combobox, ComboboxInput, ComboboxPopover } from '@reach/combobox';
import '@reach/combobox/styles.css';
import axios from 'axios';
import bem from 'bem-ts';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import './index.scss';

const WIDTH = 350;
const bSelector = bem('ParticipantSelector', {strict: false});
const bParticipant = bem('participant__container', {strict: false});

interface IParticipantSelectorProps {
  appointmentStore: Appointment
  onChange?: (participants: IDoctorInfo[]) => void;
  dontCheckAvailable?: boolean;
}

export default observer(({appointmentStore, ...props}: IParticipantSelectorProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  inputRef.current?.setAttribute('autocomplete', 'off');

  const currentParticipants = (appointmentStore?.participants?.map<IDoctorInfoWithAvailability>(participant => ({
    ...participant,
    availability: {isAvailable: true}
  })) || []);

  // this state is for display checked in below combobox
  const [selectedParticipants, setSelectedParticipants] = useState<IDoctorInfoWithAvailability[]>(currentParticipants);

  // this state is for display checked in dropdown list
  const [checkedParticipants, setCheckedParticipants] = useState<IDoctorInfoWithAvailability[]>(currentParticipants);

  const [term, setTerm] = useState('');
  const [isMultipleSelectMode, setIsMultipleSelectMode] = useState(false);
  const [isShowAllSelectedParticipants, setIsShowAllSelectedParticipants] = useState(false);

  let [foundPage, setFoundPage] = useState(0);
  // There are results of alive search:
  let [specId2Participants, specId2Professions, foundParticipantsCount] = useParticipantMatch(term, appointmentStore, foundPage, props.dontCheckAvailable);

  function toggleMultipleSelectMode() {
    setIsMultipleSelectMode(!isMultipleSelectMode);
  }

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedParticipants);
    }

    appointmentStore?.setField('participants', selectedParticipants);
    appointmentStore?.setField('doctor', selectedParticipants[0]);
  }, [selectedParticipants]);

  // FIXME: Maybe it's not good solution for check countItems
  // const noMoreItems = () => foundParticipantsCount > 1000;
  const noMoreItems = () => true;


  function loadMoreItems() {
    setFoundPage(foundPage + 1);
  }

  function getItems(): Promise<void> {
    return !noMoreItems()
      ? new Promise((res) => {
        setTimeout(() => res(loadMoreItems()), 500);
      })
      : Promise.resolve();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTerm(event.target.value);
    setFoundPage(0);
  }


  function addSelection(participant: IDoctorInfoWithAvailability) {
    if (!selectedParticipants.find((sp: IDoctorInfoWithAvailability) => sp.sithsid === participant.sithsid)) {
      setSelectedParticipants([...selectedParticipants, participant]);
    }
    setTerm('');
    setFoundPage(0);
  }

  /**
   * Function for check all participants in dropdown menu
   */
  function checkAllParticipants() {
    let allParticipants: IDoctorInfoWithAvailability[] = [];
    Object.entries(specId2Participants).forEach(([, participants]) => {
      allParticipants = [...allParticipants, ...participants];
    });
    setIsMultipleSelectMode(true);
    setCheckedParticipants(allParticipants);
    setSelectedParticipants(allParticipants);
  }

  function clearSelection() {
    setCheckedParticipants([]);
    setSelectedParticipants([]);
  }

  const isChecked = (participant: IDoctorInfoWithAvailability) => checkedParticipants.includes(participant);

  function toggleCheck(action: 'add' | 'remove', participant: IDoctorInfoWithAvailability) {
    if (action === 'add') {
      setCheckedParticipants([...checkedParticipants, participant]);
    } else if (action === 'remove') {
      setCheckedParticipants([
        ...(checkedParticipants.filter(cp => cp.sithsid !== participant.sithsid))
      ]);
    }
  }

  function addCheckedToSelectedParticipantsOnBlur(event: React.FocusEvent<HTMLDivElement>) {
    // in case of multi-check mode we need to see whether click was outside Combobox Popover or inside of it
    console.log(event.relatedTarget);
    if (!isMultipleSelectMode || (event.relatedTarget && event.currentTarget.contains(event.relatedTarget))) {
      return false;
    }

    let newSelectedParticipants: IDoctorInfoWithAvailability[] = [];
    newSelectedParticipants = newSelectedParticipants.concat(selectedParticipants);
    newSelectedParticipants = newSelectedParticipants.concat(
      checkedParticipants.filter(cp => !selectedParticipants.find((sp) => sp.sithsid === cp.sithsid))
    );

    setSelectedParticipants(newSelectedParticipants);
    setTerm('');
  }

  // Remove Participant from selected list (from part of below combobox)
  function removeParticipant(participant: IDoctorInfoWithAvailability) {
    setSelectedParticipants(selectedParticipants.filter((selected: IDoctorInfoWithAvailability) => selected !== participant));
    setCheckedParticipants(checkedParticipants.filter((checked: IDoctorInfoWithAvailability) => checked !== participant));
    return false;
  }

  function renderOption(participant: IDoctorInfoWithAvailability, showRemoveButton: boolean, showFlagOfChecked = false): JSX.Element {
    const firstName = participant.personalInfo.firstName;
    const lastName = participant.personalInfo.lastName;
    const speciality = participant.profession.speciality;
    const specialityText = speciality?.text || '';

    const isAvailable = !!participant?.availability?.isAvailable;
    const checked = isChecked(participant);
    return (
      <div className={bParticipant()} key={`participant-${participant.sithsid}`}>
        <div className={bParticipant('info')}>
          <div className={bParticipant('info__photo', ['container'])}>
            {showFlagOfChecked && checked && <>
              <div className={bParticipant('info__photo', ['container__overlay'])}/>
              <i className={'icon-check far fa-check'}/></>}
            <PhotoIcon person={participant}
                       className={!showRemoveButton && checked ? bParticipant('info__photo', ['checked']) : ''}/>
          </div>
          <div className={bParticipant('info__detail')}>
            <span className={bParticipant('info__detail__fullName')}>
              {firstName} {lastName}
              {!isAvailable && <span className="busyMarker">(Busy)</span>}
            </span>
            <span className={bParticipant('info__detail__speciality')}>
              {specialityText}
            </span>
          </div>
        </div>
        {showRemoveButton && (
          <a className={bParticipant('remove')}
             onClick={() => {
               removeParticipant(participant);
               return false;
             }}>
            <i className={'far fa-times'}/>
          </a>
        )}
      </div>
    );
  }

  const renderAccordionItems = (specId2Participants: { [key: string]: IDoctorInfoWithAvailability[] }, specId2Professions: { [key: string]: IProfession }) =>
    Object.entries(specId2Participants).map(([specialityId]) =>
      <Group
        key={specialityId}
        onToggleCheck={toggleCheck}
        onSelectOne={addSelection}
        renderOption={renderOption}
        checked={checkedParticipants}
        multipleSelect={isMultipleSelectMode}
        participants={specId2Participants[specialityId]}
        specProfession={specId2Professions[specialityId]}
      />);

  return (
    <Fragment>
      <div className={bSelector('container')}>

        <div className={bSelector('prefix')}>
          <i className={'far fa-user-md'}/>
        </div>

        <Combobox openOnFocus aria-label="Participants" style={{width: WIDTH}}>
          <ComboboxInput
            as={'input'}
            className="city-search-input"
            style={{width: WIDTH - 60}}
            placeholder={'Add doctor or nurse'}
            onChange={handleChange}
            value={term}
            ref={inputRef}
          />

          {
            (term.length > 0) &&
            <a className={bSelector('clear-input')}
               onClick={() => {
                 setTerm('');
                 return false;
               }}>
              <i className={'far fa-times'}/>
            </a>
          }

          {!!specId2Participants && term && foundParticipantsCount > 0 && (
            <ComboboxPopover portal={false}
                             className={bSelector('popover')}
                             style={{width: WIDTH}}
                             onBlur={addCheckedToSelectedParticipantsOnBlur}
            >
              <ScrolledComboboxList getItems={getItems}>
                <Accordion collapsible>
                  {renderAccordionItems(specId2Participants, specId2Professions)}
                </Accordion>
              </ScrolledComboboxList>

              <div className={bSelector('buttons')}>
                <button onClick={toggleMultipleSelectMode}
                        className={`${bSelector('button')} ${bSelector('button__multiple')}`}>
                  {isMultipleSelectMode && <><i className={'far fa-check'}/>&nbsp;</>}
                  Multiple select
                </button>

                {checkedParticipants.length < foundParticipantsCount && (
                  <button onClick={checkAllParticipants}
                          className={`${bSelector('button')} ${bSelector('button__selectAll')}`}>
                    Select All {foundParticipantsCount}
                  </button>
                )}

                {checkedParticipants.length === foundParticipantsCount && (
                  <button onClick={clearSelection}
                          className={`${bSelector('button')} ${bSelector('button__removeAll')}`}>
                    Remove Selection
                  </button>
                )}
              </div>
            </ComboboxPopover>
          )}
        </Combobox>
      </div>
      {selectedParticipants && selectedParticipants.length > 0 && (
        <div className={bSelector('selected')}>
          {selectedParticipants && (
            selectedParticipants.slice(0, 4).map((participant) => renderOption(participant, true, false)))}

          {selectedParticipants.length > 4 && (
            <Fragment>
              {!isShowAllSelectedParticipants && (
                <a onClick={() => {
                  setIsShowAllSelectedParticipants(true);
                  return false;
                }}>
                  Show {selectedParticipants.length - 4} more
                </a>
              )}

              {isShowAllSelectedParticipants && (
                <div className={'participants-selected--hidden'}>
                  {selectedParticipants.slice(4).map((participant) => renderOption(participant, !props.dontCheckAvailable))}
                  <a onClick={() => {
                    setIsShowAllSelectedParticipants(false);
                    return false;
                  }}>
                    Hide
                  </a>
                </div>
              )}
            </Fragment>
          )}
        </div>
      )
      }
    </Fragment>
  );
});

const fetchAppointments = async (participants: IDoctorInfo[], startDateTime: string, endDateTime: string) => {
  const participant2Appointment: { [key: string]: IAppointment } = {};

  const participantIds = participants
    .filter(participant => (participant.sithsid !== 'undefined' && participant.sithsid !== 'null'))
    .map(participant => participant.sithsid);

  if (startDateTime && endDateTime) {
    const startDateTimeISO = moment(startDateTime).format('YYYY-MM-DDTHH:mm');
    const endDateTimeISO = moment(endDateTime).format('YYYY-MM-DDTHH:mm');

    const appointments = (await axios.get<IAppointment[]>(
      `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/appointments/${startDateTimeISO}/${endDateTimeISO}/?users=${participantIds.join(',')}`,
      {withCredentials: true, headers: globalHeaders}
    ))?.data ?? [];

    appointments.filter(
      p2a => p2a.status !== null &&
        p2a.status !== 'WORK_TIME' &&
        p2a.appointmentState !== 'CANCELED'
    ).forEach(appointmentData => {
      participant2Appointment[appointmentData.doctor.sithsid.toString()] = appointmentData;
    });
  }

  return participant2Appointment;
};

function useParticipantMatch(searchTerm: string, appointmentDates: Appointment, page = 0, dontCheckAvailable = false) {
  const groupParticipantsByProfession = (participants: IDoctorInfoWithAvailability[]): [{ [key: string]: IDoctorInfoWithAvailability[] }, { [key: string]: IProfession }, number] => {
    let result = new Map();
    const specId2Participants: { [key: string]: IDoctorInfoWithAvailability[] } = {};
    const specId2Professions: { [key: string]: IProfession } = {};
    participants.forEach(participant => {
      result.set(
        participant.profession,
        result.has(participant.profession) ? [...result.get(participant.profession), participant] : []
      );

      specId2Participants[participant.profession.speciality.id] = specId2Participants.hasOwnProperty(participant.profession.speciality.id)
        ? [...specId2Participants[participant.profession.speciality.id], participant]
        : [participant];

      specId2Professions[participant.profession.speciality.id] = participant.profession;
    });

    return [specId2Participants, specId2Professions, participants.length];
  };

  const throttledTerm: string = useThrottle(searchTerm, 800);

  let [foundParticipants, setFoundParticipants] = useState<IDoctorInfoWithAvailability[]>([]);
  useEffect(() => {
    if (throttledTerm.trim() !== '') {
      let isFresh = true;

      fetchParticipants(throttledTerm, page)
        .then(([participantsTotal, newPageParticipants]) => ({
          participantsTotal,
          newPageParticipants,
          participant2appointmentPromise:
            !dontCheckAvailable ?
              fetchAppointments(newPageParticipants, appointmentDates.startDate, appointmentDates.endDate) :
              (Promise.resolve({}))
        }))
        .then(({participantsTotal, participant2appointmentPromise}) => {
          participant2appointmentPromise.then(participant2appointment => {
            if (isFresh) setFoundParticipants(participantsTotal.map<IDoctorInfoWithAvailability>(participant => ({
              ...participant,
              availability: {
                isAvailable: !participant2appointment[participant.sithsid],
              }
            })));
          });
        });

      return () => {
        isFresh = false;
      };
    } else {
      setFoundParticipants([]);
    }
  }, [throttledTerm, page]);

  return groupParticipantsByProfession(foundParticipants);
}

const cache: { [key: string]: { participants: IDoctorInfo[] } } = {};

function fetchParticipants(term: string, page = 0): Promise<[IDoctorInfo[], IDoctorInfo[]]> {
  const size = 20;
  const httpClient = new HttpClient();

  if (cache[`${term}_${page}`]) {
    return Promise.resolve([cache[`${term}`].participants, cache[`${term}_${page}`].participants]);
  }

  return httpClient.get<IDoctorInfo[]>(
    `/doctor/search/doctor/?query=${term}&size=${size}&page=${page}`)
    .then((res) => res.data)
    .then((result) => {
      cache[`${term}_${page}`] = {participants: result};
      cache[`${term}`] = {participants: [...(!!cache[`${term}`] ? cache[`${term}`].participants : []), ...result]};
      return [cache[`${term}`].participants, cache[`${term}_${page}`].participants];
    });
}
