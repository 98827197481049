import {Badge} from '$/components/badge/Badge';
import LogoWithHealth from '$/components/common/logoWithText';
import Notifications from '$/components/notifications';
import {ChatStore} from '$/contexts/chat';
import {NotificationsStore} from '$/contexts/notifications';
import {Link} from '@reach/router';
import bem from 'bem-ts';
import classNames from 'classnames';
import React, {useContext, useEffect, useState} from 'react';
import './index.scss'

const b = new bem('navHeader', {strict: false})

const Navi = (props) => {
  const [{notifications}] = useContext(NotificationsStore);
  const countNotif = Object.keys(notifications).length

  const [{totalNewMessages} ] = useContext(ChatStore);

  const [showAllNotifications, setShowAllNotifications] = useState(false)

  useEffect(() => {
    if (showAllNotifications) {
      const root = document.getElementById('root');
      const handler = () => setShowAllNotifications(false)

      root.addEventListener('click', handler);
      return () => {
        root.removeEventListener('click', handler)
      }
    }
  }, [showAllNotifications])

  const onNotificationClick = () => {
    if (!showAllNotifications) setShowAllNotifications(true);
  }


  return (
    <nav className={`${b()} ${props.className}`}>
      <LogoWithHealth/>

      <hgroup className={b('menu')}>
        {/*<Link className='navLink' to='/dashboard'>Dashboard</Link>*/}
        <Link className={b('menu__link')} to="/schedule">Calendar</Link>

        <Link to="/chat" style={{marginRight: '12px'}}>
          <Badge count={totalNewMessages || 0} containerClassName={b('menu__link')}>
            Chat
          </Badge>
        </Link>

        <Link className={b('menu__link')} to="/profile">My Profile</Link>

        <button disabled={countNotif <= 0}
                onClick={onNotificationClick}
                className={classNames(
                  b('menu__link'),
                  b('menu__link__notifications', countNotif && ['has'])
                )}
        >
          {countNotif}
          <i className="fas fa-bell"/>
        </button>
      </hgroup>
      {
        showAllNotifications && !!countNotif && (
          <Notifications/>
        )
      }
    </nav>
  )
}

export default Navi;
