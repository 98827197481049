import React, {createContext, useReducer} from 'react';

const initialState = {
  subscribes: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_SUBSCRIBE":
      return {
        ...state,
        subscribes: {
          ...state.subscribes,
          ...action.payload,
        },
      };

    case "UNSUBSCRIBE_ALL":
      return {};

    default:
      return { ...state };
  }
};

export const WebSocketStore = createContext();

const WebSocketStoreProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);

  return (
    <WebSocketStore.Provider value={value}>{children}</WebSocketStore.Provider>
  );
};

export default WebSocketStoreProvider;
