import React, { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import bem from "bem-ts";

import "./index.scss";
import { AppointmentTypeEnum } from "$/models";
import { ScheduleContext } from "$/contexts/schedule";
import { Dialog } from "@reach/dialog";
import TemplateEditForm from "$/components/templateEditForm";

const b = new bem("Template-Editing-Slot", { strict: false });

const TemplateEditingSlot = (props) => {
  const [scheduleStore] = useContext(ScheduleContext);
  const [template, setTemplate] = useState(props.slot);
  const type = useMemo(() => template.appointmentType?.toLowerCase(), [
    template,
  ]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [style, setStyle] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    const startWorkTime = moment.utc(template.startWorkTime).local();
    const endWorkTime = moment.utc(template.endWorkTime).local();

    const countMinutesBefore = moment(startWorkTime).diff(
      moment(startWorkTime).hour(scheduleStore.minHour).minute(0),
      "minutes"
    );
    const sizeRow = 41;
    const countMinutes = moment(endWorkTime).diff(
      moment(startWorkTime),
      "minutes"
    );
    const height = (sizeRow / scheduleStore.zoom) * countMinutes;
    const top = (sizeRow / scheduleStore.zoom) * countMinutesBefore;

    setStyle({
      top: `${top}px`,
      minHeight: `${height}px`,
    });
  }, [template, scheduleStore.zoom]);

  let icon = useMemo(() => {
    switch (type?.toUpperCase()) {
      case AppointmentTypeEnum[0]:
        return <i className="far fa-shoe-prints" />;
      case AppointmentTypeEnum[2]:
        return <i className="far fa-phone-alt" />;
      case AppointmentTypeEnum[1]:
        return <i className="far fa-video" />;
    }
    return <i className="far fa-shoe-prints" />;
  }, [template]);

  const { startWorkTime, endWorkTime } = template;
  const countMinutes = moment(endWorkTime).diff(
    moment(startWorkTime),
    "minutes"
  );
  const hours = countMinutes / 60;

  const openEditForm = () => {
    setShowEditForm(true);
  };
  const closeEditForm = () => {
    setShowEditForm(false);
  };

  const onDelete = () => {
    setIsDeleted(true);
  };

  const onSave = ({
    time,
    type,
  }: {
    time: { startWorkTime: Date; endWorkTime: Date };
    type: string;
  }) => {
    const newTemplate = {
      ...template,
      appointmentType: type,
      startWorkTime: time.startWorkTime,
      endWorkTime: time.endWorkTime,
    };
    const slot = {
      ...template,
      appointmentType: type,
      startWorkTime: moment(time.startWorkTime).format('HH:mm:ss'),
      endWorkTime: moment(time.endWorkTime).format('HH:mm:ss'),
    }
    props.save(newTemplate, slot);
    setTemplate(newTemplate);
  };

  return (
    <div
      data-id={template.id}
      data-time={`${moment(template.startWorkTime).format(
        "HH:mm:00"
      )}  -  ${moment(template.endWorkTime).format("HH:mm:00")}`}
      className={b([type])}
      style={{ ...style, display: isDeleted ? "none" : "flex" }}
      onClick={openEditForm}
    >
      <div style={props.style} className={b("container")} />
      <div style={props.style} className={b("background")} />

      <span className={b("time")}>
        <span>
          {icon}&nbsp;
          {moment.utc(template.startWorkTime).local().format("HH:mm")}-
          {moment.utc(template.endWorkTime).local().format("HH:mm")}
        </span>
        <span>{hours.toFixed(2)}h</span>
      </span>

      {showEditForm && (
        <Dialog aria-label={"EditSlotTemplate"} onDismiss={closeEditForm}>
          <TemplateEditForm
            template={template}
            isEdit={true}
            allSlots={props.allSlots}
            delete={props.delete}
            dismissAction={closeEditForm}
            save={onSave}
          />
        </Dialog>
      )}
    </div>
  );
};

export default TemplateEditingSlot;