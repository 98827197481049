import React, { useState, useEffect, useRef, useContext } from "react";
import { observer } from "mobx-react";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptionText,
  ComboboxPopover,
} from "@reach/combobox";
import ScrolledComboboxList from "$/components/common/scrolledComboboxList";
import { useThrottle } from "$/hooks/useThrottle";

import "./index.scss";
import "@reach/combobox/styles.css";
import bem from "bem-ts";
import { GlobalContext } from "$/contexts/global";
import { Appointment } from "$/stores/appointment";

const WIDTH = 350;
const b = new bem("SymptomsSelector", { strict: false });

interface ISymptomSelectorProps {
  appointmentStore: Appointment;
  isReason?: boolean;
  readonly?: boolean;
}

const SymptomsSelector: React.FC<ISymptomSelectorProps> = ({
  appointmentStore,
  isReason,
  ...props
}) => {
  const globalStore = useContext(GlobalContext);

  const useSymptomMatch = (searchTerm, appointmentDates, page = 0) => {
    const throttledTerm = useThrottle(searchTerm, 800);

    let [foundSymptoms, setFoundSymptoms] = useState([]);
    useEffect(() => {
      if (searchTerm?.trim() !== "") {
        let isFresh = true;
        fetchSymptoms(searchTerm, page).then((symptoms) => {
          let results = [];
          symptoms.forEach((symptom) => {
            results = results.concat(
              symptom.descriptions.map((descrSymptom) => ({
                ...descrSymptom,
                rootId: symptom.id,
              }))
            );
          });
          if (isFresh) setFoundSymptoms(results);
          return () => (isFresh = false);
        });
      }
    }, [throttledTerm, page]);
    return foundSymptoms;
  };

  const cache = {};

  const fetchSymptoms = (term, page = 0) => {
    const size = 20;

    if (cache[`${term}_${page}`] && cache[`${term}`]) {
      return Promise.resolve(cache[`${term}`]);
    }
    return globalStore.http
      .get(`/doctor/references/?filter=${term}&page=${page}&size=${size}`)
      .then((res) => res.data)
      .then((result) => {
        cache[`${term}_${page}`] = result;
        cache[`${term}`] = [...(cache[`${term}`] || []), ...result];
        return cache[`${term}`];
      });
  };

  const comboboxRef = useRef();

  const inputRef = useRef();
  inputRef.current?.setAttribute("autocomplete", "off");

  let [term, setTerm] = useState("");
  let [selectedSymptoms, setSelectedSymptoms] = useState([]);
  let [foundPage, setFoundPage] = useState(0);

  let foundSymptoms = useSymptomMatch(term, appointmentStore, foundPage);

  useEffect(() => {
    setSelectedSymptoms(
      isReason
        ? (appointmentStore.events?.reasons).filter((obj) => !!obj)
        : appointmentStore.events?.symptoms || []
    );
  }, []);

  useEffect(() => {
    const events = appointmentStore.events;
    if (selectedSymptoms) {
      const symptoms = selectedSymptoms?.map((symptom) => ({
        description: symptom.term || symptom.description,
        code:
          symptom.code ||
          (!!symptom?.id
            ? {
                ...symptom,
                id: symptom?.id,
              }
            : null),
      }));
      events[isReason ? "reasons" : "symptoms"] = symptoms;
      appointmentStore.setField("events", events);
    }
  }, [selectedSymptoms]);

  const noMoreItems = () => foundSymptoms.length > 1000;

  const loadMoreItems = () => {
    setFoundPage(foundPage + 1);
  };

  const getItems = (): Promise<void> => {
    return !noMoreItems()
      ? new Promise((res) => {
          setTimeout(() => res(loadMoreItems()), 500);
        })
      : Promise.resolve();
  };

  const handleChange = (event) => {
    event.target.value !== "," && setTerm(event.target.value);
    setFoundPage(0);
  };

  const addSelection = (symptom) => {
    setSelectedSymptoms([
      ...selectedSymptoms,
      { ...symptom, id: symptom.rootId, rootId: undefined },
    ]);

    setTerm("");
    setFoundPage(0);
  };

  const onKeyDown = (event) => {
    if (event.key === "," && event.target.value.length > 0) {
      addSelection({
        id: null,
        term: event.target.value.replace(",", ""),
      });
      setTerm("");
    } else if (
      (event.key === "ArrowLeft" || event.key === "Backspace") &&
      event.target.value.length === 0
    ) {
      setTerm(selectedSymptoms[selectedSymptoms.length - 1].term);
      const newSymptoms = [...selectedSymptoms];
      newSymptoms.splice(-1, 1);
      setSelectedSymptoms(newSymptoms);
    }
  };

  const onClickCombobox = () => {
    inputRef.current.focus();
  };

  return (
    <div className={b("container")} ref={comboboxRef}>
      <div className={b("prefix")}>
        <i className={"far fa-align-left"} />
      </div>
      {props.readonly ? (
        <span>{term}</span>
      ) : (
        <Combobox
          onClick={onClickCombobox}
          style={{ width: WIDTH || props.width }}
          className={b("combobox")}
        >
          <span>
            {selectedSymptoms.map((symptom, index) => (
              <span
                key={`symptom-${index}`}
                className={b("symptom", {
                  hasId: !!symptom.id || !!symptom.code,
                })}
              >
                {symptom.term || symptom.description},&ensp;
              </span>
            ))}
          </span>
          <span>
            <ComboboxInput
              ref={inputRef}
              placeholder={!selectedSymptoms.length && "Symptoms"}
              value={term}
              onKeyDown={onKeyDown}
              onChange={handleChange}
            />
          </span>
          {term && foundSymptoms?.length !== 0 && (
            <ComboboxPopover portal={false} className={b("popover")}>
              <ScrolledComboboxList getItems={getItems}>
                {foundSymptoms.map((symptom) => (
                  <ComboboxOption
                    key={symptom.id}
                    value={symptom.term}
                    className={b("symptomResult")}
                    onClick={() => {
                      addSelection(symptom);
                    }}
                  >
                    <div className={b("symptomResult__fullName")}>
                      <ComboboxOptionText />
                    </div>
                  </ComboboxOption>
                ))}
              </ScrolledComboboxList>
            </ComboboxPopover>
          )}
        </Combobox>
      )}
    </div>
  );
};

export default observer(SymptomsSelector);
