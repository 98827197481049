import AppointmentForm, { initForm } from '$/components/appointmentForm';
import PhotoIcon from '$/components/common/photoIcon';
import Appointment from '$/components/schedule/appointment';
import { ChatStore } from '$/contexts/chat';
import { CurrentUserContext } from '$/contexts/currentUser';
import { GeneralStore } from '$/contexts/reducers';

import { AppointmentStateEnum, IChat } from '$/models';
import { globalHeaders } from '$/services/http-client.service';
import { humanFileSize } from '$/utils';
import { Combobox, ComboboxInput } from '@reach/combobox';
import { Dialog } from '@reach/dialog';
import axios from 'axios';
import bem from 'bem-ts';

import moment from 'moment';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { useDropzone } from 'react-dropzone';
import { IChatMessage } from '../../models/index';

import './index.scss';

const b = bem("Chat", { strict: false });

interface IChatProps {
  chat: IChat;
}

const CheckIndicator: React.FC<{msg: IChatMessage}> = ({msg}) => {
  const deliveredAtTime = moment.utc(msg.dateTime).add(Math.round(Math.random() * 4) + 1, 'second');

  const [deliveredState, setDeliveredState] = useState(moment.utc().isAfter(deliveredAtTime));
  useEffect(() => {
    if(!deliveredState) {
      setTimeout(() => {
        setDeliveredState(true);
      }, -1 * moment.utc().diff(deliveredAtTime, 'milliseconds'));
    }
  }, [deliveredState]);

  if (deliveredState) {
    return <i className="far fa-check-double"></i>;
  }

  return <i className="far fa-check"></i>;
};

const Chat = (props: IChatProps) => {
  const [ , dispatch ] = useContext(ChatStore);

  const messagesList = useRef(null);
  const { chat } = props;
  const { chatId } = chat;
  const [userState] = useContext(CurrentUserContext);
  const [, dispatchChat] = useContext(ChatStore);
  const patient = chat.messages[0].patient;
  const [inputField, setInputField] = useState(null);
  const [attachments, setAttachments] = useState<[] | FileList>([]);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [generalStore, dispatchGeneralStore] = useContext(GeneralStore);

  useEffect(() => {
    if (chat) {
      messagesList.current.scrollTop =
        messagesList.current.scrollHeight - messagesList.current.offsetHeight;
    }
  }, [props.chat]);

  const onDrop = useCallback((acceptedFiles) => {
    setAttachments(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noDrag: true,
    multiple: false,
  });

  const onChangeMessage = (e) => {
    setInputField(e.target.value);
  };

  const onChangeAttachment = (files: FileList) => {
    setAttachments(files);
  };

  /**
   * Drop unread messages
   * */
  useEffect(() => {
    const userId = userState?.currentUser?.sithsid;
    if (chat && chat.unreadMessages?.[userId]) {
      axios.get(
        `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/chat/${chatId}/messages_read`,
        { withCredentials: true , headers: globalHeaders}
      ).then(({ data: chat }) => {
        if (chat && 'unreadMessages' in chat) {
          dispatch({
            type: 'UPDATE_CHAT',
            payload: chat,
          })
        }
      });
    }
  }, [chat]);

  const onSend = (e) => {
    const patientId = patient.bankId;
    const sendFile = (oldChat) => {
      console.log(chat.messages, moment().format("YYYY-mm-DDTHH:mm:ss.SSS"));
      dispatchChat({
        type: "SET_CHAT",
        payload: {
          ...chat,
          chatId: chat.chatId,
          messages: [
            ...chat.messages,
            {
              isLoading: true,
              dateTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
              doctor: userState.currentUser,
              message: attachments[0].name,
              messageType: "FILE",
              patient,
            },
          ],
        },
      });

      const formData = new FormData();
      formData.append("photo", attachments[0]);
      axios
        .post(
          `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/chat/${chatId}/attachments/patient/${patientId}`,
          formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data;",
            },
          }
        )
        .then((res) => res.data)
        .then((res) => {
          dispatchChat({
            type: "SET_CHAT",
            payload: res,
          });
        })
        .catch(() => {
          dispatchChat({
            type: "SET_CHAT",
            payload: oldChat,
          });
        });
    };

    const sendMessage = () => {
      return axios
        .get(
          `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/chat/message/?message=${inputField}&patientId=${patientId}&chatId=${chatId}`,
          { withCredentials: true, headers: globalHeaders }
        )
        .then((res) => {
          dispatchChat({
            type: "SET_CHAT",
            payload: res.data,
          });

          if (chat.state === "TO_NURSE") {
            axios.get(
              `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/chat/join/?chatId=${chatId}`,
              { withCredentials: true, headers: globalHeaders }
            );
          }
          return res;
        });
    };

    if (inputField) {
      sendMessage()
        .then((res) => res.data)
        .then((res) => {
          attachments?.length && sendFile(res);
        });
    } else if (attachments?.length) {
      sendFile(chat);
    }

    setInputField("");
    setAttachments([]);
    scrollToBottom();
    e.preventDefault();
  };

  const scrollToBottom = () => {
    if (messagesList) {
      messagesList.current.scrollTop = messagesList.current.scrollHeight;
    }
  };

  const renderChat = (currentChat) => {
    if (currentChat) {
      let currentDate = currentChat.messages[0].dateTime;
      const renderedMessagesList = [];
      for (let index = 0; index < currentChat.messages.length; index++) {
        const msg = currentChat.messages[index];

        const isFile =
          msg.messageType === "PICTURE" || msg.messageType === "FILE";
        const isChatBot = !msg.doctor;
        let isMyMessage = false;
        let author = "Chatbot";
        if (msg.patientMessage) {
          author = `${patient.personalInfo.firstName} ${patient.personalInfo.lastName}`;
        } else if (msg.doctor) {
          const doctor = msg.doctor;
          if (userState && userState.sithid === msg.doctor.sithid) {
            isMyMessage = true;
            author = "You";
          } else if (isChatBot) {
            author = "Chatbot";
          } else {
            author = `${doctor.personalInfo.firstName} ${doctor.personalInfo.lastName}`;
          }
        }
        const person = msg.patientMessage ? patient : msg.doctor;

        const isNewDay = moment(msg.dateTime).isAfter(currentDate, "days");
        currentDate = isNewDay ? msg.dateTime : currentDate;
        const isToday =
          moment().format("ddd DD MMM YYYY") ===
          moment(currentDate).format("ddd DD MMM YYYY");

        const isAppointmentState = msg.messageType === "APPOINTMENT_STATE";
        let msgAppointmentState = "";

        // FIXME: need value of status. Parsing is the worst solution.
        if (msg.message.endsWith("CHECKED IN")) {
          msgAppointmentState = "CHECKED_IN";
        } else {
          const words = msg.message.split(" ");
          msgAppointmentState = words[words.length - 1];
        }
        const loadFile = () => {
          const config = {
            url: `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/chat/${chat.chatId}/message/${msg.id}/attachment`,
            method: "GET",
            responseType: "blob",
            headers: {
              ...globalHeaders
            }
          };

          axios.request(config).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", msg.message); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
          });
        };

        let textOfMsg;
        if (isFile) {
          textOfMsg = (
            <span className={b("message__text__file")} onClick={loadFile}>
              {msg.message}
            </span>
          );
        } else if (isAppointmentState) {
          switch (msgAppointmentState) {
            case AppointmentStateEnum[0]:
              textOfMsg = (
                <>
                  <i className="far fa-book" />
                  &nbsp; An appointment is booked
                </>
              );
              break;
            case AppointmentStateEnum[1]:
              textOfMsg = (
                <>
                  <i className="far fa-door-open" />
                  &nbsp; Patient is checked in
                </>
              );
              break;
            case AppointmentStateEnum[2]:
              textOfMsg = (
                <>
                  <i className="far fa-credit-card-blank" />
                  &nbsp; Patient payed
                </>
              );
              break;
            case AppointmentStateEnum[3]:
              textOfMsg = (
                <>
                  <i className="far fa-stethoscope" />
                  &nbsp; Patient is diagnosed
                </>
              );
              break;
            case AppointmentStateEnum[4]:
              textOfMsg = (
                <>
                  <i className="far fa-trash-alt" />
                  &nbsp; Appointment is cancelled
                </>
              );
              break;
          }
        } else {
          textOfMsg = <span>{msg.message}</span>;
        }

        renderedMessagesList.push(
          !!msg.message.length && (
            <Fragment key={`message-block-${index}`}>
              {(index === 0 || isNewDay) && (
                <div
                  className={b("startDate")}
                  key={moment(currentDate).format()}
                >
                  <span>
                    {isToday && "Today"}
                    {!isToday && moment(currentDate).format("ddd DD MMM YYYY")}
                  </span>
                </div>
              )}
              <div
                key={msg.id}
                className={`${b("message", isMyMessage && ["isMyMessage"])}
                    ${
                      isAppointmentState &&
                      b("appointment__state", [
                        msgAppointmentState.toLowerCase(),
                      ])
                    }`}
              >
                {!isMyMessage && (
                  <div className={b("message__photo")}>
                    {msg.patientMessage || !!msg.doctor ? (
                      <PhotoIcon person={person} />
                    ) : (
                      <div className={b("message__photo__chatBot")}>
                        <i className="fas fa-comment-alt-smile" />
                      </div>
                    )}
                  </div>
                )}
                <div className={b("message__text")}>
                  {textOfMsg}

                  <div className={b("message__text__details")}>
                    <CheckIndicator msg={msg} />
                    &ensp;
                    {moment.utc(msg.dateTime).local().format("HH:mm")}, {author}
                  </div>
                </div>
                {isMyMessage && (
                  <div className={b("message__photo")}>
                    {msg.patientMessage || !!msg.doctor ? (
                      <PhotoIcon person={person} />
                    ) : (
                      <div className={b("message__photo__chatBot")}>
                        <i className="fas fa-comment-alt-smile" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Fragment>
          )
        );
      }
      const renderedPreviewFilesForSend = useMemo(() => {
        return attachments?.map((file: File, fileIndex: number) => {
          let size = humanFileSize(file.size);
          return (
            <div className={`${b("attachment__item")}`} key={['attachment__item', fileIndex].join('')}>
              {file.name}&nbsp;
              <span className={b("attachment__item__size")}>({size})</span>
              &nbsp;
              <i
                onClick={() =>
                  setAttachments(
                    attachments.filter((current) => file !== current)
                  )
                }
                className="close far fa-times"
              ></i>
            </div>
          );
        });
      }, [attachments]);

      return (
        <>
          <div className={b("content")}>
            <div className={b("messagesList")} ref={messagesList}>
              {renderedMessagesList}
            </div>
            <div className={b("appointment")}>
              {chat.appointment ? (
                <Appointment
                  data={chat.appointment}
                  active={true}
                  isCompact={true}
                  showCancelled={true}
                />
              ) : (
                <span
                  className={b("appointment__create")}
                  onClick={() => {
                    setShowAppointmentForm(true);
                  }}
                >
                  New Appointment
                </span>
              )}
            </div>
          </div>
          <form className={b("sendForm")} onSubmit={() => false}>
            {!!renderedPreviewFilesForSend.length && (
              <div className={b("sendForm__files")}>
                {renderedPreviewFilesForSend}
              </div>
            )}
            <div className={b("sendForm__flexContainer")}>
              <div className={b("attachment__container")}>
                <div
                  {...getRootProps()}
                  className={b("attachment__upload__button")}
                >
                  <input
                    hidden
                    {...getInputProps()}
                    id={"attachment__upload"}
                    className={b("attachment", ["fileinput"])}
                  />
                  <label
                    className={b("attachment__label")}
                    // htmlFor="attachment__upload"
                  >
                    + <i className={"far fa-paperclip"} />
                  </label>
                  {/*{*/}
                  {/*  isDragActive ?*/}
                  {/*      <p className={b('attachment__filesDropZone')}>Drop the files here ...</p> :*/}
                  {/*      <p className={b('attachment__filesHelpMessage')}>Drag 'n' drop some files here, or click to select files</p>*/}
                  {/*}*/}
                </div>
              </div>
              <Combobox className={b("sendForm__inputField")}>
                <ComboboxInput
                  value={inputField}
                  onChange={onChangeMessage}
                  selectOnClick
                  placeholder={"Write your message"}
                />
              </Combobox>
              <button className={b("sendForm__sendButton")} onClick={onSend}>
                <i className="far fa-paper-plane" />
                &ensp;Send
              </button>
            </div>
          </form>
        </>
      );
    }
  };

  const closeAppointmentForm = () => {
    setShowAppointmentForm(false)
  };

  return (
    <div className={`${b()}`}>
      <div className={b('vertical-container')}>{renderChat(chat)}</div>
      {showAppointmentForm && (
        <Dialog
          onDismiss={() => {
            setShowAppointmentForm(false);
          }}
          aria-label="Appointment Form"
        >
          <AppointmentForm
            appointmentStore={{
              ...initForm(),
              patients: [patient],
              doctor: userState.currentUser,
              participants: [userState.currentUser],
              chat,
              isEdit: false,
            }}
            dismissAction={closeAppointmentForm}
            onSave={closeAppointmentForm}
          />
        </Dialog>
      )}
    </div>
  );
};

export default Chat;
