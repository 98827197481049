import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import moment from 'moment';

export const globalHeaders = {
  "X-Client-Timezone": moment().format('ZZ'),
}

export class HttpClient {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.APP_API_DOMAIN,
      withCredentials: true,
      headers: {
        accept: "application/json",
        ...globalHeaders
      },
    });
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosInstance.get<T, R>(url, config).catch((error) => {
      console.log("Error", error);
    });
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosInstance.post<T, R>(url, data, config);
  }
}
