import React, { useState, useMemo, useEffect, useContext } from "react";

import bem from "bem-ts";
import NavHeader from "$/components/navHeader";
import ErrorBoundary from "$/utils/ErrorBoundary/ErrorBoundary";
import ChatList from "$/components/chatList";
import Chat from "$/components/chat";
import { IChat } from "$/models";
import TitlePage from "$/components/common/titlePage";
import MenuChatList from "$/components/chatList/menu";

import "./index.scss";
import { ChatStore } from "$/contexts/chat";
import LoaderColored from "$/components/loaderColored";
import { useNavigate } from "@reach/router";
import { NotificationsStore } from '$/contexts/notifications';

const b = new bem("pageChat", { strict: false });

export default (props) => {
  const navigate = useNavigate();
  const [, dispatchNotifications] = useContext(NotificationsStore);
  const [view, setView] = useState<"list" | "chat">("list");
  const [selectedChat, setSelectedChat] = useState<null | IChat>(null);
  const [selectedChatId, setSelectedChatId] = useState<null | string>(
    props.chatId
  );
  const [chatStore, dispatchChat] = useContext(ChatStore);

  useEffect(() => {
    if (props.chatId || selectedChatId) {
      setView("chat");
      dispatchNotifications({
        type: "REMOVE_NOTIFICATION_CHAT",
        payload: props.chatId || selectedChatId,
      });
    } else {
      setView("list");
    }
  }, [props.chatId, chatStore, selectedChatId]);

  const openChat = (chatId: string) => {
    setSelectedChatId(chatId);
    setView("chat");
  };

  const closeChat = () => {
    navigate("/chat");
    setView("list");
    setSelectedChatId(null);
  };

  const pageHeader = useMemo(() => {
    if (view === "list") {
      return (
        <>
          <TitlePage>Chat</TitlePage>
          <MenuChatList />
        </>
      );
    } else {
      const patient = selectedChat?.messages[0].patient;
      const title = patient && (
        <>
          {patient.personalInfo.firstName} {patient.personalInfo.lastName}
        </>
      );
      return (
        <>
          <div onClick={closeChat} className={b("header__backButton")}>
            <i className="fas fa-chevron-left" />
          </div>
          <TitlePage className={b("header__title")}>
            {title || "Chat"}
          </TitlePage>
          {/*<Menu className={b( 'header__menu')}/>*/}
        </>
      );
    }
  }, [view]);

  const pageContent = useMemo(() => {
    if (view === "list") {
      return <ChatList onSelect={openChat} />;
    } else {
      const chat = chatStore.chatList.find(
        (chat) => chat.chatId === selectedChatId
      );

      return chatStore.isLoadingChatList ? (
        <div className={b("loader")}>
          <LoaderColored />
        </div>
      ) : chat ? (
        <Chat chat={chat} />
      ) : (
        <></>
      );
    }
  }, [view, chatStore]);

  return (
    <ErrorBoundary>
      <div className={`page ${b()}`}>
        <NavHeader />
        <div className={b("header")}>{pageHeader}</div>
        <div className={b("content")}>{pageContent}</div>
      </div>
    </ErrorBoundary>
  );
};
