import LoaderColored from '$/components/loaderColored';
import Column from '$/components/schedule/column';
import FormAdd from '$/components/schedule/formAddParticipants';
import { CurrentUserContext } from '$/contexts/currentUser';
import { ScheduleContext } from '$/contexts/schedule';
import useFetch from '$/hooks/useFetch';
import { IAppointment, IDoctorInfo } from '$/models';
import { Dialog } from '@reach/dialog';

import bem from 'bem-ts';
import moment from 'moment';
import React, { useContext, useEffect, useState, } from 'react';

import '../index.scss';

const b = bem('Schedule-Presentation', { strict: false });

const Days = () => {
  const [ userState ] = useContext(CurrentUserContext);
  const [ scheduleStore, dispatchSchedule ] = useContext(ScheduleContext);
  const [ participants, setParticipants ] = useState<string[]>([]);
  const [ showFormAddParticipants, setShowFormAddParticipants ] = useState(false);

  const [ { response, isLoading }, fetchAppointments ] = useFetch(
    '/doctor/appointments'
  );
  const [ { response: users }, fetchParticipants ] = useFetch('/doctor/info');

  useEffect(() => {
    if (userState.currentUser) {
      setParticipants([
        userState.currentUser.sithsid,
        ...(scheduleStore.participants || []),
      ]);
    }
  }, [ userState, scheduleStore.participants ]);

  useEffect(() => {
    const currentTime = moment(scheduleStore.currentTime).format('YYYY-MM-DDT');
    const users = participants.map((participant) => participant).join(',');
    const url = `/doctor/appointments/${currentTime}00:00/${currentTime}23:59/?users=${users}`;
    const participantsUrl = `/doctor/info/?users=${users}`;
    fetchAppointments({ url });
    fetchParticipants({ url: participantsUrl });
  }, [ participants, scheduleStore.currentTime ]);

  useEffect(() => {
    if (response && users) {
      const forDispatch: Array<any> = [];

      // Create objects with participants
      participants.forEach((participant) => {
        const user = users.find((u) => participant === u.sithsid);
        console.log(users, user);
        const title =
          participant === userState.currentUser.sithsid
            ? 'My'
            : `${user?.personalInfo.firstName} ${user?.personalInfo.lastName}`;
        forDispatch.push({
          date: moment(scheduleStore.currentTime).format('YYYY-MM-DD'),
          keyColumn: participant,
          participant: user,
          title,
          appointments: [],
        });
      });
      participants.forEach((participant, columnIndex) => {
        response.forEach((appointment: IAppointment) => {
          const appointmentParticipants = Array.from(
            new Set([
              appointment.doctor.sithsid,
              ...(appointment.participants
                ? appointment.participants.map((ap) => ap.sithsid)
                : []),
            ])
          );
          if (appointmentParticipants.includes(participant)) {
            forDispatch[columnIndex].appointments.push(appointment);
          }
        });
      });

      forDispatch.forEach((column) => {
        column.appointments = column.appointments.sort((a, b) => {
          if (a.startDate < b.startDate) return -1;
          else if (a.startDate > b.startDate) return 1;
          else return 0;
        });
      });

      dispatchSchedule({
        type: 'SET_COLUMNS',
        payload: forDispatch,
      });
    }
  }, [ response, users ]);

  const closeAddParticipants = () => {
    setShowFormAddParticipants(false);
  };
  const openAddParticipants = () => {
    setShowFormAddParticipants(true);
  };

  const addParticipants = async (persons: IDoctorInfo[]) => {
    const forAdd = [
      ...participants,
      ...persons
        .filter((person) => !participants.includes(person.sithsid))
        .map((participant) => {
          return participant.sithsid;
        }),
    ];

    const participantsToSet = forAdd.filter(
      (p) => p !== userState.currentUser.sithsid
    );

    dispatchSchedule({
      type: 'SET_PARTICIPANTS',
      participants: participantsToSet,
    });

    if (
      !forAdd.every((person) => {
          return participants.map((p) => p).includes(person.sithsid);
        }
      )
    ) {
      setParticipants(forAdd);
    }
    closeAddParticipants();
  };

  return (
    <>
      <div
        className={b('presentation__addParticipant__icon')}
        onClick={openAddParticipants}
      >
        <i className={'far fa-user-edit'}/>
      </div>
      <div className={b('presentation__columns')}>
        {isLoading ? (
          <LoaderColored className={b('presentation__columns__loader')}/>
        ) : (
          (scheduleStore.columns || []).map((column) => {
            return (
              <Column
                date={column.date}
                key={column.keyColumn}
                keyColumn={column.keyColumn}
                title={column.title}
                appointments={column.appointments}
                participant={column.participant}
              />
            );
          })
        )}
      </div>
      {showFormAddParticipants && (
        <Dialog
          className={b('addParticipant__form')}
          onDismiss={closeAddParticipants}
        >
          <FormAdd
            date={ScheduleContext.currentTime}
            onAdd={addParticipants}
            onClose={closeAddParticipants}
          />
        </Dialog>
      )}
    </>
  );
};

export default Days;
