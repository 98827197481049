import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import bem from "bem-ts";
import SymptomsSelector from "$/components/symptomsSelector";
import { Combobox, ComboboxInput } from "@reach/combobox";
import Button from "$/components/common/button";

import "./index.scss";

const b = bem("DoctorNotes", { strict: false });

const DoctorNotes = ({ appointmentStore, ...props }) => {
  const [notes, setNotes] = useState(appointmentStore.notes || "");

  const onSend = () => {
    appointmentStore.saveAppointment().then(() => {
      appointmentStore.changeStatus("DIAGNOSED");
    });
  };
  useEffect(() => {
    appointmentStore.setField("notes", notes);
  }, [notes]);

  return (
    <div className={b("form")}>
      <div
        className={b("item", {
          symptoms: true,
          hasTerm: !!appointmentStore.events.symptoms?.length,
        })}
      >
        <div className={b("item__title")}>
          <span>DIAGNOSED SYMPTOMS</span>
        </div>
        <SymptomsSelector
          appointmentStore={appointmentStore}
          readonly={props.readonly}
        />
      </div>
      <div
        className={b("item", {
          notes: true,
          hasTerm: !!appointmentStore.notes?.length,
        })}
      >
        <div className={b("item__title")}>
          <span>NOTES</span>
        </div>
        {props.readonly ? (
          <span>{appointmentStore.notes}</span>
        ) : (
          <Combobox>
            <ComboboxInput
              value={notes}
              placeholder={"Write your notes"}
              data-textarea-notes
              as={"textarea"}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </Combobox>
        )}
      </div>

      <Button
        className={b("send")}
        disabled={
          appointmentStore.appointmentState === "DIAGNOSED" ||
          appointmentStore.appointmentState === "COMPLETED"
        }
        view={
          appointmentStore.appointmentState === "DIAGNOSED" ||
          (appointmentStore.appointmentState === "COMPLETED" && "completed")
        }
        onClick={onSend}
      >
        <i className="far fa-signature"></i> Sign
      </Button>
    </div>
  );
};

export default observer(DoctorNotes);
