import * as React from "react";
import bem from "bem-ts";
import axios from "axios";
import { CurrentUserContext } from "$/contexts/currentUser";
import { useCallback, useContext } from "react";
import NavHeader from "$/components/navHeader";
import TitlePage from "$/components/common/titlePage";
import ErrorBoundary from "$/utils/ErrorBoundary/ErrorBoundary";
import "./index.scss";
import { useDropzone } from "react-dropzone";

const b = new bem("pageProfile", { strict: false });

const Profile: React.FunctionComponent<any> = (props) => {
  const [userState] = useContext(CurrentUserContext);
  const firstName =
    userState.isLoggedIn && userState.currentUser?.personalInfo?.firstName;
  const lastName =
    userState.isLoggedIn && userState.currentUser?.personalInfo?.lastName;
  const specialisation =
    userState.isLoggedIn && userState.currentUser?.profession?.speciality.text;
  const qualification =
    userState.isLoggedIn && userState.currentUser?.profession?.occupation;
  const phones =
    userState.isLoggedIn &&
    userState.currentUser?.personalInfo?.contacts.length &&
    userState.currentUser.personalInfo.contacts[0].phones.join(", ");
  const hasPhoto =
    userState.isLoggedIn && !!userState.currentUser?.personalInfo?.photo;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, i) => {
      const formData = new FormData();
      formData.append("photo", file);
      axios
        .post(
          `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/photo`,
          formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          window.location.reload();
        });
    });

    // Do something with the files
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop /*, noDrag: true, multiple: false*/,
  });

  return (
    <ErrorBoundary>
      <NavHeader />
      <div className={`page ${b()}`}>
        <div className={b("photo")}>
          <div className={b("photo__change")}>
            <label
              className={`${b("photo__change__button")} ${b(
                "photo__change__text"
              )}`}
            >
              <input
                {...getInputProps()}
                id={"photo__upload"}
                className={b("photo__input", ["fileinput"])}
              />
              Change photo
            </label>
          </div>
          {hasPhoto ? (
            <img
              src={`${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/photo`}
              alt={""}
              className={b("photo__image")}
            />
          ) : (
            <i className={`fas fa-user-md ${b("photo__icon")}`} />
          )}
        </div>
        <div>
          <div className={b("header")}>
            <TitlePage>{`${firstName} ${lastName}`}</TitlePage>
          </div>
          <div className={b("content")}>
            <div className={b("content__item")}>
              <span className={b("content__item__title")}>Specialisation</span>
              <span className={b("content__item__value")}>
                {specialisation}
              </span>
            </div>
            <div className={b("content__item")}>
              <span className={b("content__item__title")}>Qualification</span>
              <span className={b("content__item__value")}>{qualification}</span>
            </div>
            <div className={b("content__item")}>
              <span className={b("content__item__title")}>Phone</span>
              <span className={b("content__item__value")}>{phones}</span>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Profile;
