import React, { Fragment, useState, useEffect } from "react";
import { observer } from "mobx-react";

import axios from "axios";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptionText,
  ComboboxPopover,
} from "@reach/combobox";

import ScrolledComboboxList from "$/components/common/scrolledComboboxList";
import { useThrottle } from "$/hooks/useThrottle";

import "./index.scss";
import "@reach/combobox/styles.css";
import bem from "bem-ts";
import { HttpClient } from "$/services/http-client.service";

const WIDTH = 350;

const b = new bem("PreparationSelector", { strict: false });

const PreparationSelector = ({ appointmentStore, ...props }) => {
  let [selectedPreparations, setSelectedPreparations] = useState(
    appointmentStore.preparations || []
  );
  let [term, setTerm] = useState("");
  let [foundPage, setFoundPage] = useState(0);

  const foundPreparations = usePreparationMatch(
    term,
    appointmentStore,
    foundPage
  );

  useEffect(() => {
    appointmentStore.setField("preparations", selectedPreparations);
  }, [selectedPreparations]);

  const noMoreItems = () => foundPreparations.length > 1000;

  function loadMoreItems() {
    setFoundPage(foundPage + 1);
  }

  function getItems(): Promise<void> {
    return !noMoreItems()
      ? new Promise((res) => {
          setTimeout(() => res(loadMoreItems()), 500);
        })
      : Promise.resolve();
  }

  function handleChange(event) {
    setTerm(event.target.value);
    setFoundPage(0);
  }

  function addSelection(preparation) {
    if (!selectedPreparations.find((sp) => sp === preparation)) {
      setSelectedPreparations([...selectedPreparations, preparation]);
    }
    setTerm("");
    setFoundPage(0);
  }

  function removePreparation(preparation) {
    setSelectedPreparations(
      selectedPreparations.filter((selected) => selected !== preparation)
    );
    return false;
  }

  return (
    <Fragment>
      <div className={b("container")}>
        <div className={b("prefix")}>
          <i className={"far fa-vial"} />
        </div>
        <Combobox openOnFocus aria-label="Cities" style={{ width: WIDTH }}>
          <ComboboxInput
            className="city-search-input"
            style={{ width: WIDTH - 60 }}
            placeholder={"Add preparations"}
            onChange={handleChange}
            value={term}
          />
          {term.length > 0 && (
            <a
              className={b("clear-input")}
              onClick={() => {
                setTerm("");
                return false;
              }}
            >
              <i className={"far fa-times"} />
            </a>
          )}
          {term.length > 0 && foundPreparations.length > 0 && (
            <ComboboxPopover
              className="shadow-popup appointmentForm__shadow-popup"
              style={{ width: WIDTH }}
            >
              <ScrolledComboboxList getItems={getItems}>
                {foundPreparations.map((preparation, idx) => {
                  const photo = (
                    <div
                      className={`${b("preparationResult__photo")} 
                        ${b("preparationResult__photo", ["empty"])}`}
                    >
                      {preparation[0]}
                    </div>
                  );

                  return (
                    <ComboboxOption
                      key={`preparation_${idx}`}
                      className={b("preparationResult")}
                      value={preparation}
                      onClick={() => {
                        addSelection(preparation);
                      }}
                    >
                      {photo}
                      <div className={b("preparationResult__info")}>
                        <div className={b("preparationResult__fullName")}>
                          <ComboboxOptionText />
                        </div>
                      </div>
                    </ComboboxOption>
                  );
                })}
              </ScrolledComboboxList>
            </ComboboxPopover>
          )}
        </Combobox>
      </div>
      {selectedPreparations.length > 0 && (
        <div className="currentSelection">
          {selectedPreparations.map((preparation, idx) => {
            const photo = (
              <div
                className={`${b("preparationResult__photo")}
                        ${b("preparationResult__photo", ["empty"])}`}
              >
                {preparation[0]}
              </div>
            );
            return (
              <div
                key={`preparation_${idx}`}
                className={b("preparationSelected")}
              >
                <div className={b("preparationResult")}>
                  {photo}
                  <div className={b("preparationResult__info")}>
                    <div className={b("preparationResult__fullName")}>
                      {preparation}
                    </div>
                  </div>
                </div>
                <a
                  className={b("preparationSelected__remove")}
                  onClick={() => {
                    removePreparation(preparation);
                    return false;
                  }}
                >
                  <i className={"far fa-times"} />
                </a>
              </div>
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

function usePreparationMatch(searchTerm, appointmentDates, page = 0): any[] {
  const throttledTerm = useThrottle(searchTerm, 800);

  let [foundPreparations, setFoundPreparations] = useState([]);
  useEffect(() => {
    if (throttledTerm.trim() !== "") {
      let isFresh = true;
      fetchPreparations(throttledTerm, page)
        .then((preparations) => {
          if (isFresh) setFoundPreparations(preparations);
          return preparations;
        })
        .then((preparations) => {
          setFoundPreparations(preparations);
        });

      return () => (isFresh = false);
    }
  }, [throttledTerm, page]);
  return foundPreparations;
}

const cache = {};

function fetchPreparations(term, page = 0) {
  const size = 20;
  const httpClient = new HttpClient();

  if (cache[`${term}_${page}`] && cache[`${term}`]) {
    return Promise.resolve(cache[`${term}`]);
  }

  return httpClient
    .get(`/doctor/preparations/?filter=${term}&size=${size}&page=${page}`)
    .then((res) => res.data)
    .then((result) => {
      cache[`${term}_${page}`] = result;
      cache[`${term}`] = [...(cache[`${term}`] || []), ...result];
      return cache[`${term}`];
    });
}

export default observer(PreparationSelector);
