import React, {
  Fragment,
  useState,
  useMemo,
  useEffect,
  useContext,
} from "react";

import {observer} from 'mobx-react'
import matchSorter from "match-sorter";
import moment from "moment";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
  ComboboxPopover,
} from "@reach/combobox";
import { CurrentUserContext } from "$/contexts/currentUser";
import { useThrottle } from "$/hooks/useThrottle";

import "./index.scss";
import "@reach/combobox/styles.css";
import bem from "bem-ts";
import {HttpClient} from "$/services/http-client.service";

const WIDTH = 350;
const b = new bem("LocationSelector", { strict: false });

const LocationSelector = ({ appointmentStore ...props }) => {
  let [selectedLocation, setSelectedLocation] = useState(
    appointmentStore.place || {}
  );
  let [term, setTerm] = useState(
    (!!appointmentStore.roomNumber && `${appointmentStore.roomNumber}`) ||
      ""
  );

  const foundLocations = useLocationMatch(term);

  useEffect(() => {
    appointmentStore.setField("place", selectedLocation);
  }, [selectedLocation]);

  function handleChange(event) {
    setTerm(event.target.value);
  }

  function changeSelection(location) {
    if (selectedLocation !== location) {
      fetchAppointments(
        location,
        appointmentStore.startDate,
        appointmentStore.endDate
      ).then((busyAppointments) => {
        location.isAvailable = !busyAppointments.length;
        setSelectedLocation(location);
      });
    }
    setTerm(location.roomTitle);
  }

  return (
    <Fragment>
      <div className={b("container")}>
        <div className={b("prefix")}>
          <i className={"far fa-map-marker"} />
        </div>
        <Combobox openOnFocus aria-label="Cities" style={{ width: WIDTH }}>
          <ComboboxInput
            className="city-search-input"
            style={{ width: WIDTH - 60 }}
            placeholder={"Add locations"}
            onChange={handleChange}
            value={term}
          />
          {term.length > 0 && (
            <a
              className={b("clear-input")}
              onClick={() => {
                setTerm("");
                return false;
              }}
            >
              <i className={"far fa-times"} />
            </a>
          )}
          {term.length > 0 &&
            term !== selectedLocation.roomTitle &&
            foundLocations.length > 0 && (
              <ComboboxPopover
                className="shadow-popup appointmentForm__shadow-popup"
                style={{ width: WIDTH }}
              >
                <ComboboxList>
                  {foundLocations.map((location, idx) => {
                    return (
                      <ComboboxOption
                        key={`location_${idx}`}
                        className={b("locationResult")}
                        value={location.roomTitle}
                        onClick={() => {
                          changeSelection(location);
                        }}
                      >
                        <div className={b("locationResult__info")}>
                          <div className={b("locationResult__fullName")}>
                            <ComboboxOptionText />
                          </div>
                        </div>
                      </ComboboxOption>
                    );
                  })}
                </ComboboxList>
              </ComboboxPopover>
            )}
        </Combobox>
      </div>
    </Fragment>
  );
};

function useLocationMatch(searchTerm): any[] {
  const throttledTerm = useThrottle(searchTerm, 800);
  const [userState] = useContext(CurrentUserContext);

  return useMemo(
    () =>
      throttledTerm.trim() === ""
        ? userState.currentUser?.clinic?.places
        : matchSorter(userState.currentUser?.clinic?.places, throttledTerm, {
            keys: [(item) => `${item.roomNumber}, ${item.roomTitle}`],
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [throttledTerm]
  );
}

function fetchAppointments(location, startDateTime, endDateTime) {
  const locationId = location.roomNumber;
  const httpClient = new HttpClient()

  if (!startDateTime || !endDateTime) {
    // if appointment time limits not fully specified, then don't check on possible place's appointments at all
    // check only fully specified time slots

    return [];
  } else {
    const startDateTimeISO = moment(startDateTime).format(
      "YYYY-MM-DDTHH:mm:00"
    );
    const endDateTimeISO = moment(endDateTime).format("YYYY-MM-DDTHH:mm:00");

    return httpClient.get(
        `/doctor/appointments/${startDateTimeISO}/${endDateTimeISO}/?places=${locationId}`)
      .then((res) => res.data)
      .then((result) => {
        result.forEach((appointmentData) => {
          // appointmentData.startDate
          // appointmentData.endDate
          appointmentData.place;
        });
        return result;
      });
  }
}

export default observer(LocationSelector);
