import * as React from "react";
import Calendar from "$/components/common/calendar";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import ButtonIcon from "$/components/common/icons/prev-button.svg";
import CalendarIcon from "$/components/common/icons/calendar.svg";
import { ScheduleContext } from "$/contexts/schedule";

import "./index.scss";
import Button from "$/components/common/button";
import bem from "bem-ts";

const b = new bem("Menu-Calendar", { strict: false });
const bAppointmentDate = new bem("AppointmentDateSelector", { strict: false });

const SelectorDate: React.FunctionComponent<any> = ({ hasChoosing = true }) => {
  const refButton = useRef();
  const refPopover = useRef();
  const [scheduleState, dispatchSchedule] = useContext(ScheduleContext);
  const isWeek = scheduleState.view === "week";
  const [value, setValue] = useState<Date>(scheduleState.currentTime);
  const [visibleCalendar, setVisibleCalendar] = useState<boolean>(false);

  useEffect(() => {
    setValue(scheduleState.currentTime);
  }, [scheduleState.currentTime]);

  const changeValue = (val) => {
    dispatchSchedule({
      type: "SET_CURRENT_TIME",
      payload: val,
    });
  };

  const onClick = (type: string) => {
    switch (type) {
      case "next":
        if (isWeek) {
          const nextWeek = moment(scheduleState.currentTime)
            .add(1, "week").toDate(); // .isoWeek()
          changeValue(nextWeek);
        } else {
          changeValue(moment(scheduleState.currentTime).add(1, "d").toDate());
        }
        break;
      case "prev":
        if (isWeek) {
          const nextWeek = moment(scheduleState.currentTime)
            .subtract(1, "week").toDate(); // .isoWeek()
          changeValue(nextWeek);
        } else {
          changeValue(
            moment(scheduleState.currentTime).subtract(1, "d").toDate()
          );
        }
        break;
    }
  };

  const onChangeCalendar = (value: Date | number) => {
    changeValue(value);
    setVisibleCalendar(false);
  };

  const onChangeView = () => {
    if (scheduleState.view === "day") {
      dispatchSchedule({
        type: "SET_VIEW",
        payload: "week",
      });
    } else {
      dispatchSchedule({
        type: "SET_VIEW",
        payload: "day",
      });
    }
    setVisibleCalendar(false);
  };

  const open = () => {
    setVisibleCalendar(true);
  };

  useEffect(() => {
    if (visibleCalendar) {
      const handler = (event) => {
        if (!refPopover.current.contains(event.target))
          setVisibleCalendar(false);
      };
      document.addEventListener("click", handler);
      return () => {
        document.removeEventListener("click", handler);
      };
    }
  }, [visibleCalendar]);

  return (
    <div className={b()}>
      <div className={b("field")}>
        <img src={ButtonIcon} onClick={() => onClick("prev")} />

        <span
          className={b("container__item__text")}
          onClick={() => {
            setVisibleCalendar(!visibleCalendar);
          }}
        >
          <img src={CalendarIcon} />
          <div>
            <button onClick={open} ref={refButton} className={b("label")}>
              {scheduleState.view === "week"
                ? `Week ${
                    typeof value === "number" ? value : moment(value).isoWeek()
                  }`
                : moment(value).format("D MMM YYYY")}
            </button>
          </div>
        </span>
        <img
          src={ButtonIcon}
          style={{ transform: "rotate(180deg)" }}
          onClick={() => onClick("next")}
        />
      </div>
      {visibleCalendar && (
        <div
          className={bAppointmentDate("fields__date__popover")}
          ref={refPopover}
        >
          <div className={bAppointmentDate("fields__date__calendar")}>
            <div className={b("container")}>
              <i
                className={"close far fa-times"}
                onClick={() => refButton.current.click()}
              />
              <div className={b("container__wrapper")}>
                {hasChoosing && (
                  <div className={b("buttons-change-view")}>
                    <Button
                      view={"transparent"}
                      disabled={scheduleState.view === "day"}
                      active={scheduleState.view === "day"}
                      onClick={onChangeView}
                    >
                      Day view
                    </Button>
                    <Button
                      view={"transparent"}
                      disabled={scheduleState.view === "week"}
                      active={scheduleState.view === "week"}
                      onClick={onChangeView}
                    >
                      Week view
                    </Button>
                  </div>
                )}

                <Calendar
                  className={b("component")}
                  showWeekNumbers={true}
                  value={value}
                  modeSelect={scheduleState.view}
                  onChange={onChangeCalendar}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectorDate;
