import Button from '$/components/common/button';
import TitlePage from '$/components/common/titlePage';
import LoaderColored from '$/components/loaderColored';
import NavHeader from '$/components/navHeader';
import Column from '$/components/scheduleEditingColumn';
import ScheduleEditingForm from '$/components/scheduleEditingForm';
import Templates from '$/components/scheduleEditingTemplates';
import { ScheduleContext } from '$/contexts/schedule';
import useFetch from '$/hooks/useFetch';
import { AppointmentTypeEnum, IAppointment } from '$/models';
import ErrorBoundary from '$/utils/ErrorBoundary/ErrorBoundary';
import { Dialog } from '@reach/dialog';
import { Link } from '@reach/router';
import bem from 'bem-ts';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import Menu from './Menu';

const b = new bem("EditTemplates", { strict: false });

const EditTemplatesPage = () => {
  window.moment = moment;
  const [scheduleStore, dispatchSchedule] = useContext(ScheduleContext);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [appointmentType, setAppointmentType] = useState(
    AppointmentTypeEnum[0]
  );
  const [showTemplates, setShowTemplates] = useState(false);
  const [slots, setSlots] = useState([]);

  const [{ response, isLoading }, fetchAppointments] = useFetch(
    "/doctor/appointments"
  );

  useEffect(() => {
    const week = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] };
    (response || [])
      .sort((a, b) => {
        if (a.startDate > b.startDate) return 1;
        else if (a.startDate < b.startDate) return -1;
        else return 0;
      })
      .forEach((appointment: IAppointment) => {
        if (
          !!appointment.appointmentType &&
          appointment.appointmentState !== "CANCELED"
        ) {
          const day = moment(appointment.startDate).isoWeekday();

          const slot = {
            ...appointment,
            isLocked: appointment.status !== "WORK_TIME",
          };
          week[day].push(slot);
        }
      });
    setSlots(week);
  }, [response]);

  useEffect(() => {
    if (typeof scheduleStore.currentTime === "number") {
      console.warn("Deprecated usage of number current time");
    }
    const momentWeek =
      typeof scheduleStore.currentTime === "number"
        ? moment().isoWeek(scheduleStore.currentTime)
        : moment(scheduleStore.currentTime);
    const firstDay = momentWeek.isoWeekday(1).format("YYYY-MM-DDT");
    const lastDay = momentWeek.isoWeekday(7).format("YYYY-MM-DDT");

    fetchAppointments({
      url: `/doctor/appointments/${firstDay}00:00/${lastDay}23:59`,
    });
  }, [fetchAppointments, scheduleStore?.currentTime]);

  const renderHours = () => {
    const startTime = moment().hour(scheduleStore.minHour).minute(0).second(0);
    const endTime = moment().hour(scheduleStore.maxHour).minute(0).second(0);
    const times = [];
    for (
      let now = moment(startTime);
      now <= endTime;
      now.add(scheduleStore.zoom || 15, "minutes")
    ) {
      times.push(moment(now));
    }
    return times.map((time) => {
      return (
        <div className={b("schedule__hours__hour")} key={time.format("HH:mm")}>
          <span className={b("schedule__hours__hour__text")}>
            {time.format("HH:mm")}
          </span>
        </div>
      );
    });
  };

  const renderBackground = () => {
    const zoomTime = scheduleStore.zoom;
    const countRows =
      (scheduleStore.maxHour - scheduleStore.minHour) * (60 / zoomTime);

    const renderRows: any[] = [];
    for (let i = 0; i <= countRows; i++) {
      renderRows.push(
        <div className={b("schedule__background__row")} key={`row-${i}`} />
      );
    }
    return renderRows;
  };

  const onSend = () => {
    const momentWeek = moment(scheduleStore.currentTime);
    const firstDay = momentWeek.isoWeekday(1).format("YYYY-MM-DDT");
    const lastDay = momentWeek.isoWeekday(7).format("YYYY-MM-DDT");

    fetchAppointments({
      url: `/doctor/appointments/${firstDay}00:00/${lastDay}23:59`,
    });
  };

  const onEditAppointment = (a: IAppointment) => {
    const momentWeek = moment(scheduleStore.currentTime);
    const firstDay = momentWeek.isoWeekday(1).format("YYYY-MM-DDT");
    const lastDay = momentWeek.isoWeekday(7).format("YYYY-MM-DDT");

    fetchAppointments({
      url: `/doctor/appointments/${firstDay}00:00/${lastDay}23:59`,
    });
  };

  const renderColumns = (
    week: number,
    slots: IAppointment[][],
    during: number
  ) => {
    if (week) {
      if (typeof scheduleStore.currentTime === "number") {
        console.warn("Deprecated usage of number current time");
      }
      const countMinutes = moment()
        .hour(scheduleStore.maxHour)
        .diff(moment().hour(scheduleStore.minHour), "minutes");
      const height = (countMinutes / during) * 42;
      const momentWeek = moment(scheduleStore.currentTime);
      return [1, 2, 3, 4, 5, 6, 7].map((day: number, index: number) => {
        const currentSlots = slots[index + 1];
        return (
          <Column
            onCreateFreeSlot={onSend}
            onEditAppointment={onEditAppointment}
            title={
              day === 1
                ? momentWeek.isoWeekday(day).format("ddd, DD MMM YYYY")
                : momentWeek.isoWeekday(day).format("ddd, DD MMM ")
            }
            date={momentWeek.isoWeekday(day).format("YYYY-MM-DDTHH:mm:ss")}
            slots={currentSlots}
            height={height}
            key={`column-${index}`}
          />
        );
      });
    }
  };

  const onClickChoosingType = (type: number) => {
    setAppointmentType(AppointmentTypeEnum[type]);
    setShowAppointmentForm(true);
  };

  const close = () => {
    setShowAppointmentForm(false);
  };

  const closeTemplate = () => {
    setShowTemplates(false);
  };

  const onSuccessApply = () => {
    if (typeof scheduleStore.currentTime === "number") {
      console.warn("Deprecated usage of number current time");
    }

    if (typeof scheduleStore.currentTime !== "number") {
      const momentWeek = moment(scheduleStore.currentTime);
      const firstDay = momentWeek.isoWeekday(1).format("YYYY-MM-DDT");
      const lastDay = momentWeek.isoWeekday(7).format("YYYY-MM-DDT");

      fetchAppointments({
        url: `/doctor/appointments/${firstDay}00:00/${lastDay}23:59`,
      });
    }
  };

  return (
    <ErrorBoundary>
      <div className={`page ${b()}`}>
        <NavHeader />
        <div className={b("header")}>
          <Link to={`/schedule/`} className={b("header__backButton")}>
            <i className="fas fa-chevron-left" />
          </Link>
          <TitlePage className={b("header__title")}>
            Your schedule editing
          </TitlePage>
          <Menu />
        </div>
        <div className={b("content")}>
          <div className={b("schedule")}>
            <div className={b("schedule__hours")}>{renderHours()}</div>
            <div className={b("schedule__background")}>
              {renderBackground()}
            </div>
            <div className={b("schedule__columns")}>
              {isLoading ? (
                <LoaderColored />
              ) : (
                renderColumns(
                  scheduleStore.currentTime,
                  slots,
                  scheduleStore.zoom
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={b("footer")}>
        <div className={b("footer__slots")}>
          <Button
            className={b("footer__slots__button", ["physical"])}
            onClick={() => onClickChoosingType(AppointmentTypeEnum.PHYSICAL)}
          >
            Physical
          </Button>
          <Button
            className={b("footer__slots__button", ["call"])}
            onClick={() => onClickChoosingType(AppointmentTypeEnum.CALL)}
          >
            Call
          </Button>
          <Button
            className={b("footer__slots__button", ["video"])}
            onClick={() => onClickChoosingType(AppointmentTypeEnum.VIDEO)}
          >
            Video
          </Button>
          <div className={b("footer__slots__button", ["templates"])}>
            <span
              className={b("footer__slots__button__text")}
              onClick={() => setShowTemplates(true)}
            >
              Templates
            </span>
          </div>
        </div>
      </div>
      {showAppointmentForm && (
        <Dialog
          aria-label={"EditTemplateForm"}
          onDismiss={() => {
            setShowAppointmentForm(false);
            setAppointmentType(AppointmentTypeEnum.PHYSICAL);
          }}
        >
          <div>
            <ScheduleEditingForm
              onSend={onSend}
              dismissAction={close}
              isEdit={true}
              initialValues={{
                appointmentType,
                startDate: moment(scheduleStore.currentTime)
                  .utc()
                  .startOf("day")
                  .set("day", 1)
                  .format("YYYY-MM-DDTHH:00:00"),
                endDate: moment(scheduleStore.currentTime)
                  .utc()
                  .startOf("day")
                  .set("day", 1)
                  .minutes(0)
                  .add(15, "minutes")
                  .format("YYYY-MM-DDTHH:mm:00"),
              }}
            />
          </div>
        </Dialog>
      )}
      {showTemplates && (
        <Dialog aria-label={"Templates"} onDismiss={closeTemplate}>
          <Templates
            currentWeek={moment(scheduleStore.currentTime).isoWeek()}
            dismissAction={closeTemplate}
            onSuccessApply={onSuccessApply}
          />
        </Dialog>
      )}
    </ErrorBoundary>
  );
};

export default EditTemplatesPage;
