import { action, observable, makeObservable } from "mobx";
import { HttpClient } from "$/services/http-client.service";
import { IDoctorInfo } from "$/models";
import CalendarStore from "$/stores/calendar";

export class GlobalStore {
  public http: HttpClient;
  // public calendarStore: CalendarStore;
  public currentPage: string = "calendar";
  public currentUser?: IDoctorInfo = undefined;

  constructor() {
    makeObservable<GlobalStore>(this, {
      currentPage: observable,
      currentUser: observable,
      updateCurrentPage: action,
      setCurrentUser: action,
    });

    this.http = new HttpClient();
    // this.calendarStore = new CalendarStore();
  }

  public setCurrentUser(user: IDoctorInfo) {
    this.currentUser = user;
  }

  public updateCurrentPage(page: string): void {
    this.currentPage = page;
  }
}
export default GlobalStore;
