import React, { useContext, useEffect, useRef, useState } from "react";
import bem from "bem-ts";
import classNames from "classnames";

import "./index.scss";
import { ScheduleContext } from "$/contexts/schedule";
import moment from "moment";
import { Resizable } from "re-resizable";
import Button from "$/components/common/button";
import { Dialog } from "@reach/dialog";
import ScheduleEditingForm from "$/components/scheduleEditingForm";

const b = bem("Editing-Column-FreeSlot", { strict: false });

interface IFreeSlotProps {
  slot: {
    startDate: string;
    endDate: string;
    maxTime: string;
    minTime: string;
  };
  onCreateFreeSlot: () => void;
}

const FreeSlot: React.FC<IFreeSlotProps> = ({ slot, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [editing, setEditing] = useState(false);
  const [currentSlot, setCurrentSlot] = useState(slot);
  const [initTop, setInitTop] = useState();
  const [top, setTop] = useState(0);
  const [height, setHeight] = useState(0);
  const [initHeight, setInitHeight] = useState();
  const [styleResizeBox, setStyleResizeBox] = useState({
    position: "absolute",
  });
  const [delayHandler, setDelayHandler] = useState(null);

  const [scheduleStore] = useContext(ScheduleContext);

  const refSlot = useRef(null);
  const refCreate = useRef(null);
  const refCancel = useRef(null);
  const pixelsInMinute = 41 / scheduleStore.zoom;

  const handleMouseEnter = () => {
    !editing &&
      setDelayHandler(
        setTimeout(() => {
          setShowButton(true);
        }, 1000)
      );
  };

  const handleMouseLeave = () => {
    !editing && setShowButton(false);
    clearTimeout(delayHandler);
  };

  useEffect(() => {
    setHeight(initHeight);
    setTop(initTop);
  }, [initTop, initHeight]);

  useEffect(() => {
    const minutesBefore = moment
      .utc(slot.startDate)
      .local()
      .diff(
        moment
          .utc(slot.startDate)
          .local()
          .hours(scheduleStore.minHour)
          .minute(0),
        "minutes"
      );

    setInitHeight(41);
    const top = (41 / scheduleStore.zoom) * minutesBefore + 1;
    setInitTop(top);
  }, [slot, scheduleStore.zoom]);

  const onResizeStart = (e, dir, refToElement) => {
    if (dir === "top") {
      setStyleResizeBox({ ...styleResizeBox, bottom: "0" });
    } else {
      setStyleResizeBox({ ...styleResizeBox, bottom: "auto" });
    }
  };

  const onResize = (e, direction, ref, d) => {
    const heightInMinutes = (scheduleStore.zoom / 41) * (height + d.height);
    if (direction === "top") {
      const newStartDate = moment(currentSlot.endDate).subtract(
        Math.round(heightInMinutes),
        "minutes"
      );
      setCurrentSlot({
        ...currentSlot,
        startDate: newStartDate.format("YYYY-MM-DDTHH:mm:SS"),
      });
    } else {
      const heightLimitInMinutes = moment(currentSlot.maxTime).diff(
        moment(currentSlot.startDate),
        "minutes"
      );
      if (heightInMinutes <= heightLimitInMinutes) {
        const newEndDate = moment(currentSlot.startDate).add(
          Math.round(heightInMinutes),
          "minutes"
        );
        setCurrentSlot({
          ...currentSlot,
          endDate: newEndDate.format("YYYY-MM-DDTHH:mm:SS"),
        });
      }
    }
  };

  const onResizeStop = (e, direction, ref, d) => {
    if (direction === "top") {
      setTop(top - d.height);
    }
    setHeight(height + d.height);
  };

  const startEdit = () => {
    setEditing(true);
  };

  const stopEdit = (save: boolean = false) => {
    setEditing(false);
    setShowButton(false);
    if (!save) {
      setCurrentSlot(slot);
      setTop(initTop);
      setHeight(initHeight);
    }
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const openForm = () => {
    setShowForm(true);
  };

  useEffect(() => {
    const handleClickDocument = (event) => {
      if (
        !refSlot.current.contains(event.target) ||
        event.target == refCancel.current
      ) {
        stopEdit();
      }
    };

    if (editing) {
      document.addEventListener("click", handleClickDocument);
    } else {
      document.removeEventListener("click", handleClickDocument);
    }

    return () => {
      document.removeEventListener("click", handleClickDocument);
    };
  }, [editing]);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-time={slot.startDate}
      className={classNames(b({ editing }))}
      style={{ height: `${height}px`, top: `${top}px` }}
      ref={refSlot}
    >
      {showButton && (
        <Resizable
          className={b("resizableBox")}
          enable={{ top: editing, bottom: editing }}
          minHeight={40}
          style={styleResizeBox}
          size={{ height }}
          grid={[0, pixelsInMinute * 5]}
          onResize={onResize}
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
        >
          <Button className={b("button", { editing })} onClick={startEdit}>
            {!editing && (
              <>
                <i className="far fa-plus-circle"></i> &ensp;New
              </>
            )}
            {editing && (
              <>
                <span className={b("button__output")}>
                  <span>
                    {moment.utc(currentSlot.startDate).local().format("HH:mm")}
                  </span>
                  -
                  <span>
                    {moment.utc(currentSlot.endDate).local().format("HH:mm")}
                  </span>
                </span>
                <span onClick={openForm}>Create</span>
                <span>
                  <i className={"far fa-times"} onClick={stopEdit} />
                </span>
              </>
            )}
          </Button>
        </Resizable>
      )}
      {showForm && (
        <Dialog aria-label={"EditTemplateForm"} onDismiss={closeForm}>
          <div>
            <ScheduleEditingForm
              isEdit={true}
              onSend={props.onCreateFreeSlot}
              dismissAction={closeForm}
              initialValues={currentSlot}
            />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default FreeSlot;
