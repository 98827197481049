import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {ScheduleContext} from "$/contexts/schedule";

import {Slider} from "@reach/slider";
import "@reach/slider/styles.css";
import './index.scss'

import ZoomPlus from '$/components/common/icons/zoom_plus.svg';
import ZoomMinus from '$/components/common/icons/zoom_minus.svg';
import bem from "bem-ts";

interface ISliderZoomProps {
  onChange?(newValue: number, props?: { min?: number, max?: number, handlePosition?: string }): void
}

enum MapValuesEnum {
  10 = 30,
  20 = 15,
  30 = 10,
  40 = 5
}
const b = new bem('SliderZoom', {strict: false})
const SliderZoom: React.FunctionComponent<ISliderZoomProps> = (props) => {
  const [scheduleState, dispatchSchedule] = useContext(ScheduleContext);
  const [value, setValue] = useState(MapValuesEnum[scheduleState.zoom || 15])

  useEffect(() => {
    dispatchSchedule({type: 'SET_ZOOM', payload: MapValuesEnum[value]})
  }, [value])

  return (
    <div className={b('container')}>
      <img onClick={() => {
        setValue(Math.max(10, value - 10))
      }} src={ZoomMinus}/>
      <Slider
        onChange={setValue}
        value={value}
        min={10}
        max={40}
        step={10}
      />
      <img onClick={() => {
        setValue(Math.min(40, value + 10))
      }} src={ZoomPlus}/>
    </div>
  )
}

export default SliderZoom;
