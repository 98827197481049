import React from "react";
import SelectorDate from "$/components/menuCalendarPopover";

import "@reach/slider/styles.css";
import "./index.scss";
import SliderZoom from "$/components/sliderZoom";
import bem from "bem-ts";

const b = new bem("ScheduleEditing-Menu", { strict: false });

const Menu: React.FunctionComponent<any> = (props) => {
  return (
    <div className={b("container")}>
      <div className={b("container__item")}>
        <SliderZoom />
      </div>
      <div className={b("container__item")}>
        <SelectorDate hasChoosing={false} />
      </div>
    </div>
  );
};

export default Menu;
