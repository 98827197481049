import AppointmentForm from '$/components/appointmentForm';
import TitlePage from '$/components/common/titlePage';
import { AppointmentTypeEnum, IDoctorInfo, IPatient, TimeSlotsStatusEnum, } from '$/models';
import DoctorNotes from '$/pages/AppointmentPage/doctorNotes';
import Member from '$/pages/AppointmentPage/member';
import { Appointment } from '$/stores/appointment';
import { humanFileSize } from '$/utils';
import { Dialog } from '@reach/dialog';
import { Menu, MenuButton, MenuPopover } from '@reach/menu-button';
import { Link, navigate } from '@reach/router';
import axios from 'axios';
import bem from 'bem-ts';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './index.scss';

const b = bem('ContentAppointment', { strict: false });

interface IContentAppointmentProps {
  appointmentStore: Appointment
}

const ContentAppointment: React.FC<IContentAppointmentProps> = (props: IContentAppointmentProps) => {
  const { appointmentStore } = props;
  const [ edit, setEdit ] = useState(false);
  const [ isRestoring, setIsRestoring ] = useState(false);

  const startEdit = () => setEdit(true);

  const stopEdit = () => {
    setEdit(false);

    if (isRestoring) {
      appointmentStore.cancelAppointmentReopen();
      setIsRestoring(false);
    }
  };

  const onSave = (appointment: Appointment) => {
    appointmentStore.setAppointment(appointment);
    setEdit(false);

    if (isRestoring) {
      setIsRestoring(false);
      void navigate(`/appointment/${appointment.id}`);
    }
  };

  const [ files, setFiles ] = useState([]);
  const [ uploadError, setUploadError ] = useState('');
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);

    acceptedFiles.map((file, i) => {
      const formData = new FormData();
      formData.append('file', file);
      appointmentStore.globalStore.http
        .post(
          `/doctor/appointment/${appointmentStore.id}/document`,

          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((res) => {
          appointmentStore.setAppointment(res.data);
          // res.data // appointment
          // setCurrentAppointment(res.data);
        })
        .catch((error) => {
          const resultError = error.response ? error.response.data : error;
          setUploadError(resultError);
        });
    });

    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop /*, noDrag: true, multiple: false*/,
  });

  const deleteAttachment = (file) => {
    axios
      .get(
        `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/appointment/${appointmentStore.id}/document/delete/?file=${file.fileName}`
      )
      .then((res) => {
        appointmentStore.setAppointment(res.data);
        // res.data // appointment
        // setCurrentAppointment(res.data);
      })
      .catch((error) => {
        const resultError = error.response ? error.response.data : error;
        setUploadError(resultError);
      });
  };

  const startTime = moment
    .utc(appointmentStore.startDate)
    .local()
    .format('HH:mm');
  const endTime = moment.utc(appointmentStore.endDate).local().format('HH:mm');
  const date = moment
    .utc(appointmentStore.endDate)
    .local()
    .format('ddd DD MMM YYYY');

  const Description = useMemo(() => {
    if (appointmentStore) {
      let descriptionType;
      if (appointmentStore.status === TimeSlotsStatusEnum.PATIENT_APPOINTMENT) {
        switch (appointmentStore.appointmentType) {
          case AppointmentTypeEnum[0]:
            descriptionType = (
              <span className={b('description__type', [ 'physical' ])}>
                <i className="far fa-shoe-prints"/> Physical appointment
              </span>
            );
            break;
          case AppointmentTypeEnum[1]:
            descriptionType = (
              <span className={b('description__type', [ 'video' ])}>
                <i className="far fa-video"/> Video appointment
              </span>
            );
            break;
          case AppointmentTypeEnum[2]:
            descriptionType = (
              <span className={b('description__type', [ 'call' ])}>
                <i className="far fa-phone-alt"/> Call appointment
              </span>
            );
            break;
        }

        const participants = appointmentStore.participants
          .map((participant: IDoctorInfo) => {
            return `${participant.profession?.occupation?.toLowerCase()} ${
              participant.personalInfo.firstName
            } ${participant.personalInfo.lastName}`;
          })
          .join(', ');

        return (
          <div className={b('description')}>
            {descriptionType}
            {appointmentStore.place?.roomNumber && (
              <span className={'description__room'}>
                &nbsp;in room {appointmentStore.place.roomNumber}&nbsp;
              </span>
            )}
            {!!participants && `, ${participants}`}.{' '}
            {appointmentStore.isFirstVisit ? 'New visit.' : ''}
          </div>
        );
      } else if (
        appointmentStore.status === TimeSlotsStatusEnum.ADMIN_APPOINTMENT
      ) {
        return (
          <div className={b('description')}>
            <i className="far fa-hospital-alt"/>
            &nbsp;
            <span className={b('description__type', [ 'administrative' ])}>
              Administrative appointment&nbsp;
            </span>
            {appointmentStore.place?.roomNumber && (
              <span className={'description__room'}>
                in room {appointmentStore.place.roomNumber}.
              </span>
            )}
          </div>
        );
      }
    }
  }, [
    appointmentStore,
    appointmentStore.appointmentType,
    appointmentStore.status,
    appointmentStore.isFirstVisit,
    appointmentStore.place,
    appointmentStore.participants,
  ]);

  const Information = useMemo(() => {
    if (appointmentStore) {
      let symptoms: any[] = [];

      (appointmentStore.events.reasons || []).forEach((symptom) => {
        symptom && !!symptom.description && symptoms.push(symptom.description);
      });

      symptoms = symptoms.join(', ');
      const preparations = (appointmentStore.preparations || []).join(', ');

      return (
        <div className={b('information')}>
          {!!symptoms.length &&
          appointmentStore.status === 'PATIENT_APPOINTMENT' && (
            <div className={b('information__field', [ 'symptoms' ])}>
              <i
                className={`${b(
                  'information__field__icon'
                )} far fa-align-left`}
              />
              {symptoms}
            </div>
          )}
          {!!preparations.length && (
            <div className={b('information__field', [ 'symptoms' ])}>
              <i className={`${b('information__field__icon')} far fa-vial`}/>
              {preparations}
            </div>
          )}
          {appointmentStore.description && (
            <div className={b('information__field', [ 'description' ])}>
              <i
                className={`${b('information__field__icon')} far fa-comment`}
              />
              {appointmentStore.description}
            </div>
          )}
        </div>
      );
    }
  }, [
    appointmentStore,
    appointmentStore,
    appointmentStore.events,
    appointmentStore.preparations,
    appointmentStore.description,
  ]);

  const Members = useMemo(() => {
    if (appointmentStore) {
      let members =
        appointmentStore.status === TimeSlotsStatusEnum.PATIENT_APPOINTMENT
          ? appointmentStore.patients
          : appointmentStore.participants;
      members = (members || []).map((person: IPatient | IDoctorInfo) => {
        return {
          person,
          state:
            appointmentStore.status === TimeSlotsStatusEnum.PATIENT_APPOINTMENT
              ? appointmentStore.appointmentState
              : null,
        };
      });

      let isChild = !!appointmentStore.patientChildInfo;

      return members.map((member, index) => {
        return (
          <Member
            key={`member-${index}`}
            person={member.person}
            state={member.state}
            appointment={appointmentStore}
            openChat={props.changeView}
            isChild={isChild}
          />
        );
      });
    }
  }, [
    appointmentStore,
    appointmentStore.status,
    appointmentStore.appointmentState,
    appointmentStore.patients,
    appointmentStore.participants,
    appointmentStore.patientChildInfo,
  ]);

  const renderAttachments = (appointment: Appointment) => {
    return (
      <>
        <div className={b('appointment__attachments__container')}>
          {appointment.attachments && (
            <>
              {appointment.attachments.map((file) => (
                <span className={b('appointment__attachment')}>
                  <span
                    className={b('appointment__attachment__fileName')}
                    onClick={() => appointmentStore.downloadAttachment(file)}
                  >
                    {file.fileName}
                  </span>
                  <span className={b('appointment__attachment__fileSize')}>
                    {humanFileSize(file.size)}
                  </span>
                  <a
                    className={b('appointment__attachment__delete')}
                    onClick={() => {
                      deleteAttachment(file);
                      return false;
                    }}
                  >
                    <i className={'far fa-times'}/>
                  </a>
                </span>
              ))}
            </>
          )}
          <div
            {...getRootProps()}
            className={b('appointment__attachment__upload__button')}
          >
            <input
              {...getInputProps()}
              id={'attachment__upload'}
              className={b('appointment__attachment', [ 'fileinput' ])}
            />
            <label
              className={b('appointment__attachment__label')}
              // htmlFor="attachment__upload"
            >
              + <i className={'far fa-paperclip'}/>
            </label>
            {/*{*/}
            {/*  isDragActive ?*/}
            {/*      <p className={b('attachment__filesDropZone')}>Drop the files here ...</p> :*/}
            {/*      <p className={b('attachment__filesHelpMessage')}>Drag 'n' drop some files here, or click to select files</p>*/}
            {/*}*/}
          </div>
        </div>
      </>
    );
  };

  const restore = () => {
    appointmentStore.reopenAppointment(appointmentStore.startDate).then(() => {
      setIsRestoring(true);
    });
  };

  return (
    <div>
      <div className={b('header')}>
        <Link
          to={`/schedule/${moment(appointmentStore.startDate).format(
            'YYYY-MM-DD'
          )}`}
          className={b('header__backButton')}
        >
          <i className="fas fa-chevron-left"/>
        </Link>

        <TitlePage className={b('header__title')}>
          <>
            <span>
              {appointmentStore.appointmentState === 'CANCELED' && (
                <span className={b('appointment__status', [ 'canceled' ])}>
                  Canceled:
                </span>
              )}
              {startTime}-{endTime},&#8194;{date}
            </span>
            {!!appointmentStore.appointmentTopic && (
              <span>{appointmentStore.appointmentTopic}</span>
            )}
          </>
        </TitlePage>

        <div className={b('header__menu')}>
          {moment.utc().isBefore(moment.utc(appointmentStore.startDate)) && (
            <>
              {appointmentStore.appointmentState !== 'CANCELED' ? (
                <i
                  className={`${b('header__menu__button', [
                    'edit',
                  ])} far fa-edit`}
                  onClick={startEdit}
                />
              ) : (
                <span className={b('header__menu__restore')} onClick={restore}>
                  {/*{isLoadingRestore ? (*/}
                  {/*  <LoaderPlain />*/}
                  {/*) : (*/}
                  <i className="far fa-trash-restore-alt"/>
                  {/*)}*/}
                </span>
              )}
            </>
          )}

          {appointmentStore.status === 'PATIENT_APPOINTMENT' && (
            <Menu>
              <MenuButton
                disable={!appointmentStore?.stateHistory.length}
                className={b('header__menu__button', [ 'history' ])}
              >
                <i className="far fa-info-square"></i>
              </MenuButton>
              <MenuPopover
                position={() => {
                  return {
                    position: 'absolute',
                    right: '0',
                    top: '173px',
                  };
                }}
              >
                {!!appointmentStore?.stateHistory.length && (
                  <div className={b('header__menu__history')}>
                    {appointmentStore?.stateHistory.map((state, index) => {
                      const patient = appointmentStore.patients[0];
                      const patientName = `${patient?.personalInfo?.firstName} ${patient?.personalInfo?.lastName}`;
                      const isToday =
                        moment().format('YYYY-MM-DD') ===
                        moment(state.changedTime).format('YYYY-MM-DD');
                      const date = moment(state.changedTime).format(
                        isToday ? 'HH:mm' : 'DD.MM.YYYY, HH:mm'
                      );
                      let result = '';
                      if (state.state === 'BOOKED') {
                        result = `${date} Booked by ${state.author}`;
                      } else if (state.state === 'PAYED') {
                        result = `${date} ${patientName} Payed`;
                      } else if (state.state === 'CHECKED_IN') {
                        result = `${date} ${patientName} Checked in`;
                      } else if (state.state === 'DIAGNOSED') {
                        result = `${date} ${patientName} was Diagnosed by ${state.author}`;
                      } else if (state.state === 'COMPLETED') {
                        result = `${date} Completed by ${state.author}`;
                      } else if (state.state === 'CANCELED') {
                        result = `${date} Canceled by ${state.author}`;
                      }
                      return (
                        <span key={`appointmentPage-history-${index}`}>
                          {result}
                        </span>
                      );
                    })}
                  </div>
                )}
              </MenuPopover>
            </Menu>
          )}
        </div>
      </div>

      <div className={b('content')}>
        {Description}
        {Members}
        {Information}

        {appointmentStore.status === 'PATIENT_APPOINTMENT' && (
          <div className={b('notes')}>
            {renderAttachments(appointmentStore)}

            <DoctorNotes
              readonly={[ 'DIAGNOSED', 'COMPLETED' ].includes(
                appointmentStore?.appointmentState
              )}
              appointmentStore={appointmentStore}
            />
          </div>
        )}
      </div>

      <Dialog isOpen={edit || isRestoring} onDismiss={stopEdit} aria-label="Appointment Form">
        <AppointmentForm
          isEditForm={true}
          autoSetDate={true}
          appointmentStore={appointmentStore}
          dismissAction={stopEdit}
          onSave={onSave}
          type={
            appointmentStore.status === TimeSlotsStatusEnum.PATIENT_APPOINTMENT
              ? 'patient'
              : 'admin'
          }
          disableDelete={isRestoring}
        />
      </Dialog>
    </div>
  );
};

export default observer(ContentAppointment);
