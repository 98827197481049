// mostly code from reactjs.org/docs/error-boundaries.html
import React, { Component } from "react";
import { Link, Redirect } from "@reach/router";
import bem from "bem-ts";
import "./ErrorBoundary.scss";

const b = bem("ErrorBoundary", { strict: false });

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "",
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }
  componentDidUpdate() {
    if (this.state.hasError) {
      setTimeout(() => this.setState({ redirect: true }), 8000);
    }
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    if (this.state.hasError) {
      return (
        <div className={b()}>
          <h1 className={b("message")}>
            There was an error with this listing. <Link to="/">Click here</Link>{" "}
            to back to the home page or wait five seconds
          </h1>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
