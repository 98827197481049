import React, {useEffect, useState} from 'react';
import ParticipantSelector from "$/components/participantSelector";
import Button from "$/components/common/button";
import {IAppointment, IDoctorInfo} from "$/models";
import useFetch from "$/hooks/useFetch";
import moment from "moment";

import './index.scss'
import bem from "bem-ts";

interface IFormAddProps {
  onAdd: (param: {
    value: {
      title: string, appointments: IAppointment[]
    },
    keyColumn: string
  }) => void;
  onClose: () => void;
  date: Date;
}

const URL = '/doctor/appointments/'
const b = new bem('addParticipant', {strict: false})

const FormAdd: React.FunctionComponent<IFormAddProps> = (props) => {
  const [participants, setParticipants] = useState<IDoctorInfo[]>([]);

  const add = () => {
    props.onAdd(participants)
  }

  const onChange = (participants: IDoctorInfo[]) => {
    setParticipants(participants)
  }


  return (
    <React.Fragment>
      <div className={b('container')}>
        <span className={b('close')} onClick={props.onClose}>
                <i className={'far fa-times'}/>
        </span>
        <h2 className={b('title')}>Colleagues</h2>
        <ParticipantSelector onChange={onChange} dontCheckAvailable={true}/>
      </div>
      <div className={b('footer')}>
        <Button className={b('button', ['cancel'])} view='cancel' onClick={props.onClose}>Cancel</Button>
        <Button className={b('button', ['create'])} onClick={add}>
          Add
        </Button>
      </div>
    </React.Fragment>
  )
}

export default FormAdd;
