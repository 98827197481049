import * as React from "react";
import SelectorDate from "$/components/menuCalendarPopover";

import "@reach/slider/styles.css";
import "./index.scss";
import SliderZoom from "$/components/sliderZoom";
import bem from "bem-ts";
import { useContext } from "react";
import { ScheduleContext } from "$/contexts/schedule";
import { Link } from "@reach/router";

const b = new bem("Schedule-Menu", { strict: false });

const Menu: React.FunctionComponent<any> = (props) => {
  const [scheduleContext, dispatchSchedule] = useContext(ScheduleContext);

  const openDialog = () => {
    props.setters.setShowAppointmentForm(true);
  };

  const showCancelled = () => {
    dispatchSchedule({
      type: "SET_SHOW_CANCELLED",
      payload: !scheduleContext.showCancelled,
    });
  };

  return (
    <div className={b("container")}>
      <div className={b("container__item")} onClick={showCancelled}>
        {!scheduleContext.showCancelled ? (
          <i className="icon far fa-eye" />
        ) : (
          <i className="icon far fa-eye-slash" />
        )}
        <span className={b("container__item__text")}>
          {scheduleContext.showCancelled ? "Hide cancelled" : "Show cancelled"}
        </span>
      </div>
      <Link to={"/schedule-editing"} className={b("container__item")}>
        <i className="icon far fa-edit"></i>
        <span className={b("container__item__text")}>Edit</span>
      </Link>
      <div className={b("container__item")} onClick={openDialog}>
        <i className="icon far fa-plus-circle"></i>
        <span className={b("container__item__text")}>New Appointment</span>
      </div>
      <div className={b("container__item")}>
        <SliderZoom />
      </div>
      <div className={b("container__item")}>
        <SelectorDate />
      </div>
    </div>
  );
};

export default Menu;
