import FormAdmin from '$/components/appointmentForm/formAdmin';
import FormPatient from '$/components/appointmentForm/formPatient';
import Button from '$/components/common/button';
import LoaderPlain from '$/components/loaderPlain';
import { CurrentUserContext } from '$/contexts/currentUser';
import { GlobalContext } from '$/contexts/global';
import { AppointmentTypeEnum, IAppointment, TimeSlotsStatusEnum, } from '$/models';
import { Appointment } from '$/stores/appointment';
import GlobalStore from '$/stores/global';
import { navigate } from '@reach/router';
import bem from 'bem-ts';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import './index.scss';

const apiUrl = "/doctor/appointment/";

export const initForm = () => ({
  id: null,
  patients: [],
  participants: [],
  events: {
    reasons: [],
    symptoms: [],
    observations: [],
    diagnosis: [],
    medicines: [],
  },
  preparations: [],
  appointmentType: AppointmentTypeEnum[0],
  appointmentState: "BOOKED",
  isFirstVisit: true,
  startDate: moment.utc().format("YYYY-MM-DDTHH:00:00"),
  endDate: moment
    .utc()
    .minutes(0)
    .add(15, "minutes")
    .format("YYYY-MM-DDTHH:mm:00"),
  status: TimeSlotsStatusEnum.PATIENT_APPOINTMENT,
  place: null,
  appointmentTopic: "",
  description: "",
  patientChildInfo: null,
});

const b = new bem("appointmentForm", { strict: false });

interface IAppointmentFormProps {
  dismissAction: () => void;
  onSave?: (appointment: IAppointment) => void;
  isEditForm?: boolean;
  appointmentStore?: Appointment;
  autoSetDate: boolean;
  disableDelete?: boolean;
}

const AppointmentForm: React.FC<IAppointmentFormProps> = ({
  dismissAction,
  autoSetDate = true,
  disableDelete,
  ...props
}) => {
  const globalStore = useContext<GlobalStore>(GlobalContext);
  const [userState] = useContext(CurrentUserContext);
  const [type, setType] = useState<"patient" | "admin">(
    props.type || "patient"
  );

  const [appointmentStore, setAppointmentStore] = useState<Appointment>(
    new Appointment(globalStore, {
      ...props.appointmentStore,
      doctor: userState.currentUser,
    })
  );

  const resetAppointmentStore = (appointment: IAppointment) =>
    setAppointmentStore(
      new Appointment(globalStore, {
        ...props.appointmentStore,
        ...appointment,
        doctor: userState.currentUser,
      })
    );

  const onSubmit = () => {
    appointmentStore
      .saveAppointment()
      .then((res) => {
        props.onSave && props.onSave(res.data as IAppointment);
      })
      .catch((e) => {
        appointmentStore.setOtherError(typeof e === 'string' ? e : e?.response?.data?.description);
      });
  };

  const onCancel = async () => {
    appointmentStore.changeStatus("CANCELED").then((isSuccess: boolean) => {
      if (!isSuccess) return;
      navigate('/schedule');
    });
    dismissAction();
  };

  return (
    <form className={b()} onSubmit={() => {}}>
      <fieldset className={b("fieldset")}>
        <h2>{props.isEditForm ? "Edit appointment" : "New appointment"}</h2>

        {!props.type && (
          <div className={b("controllerType")}>
            <Button
              view={"transparent"}
              active={type === "patient"}
              onClick={() => {
                setType("patient");
                resetAppointmentStore();
              }}
            >
              With a patient
            </Button>
            <Button
              view={"transparent"}
              active={type !== "patient"}
              onClick={() => {
                setType("admin");
                resetAppointmentStore({
                  status: TimeSlotsStatusEnum.ADMIN_APPOINTMENT,
                  // appointmentType: null, - keep current appointment type as is
                  appointmentState: null,
                });
              }}
            >
              Administrative
            </Button>
          </div>
        )}

        {type === "patient" ? (
          <FormPatient
            appointmentStore={appointmentStore}
            isEdit={!autoSetDate}
          />
        ) : (
          <FormAdmin
            appointmentStore={appointmentStore}
            disableAutoSetData={!autoSetDate}
          />
        )}
      </fieldset>

      <div className={b("footer")}>
        <div className={b("footer__errors")}>
          {!!Object.values(appointmentStore.errors)?.length &&
            Object.values(appointmentStore.errors).map(
              (error: string, index: number) => {
                return (
                  <div
                    className={b("footer__formError")}
                    key={`formError-${index}`}
                  >
                    {error}
                  </div>
                );
              }
            )}
          {appointmentStore.otherError && (
            <div className={b("footer__formError")}>
              {appointmentStore.otherError?.replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/gm,
                (match: string) => moment.utc(match).local().format('DD-MM-YY hh:mm'))}
            </div>
          )}
        </div>

        {!disableDelete && props.isEditForm &&
        appointmentStore?.appointmentState !== 'DIAGNOSED' && (
          <Button
            className={b('button', [ 'cancel' ])}
            view="danger"
            onClick={onCancel}
          >
            {appointmentStore.isLoadingChangeStatus ? (
              <LoaderPlain/>
            ) : (
              <>
                <i className={'far fa-trash-alt'}/> &nbsp; Delete
              </>
            )}
          </Button>
        )}

        <Button
          className={b('button', [ 'cancel' ])}
          view="cancel"
          onClick={dismissAction}
        >
          Cancel
        </Button>

        <Button
          className={b("button", ["create"])}
          onClick={onSubmit}
          disabled={appointmentStore?.isLoadingSave || Object.values(appointmentStore.errors).length}
          // view={}
        >
          {appointmentStore?.isLoadingSave ? (
            <LoaderPlain />
          ) : props.isEditForm ? (
            "Save"
          ) : (
            "Create"
          )}
        </Button>
      </div>

      <span className={b("close")} onClick={dismissAction}>
        <i className={"far fa-times"} />
      </span>
    </form>
  );
};

export default observer(AppointmentForm);
