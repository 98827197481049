import React, { memo, useCallback } from 'react';
import SockJsClient from 'react-stomp';

interface ISocketClientProps {
  subscribes: Record<string, Function> ;
  currentUser: any;
}

export const SocketClient: React.FC<ISocketClientProps> = memo((props: ISocketClientProps) => {
  const { subscribes, currentUser } = props;

  if (!Object.entries(subscribes ?? {}).length || !currentUser) return null;

  const handler = useCallback((msg) => {
    Object.values(subscribes).forEach((clb: any) => {
      clb(msg);
    });
  }, [ subscribes ]);

  const url = `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/chatbot`;
  const sithsid = currentUser.sithsid;
  const isReadyForChat = currentUser.readyForChat ?? false;

  return <SockJsClient
    onMessage={handler}
    url={url}
    debug
    topics={[
      `/queue/notifications/${sithsid}`,
      `/queue/chat/message/${sithsid}`,
      `/queue/appointment/${sithsid}`,
      ...(isReadyForChat ? ['/topic/chat/nurse'] : []),
    ]}
  />;
});