import React, { useContext, useMemo } from "react";
import bem from "bem-ts";
import "./index.scss";
import { AppointmentTypeEnum, IAppointment } from "$/models";
import { ScheduleContext } from "$/contexts/schedule";
import moment from "moment";
import TemplateEditingSlot from "$/components/templateEditingSlot";
import FreeSlot from "$/components/templateEditingColumn/freeSlot";

const b = bem("TemplateEditing-Column", { strict: false });

interface IColumnProps {
  title: string;
  slots: any[];
  height: number;
  day: number;
  templateState: any[];
  discardState: any[];
  onCreateFreeSlot: () => void;
  onEditSlot: (newSlot: any, oldSlot: any) => void;
}

const Column: React.FC<IColumnProps> = (props) => {
  const [scheduleStore] = useContext(ScheduleContext);
  let minutesInDay = 0;

  const renderSlots = useMemo(() => {
    return (props.slots || []).map((slot, indexSlot: number) => {
      const startHour = slot.startWorkTime.split(":")[0];
      const startMinute = slot.startWorkTime.split(":")[1];
      const startMoment = moment
        .utc()
        .isoWeekday(props.day)
        .hour(startHour)
        .minutes(startMinute)
        .seconds(0);

      const endHour = slot.endWorkTime.split(":")[0];
      const endMinute = slot.endWorkTime.split(":")[1];
      const endMoment = moment
        .utc()
        .isoWeekday(props.day)
        .hour(endHour)
        .minutes(endMinute)
        .seconds(0);

      minutesInDay += moment(endMoment).diff(moment(startMoment), "minutes");
      let icon = <i className="far fa-shoe-prints" />;
      const type = slot.appointmentType;
      switch (type) {
        case AppointmentTypeEnum[0]:
          icon = <i className="far fa-shoe-prints" />;
          break;
        case AppointmentTypeEnum[2]:
          icon = <i className="far fa-phone-alt" />;
          break;
        case AppointmentTypeEnum[1]:
          icon = <i className="far fa-video" />;
          break;
      }

      const slotData = {
        id: slot.id,
        appointmentType: type,
        startWorkTime: startMoment.format("YYYY-MM-DDTHH:mm:00"),
        endWorkTime: endMoment.format("YYYY-MM-DDTHH:mm:00"),
        status: "WORK_TIME",
        nextTimeForLock: undefined,
        prevTimeForLock: undefined,
      };

      if (props.slots[indexSlot + 1]) {
        const nextSlot = props.slots[indexSlot + 1];
        slotData.nextTimeForLock = moment(endMoment)
          .hour(nextSlot.startWorkTime.split(":")[0])
          .minute(nextSlot.startWorkTime.split(":")[1])
          .format("YYYY-MM-DDTHH:mm:00");
      } else {
        slotData.nextTimeForLock = moment(endMoment.local())
          .hour(scheduleStore.maxHour)
          .utc()
          .minute(0)
          .format("YYYY-MM-DDTHH:mm:00");
      }

      if (props.slots[indexSlot - 1]) {
        const prevSlot = props.slots[indexSlot - 1];
        slotData.prevTimeForLock = moment(startMoment)
          .hour(prevSlot.endWorkTime.split(":")[0])
          .minute(prevSlot.endWorkTime.split(":")[1])
          .format("YYYY-MM-DDTHH:mm:00");
      } else {
        slotData.prevTimeForLock = moment(startMoment.local())
          .hour(scheduleStore.minHour)
          .utc()
          .minute(0)
          .format("YYYY-MM-DDTHH:mm:00");
      }

      return (
        <TemplateEditingSlot
          key={`column-${props.day}-slot-${slotData.startWorkTime}-${slotData.endWorkTime}`}
          slot={slotData}
          allSlots={props.slots}
          save={props.onEditSlot}
          delete={props.onDeleteSlot}
        />
      );
    });
  }, [props, scheduleStore.zoom]);

  const renderFreeSlots = useMemo((): any[] => {
    const result: any[] = [];

    if (props.slots?.length) {
      let startTime = moment
        .utc()
        .local()
        .hour(scheduleStore.minHour)
        .minutes(0)
        .seconds(0);

      const endTime = moment
        .utc()
        .local()
        .hour(scheduleStore.maxHour)
        .minutes(0)
        .seconds(0)
        .format("YYYY-MM-DDTHH:mm:00");

      const timeSteps = getStepsByDuring(scheduleStore.zoom);

      [
        ...(props.slots || []),
        {
          startWorkTime: endTime,
        },
      ].forEach((slot: any) => {
        const hourSlot = slot.startWorkTime.split(":")[0];
        const minutesSlot = slot.startWorkTime.split(":")[1];
        const isInSchedule = startTime.isBefore(endTime);

        if (isInSchedule) {
          const condition = (i) => {
            return (
              i.format("YYYY-MM-DDTHH:mm:SS") <
              moment
                .utc()
                .hour(hourSlot)
                .minutes(minutesSlot)
                .second(0)
                .local()
                .format("YYYY-MM-DDTHH:mm:SS")
            );
          };

          const next = (i) => {
            if (!timeSteps.includes(i.minutes())) {
              const findMinute =
                timeSteps.find((minute: number, index, arr) => {
                  return (
                    !!arr[index - 1] &&
                    i.minutes() < minute &&
                    i.minutes() > arr[index - 1]
                  );
                }) || 0;

              i.minutes(findMinute);
            } else {
              i.add(scheduleStore.zoom, "minutes");
            }
          };

          !timeSteps.includes(startTime.minutes()) && next(startTime);

          const diff = moment
            .utc()
            .hour(hourSlot)
            .minutes(minutesSlot)
            .second(0)
            .local()
            .diff(moment(startTime), "minutes");

          if (diff >= scheduleStore.zoom) {
            for (let i = startTime; condition(i); next(i)) {
              const dataForSlot = {
                minTime: moment(startTime).utc().format("YYYY-MM-DDTHH:mm:00"),
                maxTime: moment
                  .utc()
                  .hour(hourSlot)
                  .minutes(minutesSlot)
                  .second(0)
                  .local()
                  .format("YYYY-MM-DDTHH:mm:SS"),
                startWorkTime: moment(i).utc().format("YYYY-MM-DDTHH:mm:00"),
                endWorkTime: moment(i)
                  .utc()
                  .add(scheduleStore.zoom, "minutes")
                  .format("YYYY-MM-DDTHH:mm:00"),
              };
              result.push(dataForSlot);
            }
          }
        }
        startTime =
          !!slot.endWorkTime &&
          moment
            .utc()
            .hour(slot.endWorkTime.split(":")[0])
            .minute(slot.endWorkTime.split(":")[1])
            .second(0)
            .local();
      });
    }
    return result;
  }, [props, scheduleStore.zoom]);

  return (
    <div className={b()}>
      <span className={b("title")}>
        {props.title}
        &ensp;
        <span className={b("title__time")}>
          {minutesInDay === 0 ? "0" : (minutesInDay / 60).toFixed(2)}h
        </span>
      </span>

      <div style={{ height: `${props.height}px` }} className={b("content")}>
        {renderSlots}
        {renderFreeSlots.map((slot, index) => {
          return (
            <FreeSlot
              day={props.day}
              templateState={props.templateState}
              discardState={props.disardState}
              slot={slot}
              key={`slot-${index}-${moment(slot.startWorkTime).format(
                "YYYY-MM-DDTHH:mm:00"
              )}-${moment(slot.endWorkTime).format("YYYY-MM-DDTHH:mm:00")}`}
              onCreateFreeSlot={props.onCreateFreeSlot}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Column;

const getStepsByDuring = (during: number): number[] => {
  const result = [0];
  const hour = 60;
  const countSteps = hour / during;
  for (let i = 1; i < countSteps; i++) {
    result.push(i * during);
  }
  return result;
};
