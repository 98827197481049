import React, { createContext, useContext, useEffect } from "react";
import GlobalStore from "$/stores/global";
import { configure } from "mobx";
import { CurrentUserContext } from "$/contexts/currentUser";

export const GlobalContext = createContext<GlobalStore>(null);

const GlobalProvider: React.FC = ({ children }) => {
  return (
    <GlobalContext.Provider value={new GlobalStore()}>
      <Inner>{children}</Inner>
    </GlobalContext.Provider>
  );
};

const Inner: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userState] = useContext(CurrentUserContext);
  const globalStore = useContext(GlobalContext);

  useEffect(() => {
    globalStore.setCurrentUser(userState.currentUser);
  }, [userState]);
  return children;
};

export default GlobalProvider;
