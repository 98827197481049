import Notifications from '$/components/notifications';
import Reducers from '$/contexts/reducers';
import AppointmentPage from '$/pages/AppointmentPage';
import Chat from '$/pages/Chat';
import DoctorProfile from '$/pages/DoctorProfile';
import Login from '$/pages/Login';
import PatientProfile from '$/pages/PatientProfile';
import SchedulePage from '$/pages/Schedule';
import ScheduleEditing from '$/pages/ScheduleEditing';
import TemplateEditing from '$/pages/TemplateEditing';
import ChatController from '$/utils/chatController';
import CurrentUserChecker from '$/utils/currentUserChecker';
import NotificationController from '$/utils/notificationController';
import WebSocketController from '$/utils/Websocket/webSocketController';
import { Router } from '@reach/router';
import React, { Fragment } from 'react';
import './App.scss';

const NotFound = () => <Fragment><h1>404</h1><p className="error">Sorry, nothing here</p></Fragment>

function App() {
  return (
    <Reducers>
      <CurrentUserChecker>
        <WebSocketController>
          <ChatController>
            <NotificationController>
              <Notifications isGlobal={true}/>
              <Router>
                <Login path="/"/>
                <SchedulePage path="/schedule"/>
                <SchedulePage path="/schedule/:initDate"/>
                <ScheduleEditing path="/schedule-editing"/>
                <TemplateEditing path="/template-editing/:templateId"/>
                <AppointmentPage path={"/appointment/:appointmentId"}/>
                <PatientProfile path="/patient/:patientId"/>
                <DoctorProfile path="/profile"/>
                <Chat path="/chat"/>
                <Chat path="/chat/:chatId" />
                <NotFound default/>
              </Router>
            </NotificationController>
          </ChatController>
        </WebSocketController>
      </CurrentUserChecker>
    </Reducers>


  );
}

export default App;
