import React, { useContext, useRef, useState } from "react";
import { Link } from "@reach/router";
import {
  AppointmentStateEnum,
  AppointmentTypeEnum,
  IAppointment,
  TimeSlotsStatusEnum,
} from "$/models";
import StateButton from "$/components/appointmentStateButton";

import "./index.scss";
import moment from "moment";
import { act } from "react-dom/test-utils";
import bem from "bem-ts";
import { ScheduleContext } from "$/contexts/schedule";
import { useRect } from "@reach/rect";
import { Appointment as AppointmentStore } from "$/stores/appointment";
import GlobalStore from "../../../stores/global";
import { GlobalContext } from "../../../contexts/global";
import { useMemo } from "react";

const b = new bem("Schedule-Appointment", { strict: false });

interface IAppointmentProps {
  style?: React.CSSProperties;
  className?: string;
  data: IAppointment;
  isCompact?: boolean;
  active?: boolean;
  showCancelled?: boolean;
  autoTop?: boolean;
  sizeAfter?: number;
}

const Appointment: React.FunctionComponent<IAppointmentProps> = (props) => {
  const [active, setActive] = useState(false);
  const [scheduleState] = useContext(ScheduleContext);
  const wrapperRef = useRef(null);
  const result = getFields(props.data);
  const activeClass = props.active || active ? b("state", ["active"]) : "";
  const compactClass = props.isCompact ? b("isCompact", ["true"]) : "";
  const className = `${props.className} ${result.viewStyle} ${activeClass} ${compactClass}`;

  const [delayHandler, setDelayHandler] = useState(null);
  const globalStore = useContext<GlobalStore>(GlobalContext);

  const handleMouseEnter = () => {
    !props.active &&
      setDelayHandler(
        setTimeout(() => {
          setActive(true);
        }, 500)
      );
  };

  const handleMouseLeave = () => {
    !props.active && setActive(false);
    clearTimeout(delayHandler);
  };

  const minHeight = parseInt(props.style?.minHeight.slice(0, -2), 10);
  const isThin = minHeight < 20;

  const rectContainer = useRect(wrapperRef);
  const refRoom = useRef();
  const rectRoom = useRect(refRoom);

  const appointmentStore = useMemo(() => {
    return new AppointmentStore(globalStore, props.data);
  }, [props.data]);

  const style = {
    ...props.style,
    bottom:
      active && !!props.autoTop && props.sizeAfter < rectContainer.height
        ? "auto"
        : props.style?.bottom,
    top:
      active && !!props.autoTop && props.sizeAfter < rectContainer.height
        ? 0
        : "auto",
  };
  return (
    (props.showCancelled ||
      (!scheduleState.showCancelled && !result.isCanceled) ||
      (scheduleState.showCancelled && result.isCanceled)) && (
      <div
        className={`${b()} ${className} ${
          result.isCanceled && b("isCanceled--true")
        }`}
        style={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={wrapperRef}
      >
        {(() => {
          const hasState =
            !result.isCanceled &&
            props.data.status === TimeSlotsStatusEnum.PATIENT_APPOINTMENT &&
            (minHeight >= 41 || active);
          const buttonWidth = props.isCompact ? '40px' : '140px';

          return (
            <>
              <Link
                to={`/appointment/${props.data.id}`}
                style={{
                  width: `calc(100% - ${hasState ? buttonWidth : '0px'})`,
                  height: "100%",
                  display: "flex",
                }}
              >
                {!(props.active || active) ? (
                  <Preview
                    data={result}
                    showInfo={!props.isCompact}
                    isThin={isThin}
                    style={
                      props.isCompact && rectContainer?.width
                        ? { width: `calc(${rectContainer?.width}px - ${hasState ? buttonWidth : '0px'} )` }
                        : undefined
                    }
                  />
                ) : (
                  <Detail data={result} />
                )}
              </Link>
              {hasState && (
                <StateButton
                  className={b("button")}
                  isCompact={props.isCompact && !active}
                  appointmentStore={appointmentStore}
                  currentAppointment={props.data}
                />
              )}
            </>
          );
        })()}
      </div>
    )
  );
};

export default Appointment;

const Preview: React.FunctionComponent<any> = (props) => {
  const result = props.data;
  return (
    <div className={b("preview")} style={props.style}>
      {!props.isThin && (
        <>
          <span className={b("preview__title")}>
            {result.icon}&nbsp;{result.title}
          </span>
          {props.showInfo && (
            <span className={b("preview__title", ["info"])}>
              {result.titleInfo ? `, ${result.titleInfo}` : ""}
            </span>
          )}
        </>
      )}
    </div>
  );
};

const Detail: React.FunctionComponent<any> = (props) => {
  const result = props.data;
  return (
    <div className={b("detail")}>
      <span className={b("detail__title")}>
        {result.icon}&nbsp;<div>{result.title}</div>
      </span>
      <span className={b("detail__title--info")}>{result.titleInfo}</span>
      <span className={b("detail__time")}>{result.time}</span>
      {!!result.participants.length && (
        <div className={b("detail__participants")}>
          <i className={`icon far fa-user-md ${b("detail__icon}")}`} />
          <span>{result.participants.join(", ")}</span>
        </div>
      )}
      {!!result.symptoms.length && (
        <div className={b("detail__symptoms")}>
          <i className={"icon far fa-align-left"} />
          <span>{result.symptoms.join(", ")}</span>
        </div>
      )}
      {!!result.preparations.length && (
        <div className={b("detail__preparations")}>
          <i className={"icon far fa-vial"} />
          <span>{result.preparations.join(", ")}</span>
        </div>
      )}
      {result.place?.roomNumber && (
        <div className={b("detail__room")}>
          <i className="icon far fa-map-marker" />
          <span>{result.place.roomNumber}</span>
        </div>
      )}
    </div>
  );
};

interface IResultFields {
  title: string;
  titleInfo?: string;
  icon: JSX.Element;
  viewStyle: string;
  symptoms: string[];
  room?: number;
  time: string;
  participants: string[];
  preparations: string[];
  isCanceled: boolean;
}

const getFields = (appointment: IAppointment): IResultFields => {
  const result: IResultFields = {
    title: "",
    titleInfo: undefined,
    icon: <i className="far fa-hospital-alt" />,
    symptoms:
      !!appointment.events.reasons?.length &&
      appointment.events.reasons.map((symptom) => symptom?.description),
    viewStyle: b("type", ["administrative"]),
    time:
      moment.utc(appointment.startDate).local().format("HH:mm") +
      " - " +
      moment.utc(appointment.endDate).local().format("HH:mm"),
    place: appointment.place,
    participants: (appointment.participants || []).map(
      (participant) =>
        `${participant.personalInfo.firstName} ${participant.personalInfo.lastName}`
    ),
    preparations: appointment.preparations || [],
    isCanceled: false,
  };

  const isComplete = moment.utc(appointment.endDate).local().isBefore(moment());
  result.isCanceled = appointment.appointmentState === AppointmentStateEnum[4];

  const type = appointment.status === "ADMIN_APPOINTMENT" ? null :  appointment.appointmentType;
  switch (type) {
    case AppointmentTypeEnum[0]:
      result.viewStyle = b("type", ["physical"]);
      result.icon = <i className="far fa-shoe-prints" />;
      break;
    case AppointmentTypeEnum[2]:
      result.viewStyle = b("type", ["call"]);
      result.icon = <i className="far fa-phone-alt" />;
      break;
    case AppointmentTypeEnum[1]:
      result.viewStyle = b("type", ["video"]);
      result.icon = <i className="far fa-video" />;
      break;
  }
  if (isComplete) {
    result.viewStyle = b("type", ["complete"]);
  }

  // If has type it's patient appointment
  if (appointment.status === TimeSlotsStatusEnum.PATIENT_APPOINTMENT) {
    const patient = appointment.patients[0];
    result.title = "No Name";
    result.titleInfo = "";
    if (patient) {
      const personalInfo = appointment.patientChildInfo || patient.personalInfo;
      result.title = `${personalInfo.firstName} ${patient.personalInfo.lastName}`;
      result.titleInfo = patient.bankId;
    }
  } else if (appointment.status === TimeSlotsStatusEnum.ADMIN_APPOINTMENT) {
    const participants = appointment.participants;
    result.title = appointment.appointmentTopic || "UNTITLE";
    result.titleInfo =
      participants &&
      (participants || [])
        .map((participant) => {
          return `${participant.personalInfo.firstName} ${participant.personalInfo.lastName}`;
        })
        .join(", ");

    result.room = appointment.place && appointment.place.roomNumber;
  } else if (appointment.status === TimeSlotsStatusEnum.WORK_TIME) {
    result.title = "Free Slot";
  } else {
    result.title = appointment.appointmentTopic || "UNTITLE";
  }

  return result;
};
