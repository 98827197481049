import React, { createContext, useContext, useEffect, useReducer } from "react";
import moment from "moment";
import useFetch from "$/hooks/useFetch";
import { CurrentUserContext } from "./currentUser";
import { sortAppointmentsAsc } from "$/utils/sort-appointments";

window.moment = moment;

const initialState = {
  initialized: false,
  columns: [],
  currentTime: moment().startOf("isoWeek").startOf("day").toDate(),
  zoom: 15,
  view: "week",
  //It's localtime:
  minHour: 7,
  maxHour: 21,
  showCancelled: false,
  participants: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SHOW_CANCELLED":
      return { ...state, initialized: true, showCancelled: action.payload };
    case "SET_COLUMNS": {
      return { ...state, initialized: true, columns: action.payload };
    }
    case "REMOVE_COLUMN": {
      // action.payload = keyColumn: string
      const keyColumn = action.payload;
      const columns = [
        ...state.columns?.filter((column) => column.keyColumn !== keyColumn),
      ];

      return {
        ...state,
        initialized: true,
        columns,
        participants: state.participants.filter((p) => p !== keyColumn),
      };
    }
    case "ADD_APPOINTMENT":
      const key = action.payload.keyColumn;
      const columns = [...state.columns];
      const columnIndex = columns.findIndex((col) => col.keyColumn === key);
      let currentTime = state.currentTime;
      if (columnIndex > -1) {
        const hasThisAppointmentIndex = columns[
          columnIndex
        ]?.appointments.findIndex(
          (appointment) => appointment.id === action.payload.value.id
        );
        if (hasThisAppointmentIndex > -1) {
          columns[columnIndex].appointments = columns[
            columnIndex
          ].appointments.map((a, ai) => {
            return ai === hasThisAppointmentIndex ? action.payload.value : a;
          });
        } else {
          columns[columnIndex].appointments = sortAppointmentsAsc([
            ...(columns[columnIndex]?.appointments || []),
            action.payload.value,
          ]);
          currentTime = moment(state.currentTime).toDate();
        }


        // const appointments = columns[columnIndex].appointments;
        // const flatternedAppointments = appointments
        //   .map((targetAppointment) => {
        //     const intersectingAppointments = appointments
        //       .filter(
        //         (appointment) =>
        //           targetAppointment !== appointment && targetAppointment.status !== 'WORK_TIME' &&
        //           targetAppointment.startDate >= appointment.startDate &&
        //           targetAppointment.endDate <= appointment.endDate
        //       )
        //       .map((intersectingAppointment) => {
        //         return [
        //           {
        //             ...intersectingAppointment,
        //             id: Math.random().toString(36).substring(8),
        //             startDate: intersectingAppointment.startDate,
        //             endDate: targetAppointment.startDate,
        //           },
        //           {
        //             ...intersectingAppointment,
        //             id: Math.random().toString(36).substring(8),
        //             startDate: targetAppointment.endDate,
        //             endDate: intersectingAppointment.endDate,
        //           },
        //         ];
        //       });
        //     return [
        //       targetAppointment,
        //       ...intersectingAppointments.flatMap((a) => a),
        //     ];
        //   })
        //   .flatMap((a) => a)
        //   .filter((a) => a.startDate != a.endDate);
        // console.log("flatternedAppointments", flatternedAppointments);
        // columns[columnIndex].appointments = flatternedAppointments;

        // const appointments = columns[columnIndex].appointments;
        // columns[columnIndex].appointments.map(a)
      }
      return { ...state, initialized: true, columns, currentTime }; // update current time to refetch view
    case "SET_ZOOM":
      return { ...state, initialized: true, zoom: action.payload };
    case "SET_VIEW":
      return {
        ...state,
        initialized: true,
        view: action.payload,
        currentTime:
          action.payload === "week"
            ? moment().startOf("isoWeek").startOf("day").toDate()
            : new Date(),
      };
    case "SET_CURRENT_TIME":
      if (typeof action.payload === "number") {
        console.warn("Deprecated usage of number current time");
      }
      return { ...state, currentTime: action.payload };
    case "RESET_STORE_DATA":
      return { ...state, columns: [] };
    case "SET_PARTICIPANTS":
      return { ...state, participants: action.participants };
    case "LOAD":
      return { ...state, ...action.state };
    default:
      return state;
  }
};

export const ScheduleContext = createContext();

export const ScheduleProvider = ({ children }) => {
  const [value, dispatchValue] = useReducer(reducer, initialState);
  const [, setEnv] = useFetch("/doctor/env");

  const [{ currentUser }] = useContext(CurrentUserContext);

  useEffect(() => {
    if (currentUser && currentUser.env) {
      const persistedParticipants = (currentUser.env.participants || "")
        .split(",")
        .filter((a) => !!a);
      dispatchValue({
        type: "LOAD",
        state: {
          participants: persistedParticipants,
        },
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (value.participants != null && currentUser) {
      const persisted = (currentUser.env.participants || "")
        .split(",")
        .filter((a) => !!a);
      const current = value.participants;

      const difference = persisted
        .filter((x) => !current.includes(x))
        .concat(current.filter((x) => !persisted.includes(x)));
      if (difference.length !== 0) {
        setEnv({
          url: "/doctor/env",
          method: "POST",
          data: {
            participants: value.participants.join(","),
          },
        });
      }
    }
  }, [value.participants, currentUser]);

  return (
    <ScheduleContext.Provider value={[value, dispatchValue]}>
      {children}
    </ScheduleContext.Provider>
  );
};
