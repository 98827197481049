import React from "react";
import { observer } from "mobx-react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { AppointmentTypeEnum } from "$/models";

import "@reach/tabs/styles.css";
import "./index.scss";
import bem from "bem-ts";

const b = new bem("appointmentType", { strict: false });
const AppointmentTypeSwitcher = ({ appointmentStore, value, onChange }) => {
  return (
    <div>
      <Tabs
        index={AppointmentTypeEnum[appointmentStore?.appointmentType || value]}
        onChange={(index) => {
          if(appointmentStore){
            appointmentStore.setField(
              "appointmentType",
              AppointmentTypeEnum[index]
            );
          }
          if(onChange){
            onChange(AppointmentTypeEnum[index])
          }
        }}
      >
        <TabList>
          <Tab className={b(["physical"])} type="button">
            <i className="far fa-shoe-prints" /> Physical
          </Tab>
          <Tab className={b(["video"])} type="button">
            <i className="far fa-video" /> Video
          </Tab>
          <Tab className={b(["call"])} type="button">
            <i className="far fa-phone-alt" /> Phone
          </Tab>
        </TabList>
        <TabPanel>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default observer(AppointmentTypeSwitcher);
