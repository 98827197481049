import React, {useRef} from "react";
import {ComboboxList} from "@reach/combobox"
import useInfiniteScroll from 'rc-infinite-scroll-hook'
import './index.scss'
import LoaderPlain from "$/components/loaderPlain";

const ScrolledList = (props) => {
  const paginationContainerRef = useRef<null | HTMLDivElement>(null);

  let [isLoadingNextPage] = useInfiniteScroll(paginationContainerRef, props.getItems, 50);

  return (
    <ComboboxList ref={paginationContainerRef}>
      {props.children}
      { isLoadingNextPage && <LoaderPlain /> }
    </ComboboxList>
  )
}

export default ScrolledList
