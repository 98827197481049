import React, { useContext, useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "@reach/router";
import { Dialog, DialogContent } from "@reach/dialog";
import ErrorBoundary from "$/utils/ErrorBoundary/ErrorBoundary";
import { CurrentUserContext } from "$/contexts/currentUser";
import NavHeader from "$/components/navHeader";
import AppointmentForm from "$/components/appointmentForm";
import TitlePage from "$/components/common/titlePage";
import Menu from "./Menu";
import Schedule from "$/components/schedule";
import { ScheduleContext } from "$/contexts/schedule";

import "./index.scss";
import "@reach/dialog/styles.css";
import bem from "bem-ts";
import moment from "moment";
import { IAppointment } from "$/models";
import { GlobalContext } from "$/contexts/global";
import { Appointment } from "$/stores/appointment";

const b = new bem("pageSchedule", { strict: false });

const SchedulePage: React.FunctionComponent<RouteComponentProps> = (props) => {
  const [userState] = useContext(CurrentUserContext);
  const globalStore = useContext(GlobalContext);
  const [scheduleState, dispatchSchedule] = useContext(ScheduleContext);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);

  useEffect(() => {
    /**
     * Initialize view only once
     */
    if (!scheduleState.initialized) {
      dispatchSchedule({
        type: "SET_VIEW",
        payload: "week",
      });
    }
  }, []);

  useEffect(() => {
    if (props.initDate) {
      const payload =
        scheduleState.view === "day"
          ? moment(props.initDate).toDate()
          : moment(props.initDate).startOf('isoWeek').startOf('day').toDate();
      dispatchSchedule({
        type: "SET_CURRENT_TIME",
        payload,
      });
    }

    return () => {
      dispatchSchedule({
        type: "RESET_STORE_DATA",
      });
    };
  }, [props.initDate, scheduleState.view]);

  const closeDialog = () => {
    setShowAppointmentForm(false);
  };
  const settersForMenu = {
    setShowAppointmentForm,
  };

  const onCreateAppointment = (appointment: IAppointment) => {
    let isCurrentTime = false;
    if (scheduleState.view === "day") {
      isCurrentTime =
        moment(scheduleState.currentTime).format("l") ===
        moment(appointment.startDate).format("l");
    } else {
      isCurrentTime =
        scheduleState.currentTime === moment(appointment.startDate).isoWeek();
    }
    if (isCurrentTime) {
      const keyColumn =
        scheduleState.view === "day"
          ? appointment.doctor.sithsid
          : moment(appointment.startDate).format("ddd_D");
      dispatchSchedule({
        type: "ADD_APPOINTMENT",
        payload: {
          keyColumn,
          value: appointment,
        },
      });
    }
    closeDialog();
  };

  const errorLogin = !userState.isLoading && userState.isError;
  if (errorLogin) {
    return <Redirect to={"/"} />;
  } else {
    return (
      <ErrorBoundary>
        <div className={`page ${b()}`}>
          <NavHeader className={b("Navi")} />
          <div className={b("header")}>
            <TitlePage>Calendar</TitlePage>
            <Menu setters={settersForMenu} />
          </div>

          <div className={b("content")}>
            <Schedule />
          </div>

          <Dialog
            isOpen={showAppointmentForm}
            onDismiss={closeDialog}
            aria-label="Appointment Form"
          >
            <AppointmentForm
              appointmentStore={{
                participants: [userState.currentUser],
              }}
              dismissAction={closeDialog}
              onSave={onCreateAppointment}
            />
          </Dialog>
        </div>
      </ErrorBoundary>
    );
  }
};
export default SchedulePage;
