import React, { useEffect, useState } from "react";
import "./index.scss";
import Arrow from "$/components/common/icons/arrow.svg";
import moment from "moment";
import bem from "bem-ts";
import classNames from "classnames";
import useFetch from "$/hooks/useFetch";
import { IAppointmentLight } from "$/models";

interface ICalendarProps {
  onChange?: (value: Date | number) => void;
  value?: Date | number;
  modeSelect?: "day" | "week";
  className?: string;
  showWeekNumbers?: boolean;
  needWorkload?: boolean;
}

const b = new bem("Calendar", { strict: false });

const Calendar: React.FunctionComponent<ICalendarProps> = ({
  needWorkload = true,
  ...props
}) => {
  const mode = props.modeSelect || "day";

  const [activeValue, setActiveValue] = useState<Date | number | undefined>(
    props.value
  );

  //If have star value, set naviDate by this value, else use now day/now week;
  const getStartValue = (): Date => {
    if (mode === "day") {
      return props.value && props.value instanceof Date
        ? props.value
        : new Date();
    } else {
      if (typeof props.value === "number") {
        console.warn("Deprecated usage of number current time");
      }
      return moment(props.value).toDate();
    }
  };

  // Check start value of Calendar;
  const [naviDate, setNaviDate] = useState<Date>(getStartValue());
  const [weeks, setWeeks] = useState<any[]>([]);
  const [workload, setWorkload] = useState([]);
  const [{ response }, fetchWorkload] = useFetch<any[]>(
    "/doctor/appointment/light"
  );

  useEffect(() => {
    if (weeks?.length) {
      const firstDay = moment(weeks[0].dates[0]).format("YYYY-MM-DDT00:00:00");
      const lastDay = moment(weeks[weeks.length - 1].dates[6]).format(
        "YYYY-MM-DDT23:59:00"
      );
      fetchWorkload({
        url: `/doctor/appointments/light/${firstDay}/${lastDay}`,
      });
    }
  }, [weeks]);

  useEffect(() => {
    const result = {};
    if (response) {
      response.forEach(
        (
          appointmentLight: IAppointmentLight,
          appointmentLightIndex: number,
          appointmentLights
        ) => {
          const keyDay = moment(appointmentLight.startDate).format(
            "YYYY-MM-DD"
          );
          result[keyDay] = result[keyDay] || {};
          if (appointmentLight.status === "WORK_TIME") {
            result[keyDay].hasFreeSlot = true;
          }
          if (
            appointmentLight.status === "ADMIN_APPOINTMENT" ||
            appointmentLight.status === "PATIENT_APPOINTMENT"
          ) {
            result[keyDay].hasAppointment = true;
          }
        }
      );
      setWorkload(result);
    }
  }, [response]);

  useEffect(() => {
    setActiveValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setWeeks(sortDaysByNumberWeek(getDaysArrayByMonth(naviDate)));
  }, [naviDate]);

  const onClickDay = (date: Date): void => {
    if (mode === "day") {
      setActiveValue(date);
      props.onChange && props.onChange(date);
    }
  };

  const onClickWeek = (weekNumber: number): void => {
    if (mode === "week") {
      setActiveValue(weekNumber);
      props.onChange && props.onChange(weekNumber);
    }
  };

  const renderDay = (date: Date, activeWeek?: boolean) => {
    let active = activeWeek;

    if (mode === "day") {
      const stringDate = moment(date).format("D MMM YYYY");
      const stringValue =
        props.value && moment(props.value).format("D MMM YYYY");
      const stringActiveDate = moment(activeValue).format("D MMM YYYY");

      // Value from props is in priority for set active Date
      if (props.value && stringValue === stringDate) {
        active = true;
      } else if (!props.value && stringActiveDate === stringDate) {
        active = true;
      }
    }
    const keyDay = moment(date).format("YYYY-MM-DD");

    const isWeekend =
      moment(date).isoWeekday() === 6 || moment(date).isoWeekday() === 7;

    const hasFreeSlotNextDay =
      moment(date).isoWeekday() < 7 &&
      workload[moment(date).add(1, "day").format("YYYY-MM-DD")]?.hasFreeSlot
        ? {
            hasFreeSlotNextDay: true,
          }
        : {};
    const hasFreeSlotPrevDay =
      moment(date).isoWeekday() > 1 &&
      workload[moment(date).subtract(1, "day").format("YYYY-MM-DD")]
        ?.hasFreeSlot
        ? {
            hasFreeSlotPrevDay: true,
          }
        : {};

    return (
      <div
        className={classNames(
          b("week__day"),
          b(
            "tile",
            {
              active,
              ...workload[keyDay],
              ...hasFreeSlotNextDay,
              ...hasFreeSlotPrevDay,
            },
            [isWeekend && "weekend"]
          )
        )}
        key={moment(date).format("YYYY-MM-DD")}
        onClick={() => {
          onClickDay(date);
        }}
      >
        {moment(date).format("D")}
      </div>
    );
  };

  const onClickNaviButton = (direction: "next" | "prev") => {
    switch (direction) {
      case "next":
        setNaviDate(moment(naviDate).add(1, "months").toDate());
        break;
      case "prev":
        setNaviDate(moment(naviDate).subtract(1, "months").toDate());
        break;
    }
  };

  const isShowedWeekNumbers = props.showWeekNumbers;

  return (
    <div
      className={`
            ${b()} 
            ${b("mode", [mode])} 
            ${isShowedWeekNumbers ? b("show-week-numbers") : ""}
            ${props.className}
          `}
    >
      <div className={b("navi")}>
        <div
          className={b("navi__button")}
          onClick={() => {
            onClickNaviButton("prev");
          }}
        >
          <img src={Arrow} style={{ transform: "rotate(180deg)" }} />
        </div>

        <div className={b("navi__month")}>
          {moment(naviDate).format("MMMM YYYY")}
        </div>

        <div
          className={b("navi__button")}
          onClick={() => {
            onClickNaviButton("next");
          }}
        >
          <img src={Arrow} />
        </div>
      </div>

      <div className={b("days")}>
        <div className={b("days__name-week-days")}>
          <div className={b("tile")}></div>
          <div className={b("tile")}>Mon</div>
          <div className={b("tile")}>Tue</div>
          <div className={b("tile")}>Wen</div>
          <div className={b("tile")}>Thu</div>
          <div className={b("tile")}>Fri</div>
          <div className={`${b("tile")} ${b("tile", ["weekend"])}`}>Sat</div>
          <div className={`${b("tile")} ${b("tile", ["weekend"])}`}>Sun</div>
        </div>
        <div className={b("weeks")}>
          {
            <>
              {weeks.map((week) => {
                const activeWeek = week.week === activeValue;
                return (
                  <div className={b("week")} key={`week-${week.week}`}>
                    <div
                      className={`${b("tile")} ${b("week__number")}`}
                      key={`weekNumber-${week.week}`}
                    >
                      {props.showWeekNumbers && week.week}
                    </div>
                    <div
                      className={`${b("week__days")} ${
                        activeWeek ? b("week", ["active"]) : ""
                      }`}
                      onClick={() => onClickWeek(week.date)}
                      key={`container-of-weekdays-${week.week}`}
                    >
                      {week.dates.map((date) => {
                        return renderDay(date, activeWeek);
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          }
        </div>
      </div>
    </div>
  );
};

const getDaysArrayByMonth = (date: Date): Date[] => {
  const currentMoment = moment(date);
  let daysInMonth = currentMoment.daysInMonth();
  const arrDays = [];

  for (let i = 1; i <= daysInMonth; i++) {
    arrDays.push(moment(currentMoment).date(i).toDate());
  }
  if (moment(arrDays[0]).isoWeekday() !== 1) {
    const firstDate = moment(arrDays[0]);
    const firstWeekDay = firstDate.isoWeekday();
    for (let i = 1; i < firstWeekDay; i++) {
      arrDays.unshift(moment(firstDate.subtract(1, "days")).toDate());
    }
  }
  if (moment(arrDays[arrDays.length - 1]).isoWeekday() !== 7) {
    const lastDate = moment(arrDays[arrDays.length - 1]);
    const lastWeekDay = lastDate.isoWeekday();
    for (let i = lastWeekDay; i < 7; i++) {
      arrDays.push(moment(lastDate.add(1, "days")).toDate());
    }
  }
  return arrDays;
};

const sortDaysByNumberWeek = (dates: Date[]) => {
  const weeksArray: any[] = [];
  const weeksMap: { [key: string]: number } = {};
  dates.forEach((date) => {
    const momentDate = moment(date);
    if (weeksMap.hasOwnProperty(`${momentDate.isoWeek()}`)) {
      const indexWeek = weeksMap[`${momentDate.isoWeek()}`];
      weeksArray[indexWeek].dates.push(date);
    } else {
      weeksArray.push({ week: momentDate.isoWeek(), date: momentDate,dates: [date] });
      weeksMap[`${momentDate.isoWeek()}`] = weeksArray.length - 1;
    }
  });
  return weeksArray;
};

export default React.memo(Calendar);
