import {ChatStore} from '$/contexts/chat';
import {CurrentUserContext} from '$/contexts/currentUser';
import useFetch from '$/hooks/useFetch';
import {globalHeaders} from '$/services/http-client.service';
import axios from 'axios';
import {useContext, useEffect} from 'react';

const ChatController = ({ children }) => {
  const [chatState, dispatch] = useContext(ChatStore);
  const [userContext] = useContext(CurrentUserContext);

  const apiUrl = "/doctor/chat/list";
  const [{ response, error, isLoading }, fetchChat] = useFetch(apiUrl);

  useEffect(() => {
    if (userContext.isLoggedIn) {
      fetchChat();
    }
  }, [fetchChat, userContext.isLoggedIn]);

  useEffect(() => {
    if (userContext
      && 'currentUser' in userContext
      && userContext.currentUser
      && 'sithsid' in userContext.currentUser
      && !!userContext.currentUser.sithsid) {
      dispatch({
        type: 'SET_CURRENT_USER',
        payload: {
          id: userContext.currentUser.sithsid,
          readyForChat: userContext.currentUser.readyForChat,
        },
      });
    }
  }, [userContext]);

  useEffect(() => {
    if (response) {
      dispatch({
        type: "SET_CHAT_LIST",
        payload: response,
      });

      response.forEach((chat) => {
        let id;
        if (chat.appointmentId) {
          axios
            .get(
              `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/appointment/${chat.appointmentId}`,
              {
                withCredentials: true,
                headers: globalHeaders,
              }
            )
            .then((res) => res.data)
            .then((res) => {
              if (res) {
                dispatch({
                  type: "SET_CHAT",
                  payload: { ...chat, appointment: res },
                });
              }
            });
        } else {
          // FIXME: This question is for backend. It's really hard&&&
          chat.messages.forEach((msg) => {
            if (msg.appointmentId) id = msg.appointmentId;
          });
          id &&
            axios
              .get(
                `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/appointment/${id}`,
                {
                  withCredentials: true,
                }
              )
              .then((res) => res.data)
              .then((res) => {
                if (res) {
                  dispatch({
                    type: "SET_CHAT",
                    payload: { ...chat, appointment: res },
                  });
                }
              });
        }
      });
    }
  }, [response]);

  // useEffect(() => {
  //   chatState.chatList.forEach((chat) => {
  //     let id;
  //     if (chat.appointment || chat._appointment) {
  //       return;
  //     }
  //     if (chat.appointmentId) {
  //       chat._appointment = axios
  //         .get(
  //           `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/appointment/${chat.appointmentId}`,
  //           {
  //             withCredentials: true,
  //             headers: globalHeaders,
  //           }
  //         )
  //         .then((res) => res.data)
  //         .then((res) => {
  //           if (res) {
  //             dispatch({
  //               type: "SET_CHAT",
  //               payload: { ...chat, appointment: res },
  //             });
  //           }
  //         });
  //     } else {
  //       // FIXME: This question is for backend. It's really hard&&&
  //       chat.messages.forEach((msg) => {
  //         if (msg.appointmentId) id = msg.appointmentId;
  //       });
  //       if (!id) {
  //         return;
  //       }

  //       chat._appointment = axios
  //         .get(
  //           `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/appointment/${id}`,
  //           {
  //             withCredentials: true,
  //           }
  //         )
  //         .then((res) => res.data)
  //         .then((res) => {
  //           if (res) {
  //             dispatch({
  //               type: "SET_CHAT",
  //               payload: { ...chat, appointment: res },
  //             });
  //           }
  //         });
  //     }
  //   });
  // }, [chatState.chatList]);

  useEffect(() => {
    dispatch({
      type: "SET_LOADING_CHAT_LIST",
      payload: isLoading,
    });
  }, [isLoading]);

  useEffect(() => {
    dispatch({
      type: "SET_ERROR_CHAT_LIST",
      payload: error,
    });
  }, [error]);

  const handlerNewMessage = (message) => {
    const chatId = message.objectId;
    // axios.get(`${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/chat/${chatId}`)
    //   .then(res => res.data)
    //   .then(res => {
    //     dispatch({
    //       type: 'SET_CHAT',
    //       payload: res
    //     })
    //   })
  };

  // useEffect(() => {
  //   if (userState.currentUser) {
  //     dispatchWebSocket({
  //       type: 'ADD_SUBSCRIBE',
  //       payload: handlerNewMessage
  //     })
  //   }
  //
  // }, [userState.currentUser])

  return children;
};

export default ChatController;
