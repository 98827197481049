import "./index.scss"
import * as React from 'react';

interface ITitlePage {
    children: React.ReactChild,
    className?: string;
}

export default (props: ITitlePage) => {
    return (
        <h2 className={`titlePage ${props.className}`}>
            {props.children}
        </h2>
    )
}