import { Badge } from '$/components/badge/Badge';
import PhotoIcon from '$/components/common/photoIcon';
import LoaderColored from '$/components/loaderColored';
import { ChatStore } from '$/contexts/chat';
import { CurrentUserContext } from '$/contexts/currentUser';
import { AppointmentTypeEnum, IChat, IPatient } from '$/models';
import { Link } from '@reach/router';
import bem from 'bem-ts';
import classNames from 'classnames';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';

import './index.scss';


const b = new bem('ChatList', { strict: false });

interface IChatListProps {
  onSelect: (chatId: string) => void;
}

const ChatList: React.FunctionComponent<IChatListProps> = ({ onSelect }) => {
  const [ chatState ] = useContext(ChatStore);

  const renderedChatList = useMemo(() => {
    const { chatList } = chatState;
    if (chatList) {
      return <div className={b('chatList')}>
        {
          chatList
            .filter((chat: IChat) => {
              const term = chatState.searching.toLowerCase();
              const lastMessages = chat.messages[chat.messages.length - 1];
              const patient = lastMessages.patient;
              const namePatient = `${patient.personalInfo.firstName} ${patient.personalInfo.lastName}`.toLowerCase();
              const patientId = patient.bankId;
              const doctor = chat.doctor || 'chatbot';
              const doctorId = chat.doctor?.sithsid || '';
              const nameDoctor = (chat.doctor &&
                  `${doctor.personalInfo.firstName} ${doctor.personalInfo.lastName}`.toLowerCase()) ||
                'chatbot';

              return (
                namePatient.includes(term) ||
                (nameDoctor || 'chatbot').includes(term) ||
                patientId.includes(term) ||
                doctorId.includes(term)
              );
            })
            .map((chat: IChat, index: number) => {
              return <ChatItem chat={chat} key={`chat-item-${chat.chatId}`}
                               onClick={() => onSelect(chat.chatId)}/>;
            })
        }
      </div>;
    }
  }, [ chatState ]);


  return (
    <div className={`page ${b()}`}>

      <div className={`${b('chatContent')}`}>
        {chatState.isLoadingChatList ? (
          <div className={b('loader')}>
            <LoaderColored/>
          </div>
        ) : (
          chatState.isErrorChatList ? 'Chats not found' : renderedChatList
        )}
      </div>
    </div>
  );
};


const ChatItem = (props) => {
  const [ userState ] = useContext(CurrentUserContext);

  const { chat } = props;

  const patient: IPatient = chat.messages[0].patient;
  const fullName = `${patient.personalInfo.firstName} ${patient.personalInfo.lastName}`;
  const patientId = patient.bankId;

  const lastMessage = chat.messages[chat.messages.length - 1];

  const isNurseRequest = userState.currentUser.readyForChat && chat.state === 'TO_NURSE';
  const countUnreadMessages = (chat.unreadMessages?.[userState.currentUser?.sithsid] ?? 0) + (isNurseRequest ? 1 : 0);

  const renderTimeAppointment = () => {
    let icon;
    switch (chat.appointment?.appointmentType) {
      case AppointmentTypeEnum[0]:
        icon = <i className="far fa-shoe-prints"/>;
        break;
      case AppointmentTypeEnum[1]:
        icon = <i className="far fa-video"/>;
        break;
      case AppointmentTypeEnum[2]:
        icon = <i className="far fa-phone-alt"/>;
        break;
    }
    let now = moment();
    let timeString = '';
    const checkedDate = moment('2020-08-10');
    // const checkedDate = appointment.startDate;
    const isPassed = now.isAfter(moment(checkedDate));
    const isToday = now.format('YYYY-MM-DD') === moment(checkedDate).format('YYYY-MM-DD');

    const getTimeForToday = () => {
      const diffDates = moment(moment()).diff(checkedDate, 'minutes');
      if (diffDates > 60) {
        const minutes = diffDates % 60;
        const hours = Math.trunc(diffDates / 60);
        timeString = `in ${hours}h ${minutes ? minutes + 'min' : ''}`;
      }
      return true;
    };

    if (isPassed) {
      timeString = `Passed`;
    } else if (isToday) {
      getTimeForToday() && setInterval(getTimeForToday, 60000);
    } else {
      const diffDays = moment(checkedDate).diff(now, 'days') + 1;

      if (diffDays === 1) {
        timeString = `Tomorrow`;
      } else if (diffDays === 7) {
        timeString = `in a week`;
      } else if (diffDays % 7 === 0) {
        timeString = `in ${diffDays / 7} weeks`;
      } else {
        timeString = `in ${diffDays} days`;
      }
    }


    return (
      <div
        className={`${b('chatList__item__appointment__when')} ${b('chatList__item__appointment__type', [ chat.appointment?.appointmentType.toLowerCase() ])}`}>
        <div className={`${b('chatList__item__appointment__when__title')}`}>
          {icon} {timeString}
        </div>
        <div className={`${b('chatList__item__appointment__when__time')}`}>
          {!isToday && `${moment.utc(chat.appointment?.startDate).local().format('DD.MM')}, `}{moment.utc(chat.appointment?.startDate).local().format('HH:mm')}-{moment.utc(chat.appointment?.endDate).local().format('HH:mm')}
        </div>
      </div>
    );
  };

  let time = '';
  const isToday = moment().format('YYYY-MM-DD') === moment(lastMessage.dateTime).format('YYYY-MM-DD');
  const isYesterday = !isToday && (moment().diff(moment(lastMessage.dateTime), 'days') + 1) === 1;

  if (isToday) {
    time = moment.utc(lastMessage.dateTime).local().format('HH:mm');
  } else if (isYesterday) {
    time = 'Yesterday';
  } else {
    time = moment.utc(lastMessage.dateTime).local().format('DD.MM');
  }

  return (
    <div
      data-id={chat.chatId}
      onClick={props.onClick}
      className={b('chatList__item')}
    >
      <div className={b('chatList__item__person')}>
        <Badge count={countUnreadMessages}>
          <PhotoIcon person={patient}/>
        </Badge>

        <div className={b('chatList__item__person__info')}>
          <span className={b('chatList__item__person__fullName')}>
            {fullName}
          </span>

          <span className={b('chatList__item__person__patientId')}>
            {patientId}
          </span>
        </div>
      </div>

      <div className={b('chatList__item__symptoms')}>
        {chat.appointment && chat.appointment?.symptoms?.join(', ')}
      </div>

      <div className={b('chatList__item__appointment')}>
        {chat.appointment &&
          <Link to={`/appointment/${chat.appointment.id}`}>
            {renderTimeAppointment()}
          </Link>}
      </div>

      <div className={b('chatList__item__partner')}>
        {chat.appointment?.doctor && `${chat.appointment?.doctor.personalInfo.firstName} ${chat.appointment?.doctor.personalInfo.lastName}`}
      </div>

      <div className={b('chatList__item__previewMessages')}>
        <span className={classNames(b('chatList__item__previewMessages__text', { unread: !!countUnreadMessages }))}>
          {lastMessage.message}
        </span>

        <span className={b('chatList__item__previewMessages__time')}>
          {time}
        </span>
      </div>
    </div>
  );
};

export default ChatList;