import { TimePicker } from '$/components/appointmentDateSelector/TimePicker';
import Calendar from '$/components/common/calendar';
import { CurrentUserContext } from '$/contexts/currentUser';
import { GlobalContext } from '$/contexts/global';
import { AppointmentTypeEnum, IAppointment } from '$/models';
import { Appointment } from '$/stores/appointment';
import GlobalStore from '$/stores/global';
import { Combobox, ComboboxInput } from '@reach/combobox';
import { Menu, MenuButton, MenuItem, MenuPopover } from '@reach/menu-button';
import bem from 'bem-ts';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';

const b = bem("AppointmentDateSelector", { strict: false });

interface IRange {
  startTime?: string;
  endTime?: string;
  type?: string | AppointmentTypeEnum;
}

interface IAppointmentDateSelectorProps {
  appointmentStore: Appointment;
  autoSet: boolean;
}

const AppointmentDateSelector: React.FC<IAppointmentDateSelectorProps> = ({
  appointmentStore,
  autoSet = true,
}) => {
  const [openedCalendar, setOpenedCalendar] = useState(true);
  const [range, setRange] = useState<IRange>({});
  const [during, setDuring] = useState<number>(15);
  const [date, setDate] = useState<Date | undefined>();
  const calendarPopoverRef = useRef(null);

  useEffect(() => {
    const init = {
      startTime: "",
      endTime: "",
    };

    init.startTime = moment
      .utc(appointmentStore.startDate)
      .format("YYYY-MM-DDTHH:mm:00");

    init.endTime =
      (appointmentStore.endDate &&
        moment.utc(appointmentStore.endDate).format("YYYY-MM-DDTHH:mm:00")) ||
      moment(init.startTime).add(15, "minutes").format("YYYY-MM-DDTHH:mm:00");

    setDuring(moment(init.endTime).diff(moment(init.startTime), "minutes"));

    setDate(moment(init.startTime).toDate());

    setRange(init);
  }, []);

  useEffect(() => {
    if (Object.values(range).length) {
      appointmentStore.setField(
        "startDate",
        moment.utc(range.startTime).format("YYYY-MM-DDTHH:mm:00")
      );
      appointmentStore.setField(
        "endDate",
        moment.utc(range.endTime).format("YYYY-MM-DDTHH:mm:00")
      );
      if (range.type) {
        appointmentStore.appointmentType = range.type;
      }
    }
  }, [range]);

  const [duringInputValue, setDuringInputValue] = useState(`${during} Min`);
  useEffect(() => {
    setDuringInputValue(`${during} Min`);
  }, [during]);
  const globalStore = useContext<GlobalStore>(GlobalContext);
  const [ suggestedTimes, setSuggestedTimes ] = useState<Array<{
    type?: string | AppointmentTypeEnum,
    startTime: string,
    endTime: string
  }>>([]);

  const [userState] = useContext(CurrentUserContext);

  useEffect(() => {
    if (appointmentStore.appointmentType && suggestedTimes && !autoSet) {
      const newRange = suggestedTimes.find(
        (slot) => slot.type === appointmentStore.appointmentType
      );
      newRange && setRange(newRange);
    }
  }, [appointmentStore.appointmentType]);

  // const throttledTermDuring = useThrottle(during, 800);

  // useEffect(() => {
  //   const isoDate = moment(date).format("YYYY-MM-DD");
  //   const id = userState.currentUser && userState.currentUser.sithsid;
  //   throttledTermDuring &&
  //     ![0, "0"].includes(throttledTermDuring) &&
  //     isoDate !== "Invalid date" &&
  //     globalStore.http
  //       .get(
  //         `/doctor/free_slots/?date=${isoDate}T00:00Z&doctor=${id}&duration=${throttledTermDuring}`
  //       )
  //       .then((res) =>
  //         callbackSuggestedTimes(
  //           ((Array.isArray(res?.data) && res?.data) || []) as IAppointment[],
  //           true,
  //           isoDate
  //         )
  //       );
  // }, [throttledTermDuring]);

  useEffect(() => {
    if (!date) return;
    const isoDate = moment(date).format("YYYY-MM-DD");
    const id = userState.currentUser && userState.currentUser.sithsid;
    during &&
      ![0, "0"].includes(during) &&
      isoDate !== "Invalid date" &&
      globalStore.http
        .get(
          `/doctor/free_slots/?date=${isoDate}T00:00Z&doctor=${id}&duration=${during}`
        )
        .then((res) =>
          callbackSuggestedTimes(
            ((Array.isArray(res?.data) && res?.data) || []) as IAppointment[],
            false,
            isoDate,
            during
          )
        );
  }, [date]);

  const callbackSuggestedTimes = (
    appointments: IAppointment[],
    setDate: boolean = false,
    targetDate: string,
    during: number
  ) => {
    let freeSlots: {
      startTime: string;
      endTime: string;
      type?: string | AppointmentTypeEnum;
    }[] = [];

    [...appointments].forEach((appointment) => {
      for (
        let i = moment.utc(appointment.startDate);
        i <= moment.utc(appointment.endDate);
        i.add(during, "minutes")
      ) {
        if (!moment.utc().local().isAfter(moment.utc(i).local())) {
          const startTime = i.format("YYYY-MM-DDTHH:mm:00");
          const endTime = moment
            .utc(i)
            .add(during, "minutes")
            .format("YYYY-MM-DDTHH:mm:00");

          freeSlots.push({
            startTime,
            endTime,
            type: appointment.appointmentType,
          });
        }
      }
    });

    freeSlots = freeSlots.sort((a, b) => {
      if (a.startTime < b.startTime) {
        return -1;
      } else if (a.startTime > b.startTime) {
        return 1;
      } else {
        return 0;
      }
    });

    if (setDate && autoSet && freeSlots && freeSlots.length) {
      let currentSelectedDate: Moment | null = null;
      if (targetDate) {
        const selectedAppointment = moment.utc(targetDate);

        currentSelectedDate = moment(selectedAppointment)
          .set("year", selectedAppointment.get("year"))
          .set("month", selectedAppointment.get("month"))
          .set("dayOfYear", selectedAppointment.get("dayOfYear"));
      }
      const firstForSet = freeSlots
        .map((s) => ({
          ...s,
          slotType: s.type === appointmentStore.appointmentType ? 1 : 0,
          diff: currentSelectedDate
            ? moment.utc(s.startTime).diff(currentSelectedDate, "minutes")
            : 0,
        }))
        .sort((a, b) => {
          var o1 = a.slotType;
          var o2 = b.slotType;

          var p1 = Math.abs(a.diff);
          var p2 = Math.abs(b.diff);

          if (o1 > o2) return -1;
          if (o1 < o2) return 1;
          if (p1 < p2) return -1;
          if (p1 > p2) return 1;
          return 0;
        });

      setRange(firstForSet[0]);
    } else {
      if (targetDate) {
        const selectedAppointment = moment.utc(targetDate);

        appointmentStore.startDate = selectedAppointment
          .set('hour', moment.utc(appointmentStore.startDate).get('hour'))
          .set('minute', moment.utc(appointmentStore.startDate).get('minute'))
          .set('seconds', 0).format("YYYY-MM-DDTHH:mm:00")

        appointmentStore.endDate = selectedAppointment
          .set('hour', moment.utc(appointmentStore.endDate).get('hour'))
          .set('minute', moment.utc(appointmentStore.endDate).get('minute'))
          .set('seconds', 0).format("YYYY-MM-DDTHH:mm:00");
      }

      setRange({
        startTime: moment
          .utc(appointmentStore.startDate)
          .format("YYYY-MM-DDTHH:mm:00"),
        endTime: moment
          .utc(appointmentStore.startDate)
          .add(during, "minutes")
          .format("YYYY-MM-DDTHH:mm:00"),
        type: range.type,
      });
    }

    setSuggestedTimes(freeSlots);
  };

  const onChangeDateHandler = (value: Date) => {
    const isoDate = moment(value).format("YYYY-MM-DD");
    const id = userState.currentUser && userState.currentUser.sithsid;
    globalStore.http
      .get(
        `/doctor/free_slots/?date=${isoDate}T00:00Z&doctor=${id}&duration=${during}`
      )
      .then((res) =>
        callbackSuggestedTimes(
          ((Array.isArray(res?.data) && res?.data) || []) as IAppointment[],
          true,
          moment
            .utc(appointmentStore.startDate)
            .set("year", moment(value).get("year"))
            .set("month", moment(value).get("month"))
            .set("dayOfYear", moment(value).get("dayOfYear"))
            .format("YYYY-MM-DDTHH:mm:00"),
          during
        )
      );
  };
  const onChangeDuringHandler = (value: number) => {
    setDuring(value);
    const isoDate = moment(date).format("YYYY-MM-DD");
    const id = userState.currentUser && userState.currentUser.sithsid;
    globalStore.http
      .get(
        `/doctor/free_slots/?date=${isoDate}T00:00Z&doctor=${id}&duration=${value}`
      )
      .then((res) =>
        callbackSuggestedTimes(
          ((Array.isArray(res?.data) && res?.data) || []) as IAppointment[],
          false,
          appointmentStore.startDate,
          value
        )
      );
  };

  const onChangeDate = (value: Date | number) => {
    if (typeof value === "number") return;
    setDate(value);
    onChangeDateHandler(value);

    setOpenedCalendar(false);
    setTimeout(()=>{
      setOpenedCalendar(true);
    })
  };

  const onChangeStartTime = (startDate: Date) =>
    setRange({
      startTime: moment(startDate)
        .utc()
        .format('YYYY-MM-DDTHH:mm:00'),
      endTime: moment(startDate)
        .utc()
        .add(during, 'minutes')
        .format('YYYY-MM-DDTHH:mm:00'),
    });

  return (
    <div className={b()}>
      <i className={`far fa-clock ${b("prefix")}`} />
      <div className={b("wrapper")}>
        <div className={b("fields")}>
          <div className={b("fields__during")}>
            <Menu>
              <MenuButton
                className={"button button--transparent"}
                style={{ width: "20px" }}
              >
                <i className="far fa-angle-down" />
              </MenuButton>

              <MenuPopover>
                {[5, 10, 15, 20, 25, 30].map((time: number) => {
                  return (
                    <MenuItem
                      className={b("fields__during__list__option")}
                      key={`${time}-during`}
                      onSelect={() => {
                        onChangeDuringHandler(time);
                      }}
                    >
                      {`${time} min`}
                    </MenuItem>
                  );
                })}
              </MenuPopover>
            </Menu>

            <Combobox>
              <ComboboxInput
                onFocus={(e) => {
                  setDuringInputValue(e.target.value.split(" ")[0]);
                }}
                onChange={(e) => {
                  setDuringInputValue(e.target.value);
                }}
                onBlur={(e) => {
                  onChangeDuringHandler(e.target.value.split(" ")[0]);
                  setDuringInputValue(e.target.value + " Min");
                }}
                value={duringInputValue}
                style={{ width: "70px" }}
              />
            </Combobox>
          </div>

          <div className={b("fields__range")}>
            <div
              className={`button button--transparent ${b(
                "fields__range__start"
              )}`}
            >
              {range && date && (
                <TimePicker
                  date={moment.utc(range.startTime?.toString()).local().toDate()}
                  className={b('fields__range__start__input')}
                  onTimeChange={onChangeStartTime}
                />)
              }
            </div>
            –
            <div className={`${b("fields__range__end")}`}>
              {range && moment.utc(range.endTime).local().format("HH:mm")}
            </div>
          </div>
          {openedCalendar && (
          <div className={b("fields__date")}>
            <Menu>
              <MenuButton
                type={"transparent"}
                className={`${b(
                  "fields__date__label"
                )} button button--transparent`}
                ref={calendarPopoverRef}
              >
                {moment(date).format("D MMM YYYY")}
              </MenuButton>
              <MenuPopover className={b("fields__date__popover")}>
                <div className={b("fields__date__calendar")}>
                  <Calendar value={date} onChange={onChangeDate} />
                </div>
              </MenuPopover>
            </Menu>
          </div>
          )}
        </div>

        <div className={b("suggestedTimes")}>
          <Menu>
            <MenuButton>
              <span className={b("suggestedTimes__button")}>
                Suggested times <i className="far fa-chevron-down"></i>
              </span>
            </MenuButton>

            {!!suggestedTimes.length && (
              <MenuPopover className={b("suggestedTimes__list")}>
                {suggestedTimes.map((range, index) => {
                  return (
                    <MenuItem
                      key={`${index}-of-times`}
                      className={`${b(
                        "suggestedTimes__list__option"
                      )} ${b("suggestedTimes__list__option__type", [
                        range?.type?.toLowerCase(),
                      ])}`}
                      onSelect={() => {
                        setRange({
                          startTime: moment
                            .utc(range?.startTime)
                            .format("YYYY-MM-DDTHH:mm"),
                          endTime: moment
                            .utc(range?.endTime)
                            .format("YYYY-MM-DDTHH:mm"),
                          type: range?.type,
                        });
                      }}
                    >
                      {`${moment
                        .utc(range?.startTime)
                        .local()
                        .format("HH:mm, DD MMM YYYY")}`}
                    </MenuItem>
                  );
                })}
              </MenuPopover>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default observer(AppointmentDateSelector);
