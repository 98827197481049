import {CurrentUserContext} from '$/contexts/currentUser';
import {WebSocketStore} from '$/contexts/webSocket';
import {SocketClient} from '$/utils/Websocket/SocketClient';
import React, {useContext} from 'react';

export default ({children}) => {
  const [userState] = useContext(CurrentUserContext);
  const [webSocketStore] = useContext(WebSocketStore);

  const currentUser = (userState.isLoggedIn && userState.currentUser) ? userState.currentUser : undefined;
  const subscribes = webSocketStore.subscribes ?? [];

  return (
    <>
      <SocketClient currentUser={currentUser} subscribes={subscribes}/>
      {children}
    </>
  );
};