import React, { useContext, useState, useEffect } from "react";
import bem from "bem-ts";
import InputMask from "react-input-mask";

import "./index.scss";
import Button from "$/components/common/button";
import moment from "moment";
import { Menu, MenuButton, MenuItem, MenuPopover } from "@reach/menu-button";
import { ScheduleContext } from "$/contexts/schedule";
import { AppointmentTypeEnum } from "$/models";

const b = new bem("TemplateCreateForm", { strict: false });

interface ITemplateCreateFormProps {
  type: AppointmentTypeEnum;
  template: any;
  selectTime?: {
    day: number | string;
    startWorkTime: string;
    endWorkTime: string;
  };
  dismissAction: () => void;
  save: ({
    time: { startWorkTime: string, endWorTime: string },
    day: number,
    type: AppointmentTypeEnum,
  }) => void;
  delete: () => void;
}

const INIT_FORM = {
  type: "PHYSICAL",
  time: {
    startWorkTime: "08:00:00",
    endWorkTime: "09:00:00",
  },
  day: 1,
};

const TemplateCreateForm: React.FC<ITemplateCreateFormProps> = ({
  type,
  template,
  ...props
}) => {
  const [scheduleStore] = useContext(ScheduleContext);
  const [initSelectTime, setInitSelectTime] = useState(props.selectTime);

  const [formData, setFormData] = useState(INIT_FORM);

  const changeType = (event) => {
    setFormData({ ...formData, type: event.target.value });
  };

  useEffect(() => {
    if (initSelectTime) {
      const { startWorkTime, endWorkTime, day } = initSelectTime;
      setFormData({
        ...formData,
        time: {
          startWorkTime,
          endWorkTime,
        },
        day,
      });
    }
  }, [initSelectTime]);

  const onSelectDay = (day: number) => {
    setFormData({ ...formData, day });
  };

  const onBlurEndTime = (value) => {
    if (
      getUtcTime(value, formData.day) >=
      getUtcTime(formData.time.startWorkTime, formData.day)
    ) {
      setFormData({
        ...formData,
        time: {
          ...formData.time,
          endWorkTime: getUtcTime(value, formData.day),
        },
      });
      return true;
    }
  };
  const onBlurStartTime = (value) => {
    if (
      getUtcTime(value, formData.day) <=
      getUtcTime(formData.time.endWorkTime, formData.day)
    ) {
      setFormData({
        ...formData,
        time: {
          ...formData.time,
          startWorkTime: getUtcTime(value, formData.day),
        },
      });
      return true;
    }
  };

  const onSave = () => {
    props.save(formData);
    props.dismissAction();
  };

  return (
    <div className={b()}>
      <div className={b("content")}>
        <h2>Create slot</h2>
        <div className={"type__buttons"}>
          <Button
            value={"PHYSICAL"}
            onClick={changeType}
            className={b("type", [
              "physical",
              formData.type === "PHYSICAL" && "active",
            ])}
          >
            <i className="far fa-shoe-prints" /> Physical
          </Button>
          <Button
            value={"VIDEO"}
            onClick={changeType}
            className={b("type", [
              "video",
              formData.type === "VIDEO" && "active",
            ])}
          >
            <i className="far fa-video" /> Video
          </Button>
          <Button
            value={"CALL"}
            onClick={changeType}
            className={b("type", [
              "call",
              formData.type === "CALL" && "active",
            ])}
          >
            <i className="far fa-phone-alt" /> Call
          </Button>
        </div>

        <div className={b("time")}>
          <Menu>
            <MenuButton
              className={"button button--transparent"}
              style={{ width: "58px" }}
            >
              {formData?.day && moment().isoWeekday(formData.day).format("ddd")}
            </MenuButton>
            <MenuPopover>
              {[1, 2, 3, 4, 5, 6, 7].map((weekDay) => {
                return (
                  <MenuItem
                    className={b("time__weekday__list__option")}
                    key={`${weekDay}`}
                    onSelect={() => {
                      onSelectDay(weekDay);
                    }}
                  >
                    {moment().isoWeekday(weekDay).format("ddd")}
                  </MenuItem>
                );
              })}
            </MenuPopover>
          </Menu>
          <MaskField
            className={b("time__start")}
            onBlur={onBlurStartTime}
            value={getLocalTime(formData?.time?.startWorkTime, formData.day)}
          />
          &ensp;–&ensp;
          <MaskField
            className={b("time__end")}
            onBlur={onBlurEndTime}
            value={getLocalTime(formData?.time?.endWorkTime, formData.day)}
          />
        </div>
      </div>

      <div className={b("footer")}>
        <div className={b("footer__buttons")}>
          {/*{error && (*/}
          {/*  <span className={b("footer__buttons__error")}>{error}</span>*/}
          {/*)}*/}
          <Button
            className={b("footer__buttons__button", ["cancel"])}
            view="cancel"
            onClick={props.dismissAction}
          >
            Cancel
          </Button>

          <Button
            className={b("footer__buttons__button", ["save"])}
            onClick={onSave}
          >
            Create
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TemplateCreateForm;

const getLocalTime = (utcTime: string, day: number) => {
  if (utcTime) {
    return moment
      .utc()
      .isoWeekday(day)
      .hour(utcTime.split(":")[0])
      .minutes(utcTime.split(":")[1])
      .local()
      .format("HH:mm");
  }
};

const getUtcTime = (localTime: string, day: number) => {
  return moment()
    .local()
    .isoWeekday(day)
    .hour(localTime.split(":")[0])
    .minutes(localTime.split(":")[1])
    .utc()
    .format("HH:mm:00");
};

const MaskField: React.FC<{
  className?: string;
  value: string;
  onBlur: (value) => void;
}> = (props) => {
  const [term, setTerm] = useState(props.value);

  useEffect(() => {
    setTerm(props.value);
  }, [props.value]);

  const onChange = (event) => {
    setTerm(event.target.value);
  };

  const onBlur = () => {
    const success = props.onBlur(term);
    !success && setTerm(props.value);
  };

  return (
    <InputMask
      className={props.className}
      mask={"99:99"}
      onChange={onChange}
      onBlur={onBlur}
      value={term}
    />
  );
};

// const [scheduleStore] = useContext(ScheduleContext);
// const [type, setType] = useState(type);
// const [day, setDay] = useState(null);
// const [error, setError] = useState(null);
//
// const [startTimeTerm, setStartTimeTerm] = useState<string>("");
// const [endTimeTerm, setEndTimeTerm] = useState<string>("");
// const [time, setTime] = useState<{ startWorkTime: Date; endWorkTime: Date }>({
//   startWorkTime: "",
//   endWorkTime: "",
// });
// const [freeSlots, setFreeSlots] = useState([]);
//
// useEffect(() => {
//   if (
//     freeSlots.some((slot) => {
//       return (
//         time.startWorkTime >= slot.startWorkTime &&
//         time.startWorkTime <= slot.endWorkTime &&
//         time.endWorkTime >= slot.startWorkTime &&
//         time.endWorkTime <= slot.endWorkTime
//       );
//     })
//   ) {
//     setError(null);
//   } else {
//     setError("Time of new slots mustn't match with time of other slots");
//   }
// }, [time]);
//
// useEffect(() => {
//   setDay(1);
// }, []);
//
// const getFreeSlots = (weekday: number) => {
//   const resultsSlots = [];
//   if (weekday) {
//     const { schedule } = template;
//     if (schedule[weekday].length) {
//       schedule[weekday].forEach((slot, index, day) => {
//         if (index === 0) {
//           if (!day[index + 1]) {
//             resultsSlots.push({
//               startWorkTime: slot.endWorkTime,
//               endWorkTime: moment()
//                 .hours(scheduleStore.maxHour)
//                 .minutes(0)
//                 .format("HH:mm:00"),
//             });
//           }
//           if (
//             slot.startWorkTime !==
//             moment()
//               .hours(scheduleStore.minHour)
//               .minutes(0)
//               .format("HH:mm:00")
//           ) {
//             resultsSlots.push({
//               startWorkTime: moment()
//                 .hours(scheduleStore.minHour)
//                 .minutes(0)
//                 .format("HH:mm:00"),
//               endWorkTime: slot.startWorkTime,
//             });
//           }
//         } else if (index === day.length - 1) {
//           if (day[index - 1]) {
//             const prevSlot = day[index - 1];
//             if (prevSlot.endWorkTime !== slot.startWorkTime) {
//               resultsSlots.push({
//                 startWorkTime: prevSlot.endWorkTime,
//                 endWorkTime: slot.startWorkTime,
//               });
//             }
//           }
//           if (
//             slot.endWorkTime !==
//             moment()
//               .hours(scheduleStore.maxHour)
//               .minutes(0)
//               .format("HH:mm:00")
//           ) {
//             resultsSlots.push({
//               startWorkTime: slot.endWorkTime,
//               endWorkTime: moment()
//                 .hours(scheduleStore.maxHour)
//                 .minutes(0)
//                 .format("HH:mm:00"),
//             });
//           }
//         } else {
//           if (day[index - 1]) {
//             const prevSlot = day[index - 1];
//             if (prevSlot.endWorkTime !== slot.startWorkTime) {
//               resultsSlots.push({
//                 startWorkTime: prevSlot.endWorkTime,
//                 endWorkTime: slot.startWorkTime,
//               });
//             }
//           }
//         }
//       });
//     } else {
//       resultsSlots.push({
//         startWorkTime: moment()
//           .hours(scheduleStore.minHour)
//           .minutes(0)
//           .format("HH:mm:00"),
//         endWorkTime: moment()
//           .hours(scheduleStore.maxHour)
//           .minutes(0)
//           .format("HH:mm:00"),
//       });
//     }
//   }
//   return resultsSlots;
// };
//
// useEffect(() => {
//   setFreeSlots(getFreeSlots(day));
// }, [day]);
//
// useEffect(() => {
//   if (freeSlots?.length || !!props.selectTime) {
//     onSelectTime(
//       props.selectTime || {
//         startWorkTime: freeSlots[0].startWorkTime,
//         endWorkTime: freeSlots[0].endWorkTime,
//       }
//     );
//   }
// }, [freeSlots]);
//
// const onClickType = (e) => {
//   setType(e.target.value);
// };
//
// const onChangeStartTime = (e) => {
//   setStartTimeTerm(e.target.value);
// };
// const onChangeEndTime = (e) => {
//   setEndTimeTerm(e.target.value);
// };
// const onBlurStartTime = (e) => {
//   if (`${e.target.value}:00` <= time.endWorkTime) {
//     setTime({ ...time, startWorkTime: `${e.target.value}:00` });
//     setStartTimeTerm(e.target.value);
//   } else {
//     setStartTimeTerm(time.startWorkTime);
//   }
// };
// const onBlurEndTime = (e) => {
//   if (`${e.target.value}:00` >= time.startWorkTime) {
//     setTime({ ...time, endWorkTime: `${e.target.value}:00` });
//     setStartTimeTerm(e.target.value);
//   } else {
//     setStartTimeTerm(time.endWorkTime);
//   }
// };
//
// const onSave = () => {
//   props.save({ time, day, type });
//   props.dismissAction();
// };
//
// const onSelectTime = (range) => {
//   !!range.day && setDay(range.day);
//   setTime(range);
//   setStartTimeTerm(
//     `${range.startWorkTime.split(":")[0]}:${
//       range.startWorkTime.split(":")[1]
//     }`
//   );
//   setEndTimeTerm(
//     `${range.endWorkTime.split(":")[0]}:${range.endWorkTime.split(":")[1]}`
//   );
// };
//
// return (
//   <div className={b()}>
//     <div className={b("content")}>
//       <h2>Create slot</h2>
//       <div className={"type__buttons"}>
//         <Button
//           value={"PHYSICAL"}
//           onClick={onClickType}
//           className={b("type", ["physical", type === "PHYSICAL" && "active"])}
//         >
//           <i className="far fa-shoe-prints" /> Physical
//         </Button>
//         <Button
//           value={"VIDEO"}
//           onClick={onClickType}
//           className={b("type", ["video", type === "VIDEO" && "active"])}
//         >
//           <i className="far fa-video" /> Video
//         </Button>
//         <Button
//           value={"CALL"}
//           onClick={onClickType}
//           className={b("type", ["call", type === "CALL" && "active"])}
//         >
//           <i className="far fa-phone-alt" /> Call
//         </Button>
//       </div>
//
//       <div className={b("time")}>
//         <Menu>
//           <MenuButton
//             className={"button button--transparent"}
//             style={{ width: "58px" }}
//           >
//             {day && moment().isoWeekday(day).format("ddd")}
//           </MenuButton>
//           <MenuPopover>
//             {[1, 2, 3, 4, 5, 6, 7].map((weekDay) => {
//               return (
//                 <MenuItem
//                   className={b("time__weekday__list__option")}
//                   key={`${weekDay}`}
//                   onSelect={() => {
//                     setDay(weekDay);
//                   }}
//                 >
//                   {moment().isoWeekday(weekDay).format("ddd")}
//                 </MenuItem>
//               );
//             })}
//           </MenuPopover>
//         </Menu>
//         <InputMask
//           class={b("time__start")}
//           mask={"99:99"}
//           onChange={onChangeStartTime}
//           onBlur={onBlurStartTime}
//           value={moment
//             .utc()
//             .isoWeekday(day || 1)
//             .hour(startTimeTerm.split(":")[0])
//             .minute(startTimeTerm.split(":")[1])}
//         />{" "}
//         –{" "}
//         <InputMask
//           class={b("time__end")}
//           mask={"99:99"}
//           onChange={onChangeEndTime}
//           onBlur={onBlurEndTime}
//           value={moment
//             .utc()
//             .isoWeekday(day || 1)
//             .hour(endTimeTerm.split(":")[0])
//             .minute(endTimeTerm.split(":")[1])}
//         />
//       </div>
//       <div className={b("time__selected")}>
//         <Menu>
//           <MenuButton>
//             <i className={"icon far fa-angle-down"} />
//             Selected times
//           </MenuButton>
//           {!!freeSlots?.length && (
//             <MenuPopover>
//               {freeSlots.map((slot, index) => {
//                 return (
//                   <MenuItem
//                     key={index}
//                     onSelect={() => {
//                       onSelectTime({
//                         startWorkTime: slot.startWorkTime,
//                         endWorkTime: slot.endWorkTime,
//                       });
//                     }}
//                   >
//                     {moment().isoWeekday(day).format("ddd")},{" "}
//                     {slot.startWorkTime} - {slot.endWorkTime}
//                   </MenuItem>
//                 );
//               })}
//             </MenuPopover>
//           )}
//         </Menu>
//       </div>
//     </div>
//
//     <div className={b("footer")}>
//       <div className={b("footer__buttons")}>
//         {error && (
//           <span className={b("footer__buttons__error")}>{error}</span>
//         )}
//         <Button
//           className={b("footer__buttons__button", ["cancel"])}
//           view="cancel"
//           onClick={(e) => {
//             props.dismissAction();
//           }}
//         >
//           Cancel
//         </Button>
//
//         <Button
//           className={b("footer__buttons__button", ["save"])}
//           disable={!!error}
//           onClick={onSave}
//         >
//           Create
//         </Button>
//       </div>
//     </div>
//   </div>
// );
