import React from 'react'
import './index.scss'

interface IButtonProps {
  view?: string;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  children?: React.ReactChild;
  style?: React.CSSProperties;
  onClick?: () => void;
  active?: boolean,
  disabled?: boolean,
  value?: string
}

const Button: React.FunctionComponent<IButtonProps> = (props) => {
  const onClick = (e) => {
    props.view !== 'disabled' && props.onClick && props.onClick(e);
  }
  return (
    <button className={`
                       button 
                       button--${(props.disabled && 'disabled') || props.view || 'primary'} 
                       ${props.active ? 'button--active' : ''} 
                       ${props.className} 
                `}
            value={props.value}
            disabled={props.disabled}
            type={props.type || 'button'}
            onClick={onClick}
            style={props.style}>
      {props.children}
    </button>
  )
}

export default Button;
