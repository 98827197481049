import React, {useContext, useEffect, useMemo, useState} from 'react';

import bem from "bem-ts";
import {ScheduleContext} from "$/contexts/schedule";
import Days from './days'
import Week from './week'
import './index.scss'
import moment from "moment";

const b = new bem('Schedule', {strict: false})


const Schedule = () => {
  const [scheduleStore, dispatchStore] = useContext(ScheduleContext);
  const isWeek = useMemo(() => scheduleStore.view === 'week', [scheduleStore.view]);
  const MAX_HOUR = scheduleStore.maxHour;
  const MIN_HOUR = scheduleStore.minHour;

  const isToday = !isWeek && moment().format('YYYY-MM-DD') === moment(scheduleStore.currentTime).format('YYYY-MM-DD');
  const [nowTime, setNowTime] = useState(!isWeek && new Date() || null)
  setInterval(() => {
    const isWorkTime = moment().isAfter(moment(moment().hour(scheduleStore.minHour).minute(0)), 'minutes') &&
      moment().isBefore(moment(moment().hour(scheduleStore.maxHour).minute(0)), 'minutes')
    const timeLine = isToday && isWorkTime ? new Date() : null;
    setNowTime(timeLine);
  }, 60000)

  useEffect(() => {
    if (scheduleStore.view === 'day') {
      setNowTime(new Date())
    } else {
      setNowTime(null)
    }
  }, [scheduleStore.view])

  const endTime = moment(new Date()).hour(scheduleStore.maxHour).minute(0);
  const bottom = (41 / scheduleStore.zoom) * endTime.diff(moment(nowTime), 'minutes') + 41;
  const styleNowTime = {
    bottom
  }


  const renderHours = () => {
    const startTime = moment().hour(MIN_HOUR).minute(0).second(0);
    const endTime = moment().hour(MAX_HOUR).minute(0).second(0);
    const times = [];
    for (let now = moment(startTime); now <= endTime; now.add(scheduleStore.zoom || 15, 'minutes')) {
      times.push(moment(now));
    }
    return times.map((time) => {
      return (
        <div className={b('hour')} key={time.format('HH:mm')}>
          <span className={b('hour__text')}>{time.format('HH:mm')}</span>
        </div>
      )
    })
  }

  const renderBackground = () => {
    const zoomTime = scheduleStore.zoom;
    const countRows = (MAX_HOUR - MIN_HOUR) * (60 / zoomTime);

    const renderRows: any[] = [];
    for (let i = 0; i <= countRows; i++) {
      renderRows.push(
        <div className={b('presentation__background__row')} key={`row-${i}`}/>
      )
    }
    return renderRows;
  }

  return (
    <div className={`${b()} ${b('container')}`}>
      {nowTime && <div className={b('time', ['now'])} style={styleNowTime}/>}

      <div className={b('hours')}>
        {renderHours()}
      </div>
      <div className={`${b()} ${b('presentation')}`}>
        <div className={b('presentation__background')}>
          {renderBackground()}
        </div>

        {isWeek ? <Week /> : <Days/>}


      </div>
      {/*{nowTime && <div className={b('time', ['now'])} style={styleNowTime}/>}*/}
    </div>
  )
}

export default React.memo(Schedule);