import { IDoctorInfoWithAvailability } from '$/models';
import { AccordionButton, AccordionItem, AccordionPanel } from '@reach/accordion';
import { ComboboxOption } from '@reach/combobox';
import bem from 'bem-ts';
import React, { useState } from 'react';

interface IGroupProps {
  checked: IDoctorInfoWithAvailability[];
  onToggleCheck: (action: 'remove' | 'add', participant: IDoctorInfoWithAvailability) => void;
  onSelectOne: (participant: IDoctorInfoWithAvailability) => void;
  participants: any;
  specProfession: any;
  multipleSelect?: boolean;
  renderOption: (participant: any, showRemoveButton: boolean, showFlagOfChecked: boolean) => JSX.Element;
}

const bGroupButton = new bem('group__button', {strict: false})

const Group: React.FunctionComponent<IGroupProps> = props => {
  const [checked, setChecked] = useState([]);

  const toggleCheck = (participant: any) => {
    if (!checked.find((sp) => sp.sithsid === participant.sithsid)) {
      props.onToggleCheck('add', participant)
    } else {
      setChecked(checked.filter(cp => !(cp.sithsid === participant.sithsid)))
      props.onToggleCheck('remove', participant)
    }
  }

  const addSelected = (participant: any) => {
    setChecked([participant]);
    props.onToggleCheck('add', participant)
    props.onSelectOne(participant)
  }

  const hasCheckedEveryParticipants = props.participants
    .every(partisipantOfGroup => props.checked.includes(partisipantOfGroup));

  return (
    <>
      {Array.isArray(props.participants) && props.participants.map((participant) => {
        // const needDisplayCheckedFlag = !!props.checked.find(cp => cp.sithsid === participant.sithsid);
        return (
          <ComboboxOption
            key={participant.sithsid}
            value={`${participant.personalInfo.firstName} ${participant.personalInfo.lastName}`}
            onClick={(e) => {
              if (props.multipleSelect) {
                toggleCheck(participant);
                e.preventDefault();
                e.stopPropagation();
              } else {
                addSelected(participant)
              }
            }}
          >
            {props.renderOption(participant, false, true)}
          </ComboboxOption>
        );
      })}
      <AccordionItem key={props.specProfession.occupation} className={'group'}>

        {/*Group button:*/}
        <AccordionButton className={bGroupButton()}>
          <div
            className={`${bGroupButton('photo', ['empty'])} ${hasCheckedEveryParticipants ? bGroupButton('photo', ['checked']) : ''}`}>

            {hasCheckedEveryParticipants ? <i className={'far fa-check'}/> : props.specProfession.occupation[0]}
          </div>
          <div className={bGroupButton('info')}>
              <span className={bGroupButton('info__name')}>
                  {props.specProfession.occupation}
                {/*{*/}
                {/*    !isAvailable &&*/}
                {/*    <span className="busyMarker">(Busy)</span>*/}
                {/*}*/}
              </span>
            <span className={bGroupButton('info__speciality')}>
                {props.participants.length} specialists
              </span>
          </div>
          <i className={`icon-open-group far fa-chevron-up`}/>
        </AccordionButton>

        {/*<AccordionButton>{profession.specialty.descriptions[0].term}</AccordionButton>*/}
        {/*<AccordionButton>{profession.specialty.descriptions[0].typeId}</AccordionButton>*/}
        <AccordionPanel className={'group__container participants__container'}>
          {Array.isArray(props.participants) && props.participants.map((participant) => {
            // const needDisplayCheckedFlag = !!props.checked.find(cp => cp.sithsid === participant.sithsid);
            return (
              <ComboboxOption
                key={participant.sithsid}
                value={`${participant.personalInfo.firstName} ${participant.personalInfo.lastName}`}
                onClick={(e) => {
                  if (props.multipleSelect) {
                    toggleCheck(participant);
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    addSelected(participant)
                  }
                }}
              >
                {props.renderOption(participant, false, true)}
              </ComboboxOption>
            );
          })}
        </AccordionPanel>
      </AccordionItem>
    </>
  )
}

export default Group;
