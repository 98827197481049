import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import { observer } from "mobx-react";
import axios from "axios";
import bem from "bem-ts";
import { useDropzone } from "react-dropzone";

import NavHeader from "$/components/navHeader";
import TitlePage from "$/components/common/titlePage";
import ErrorBoundary from "$/utils/ErrorBoundary/ErrorBoundary";
import { IAppointment, TimeSlotsStatusEnum } from "$/models";
import "./index.scss";
import Chat from "$/components/chat";
import Button from "$/components/common/button";
import { ChatStore } from "$/contexts/chat";
import { GlobalContext } from "$/contexts/global";
import { Appointment } from "$/stores/appointment";
import GlobalStore from "$/stores/global";
import AppointmentContent from "./content/appointment";
import LoaderColored from "$/components/loaderColored";

const b = new bem("pageAppointment", { strict: false });

const AppointmentPage = (props) => {
  const globalStore = useContext<GlobalStore>(GlobalContext);

  const [currentAppointment, setCurrentAppointment] = useState<Appointment>(
    null
  );

  const { appointmentId } = props;

  useEffect(() => {
    globalStore.http.get(`/doctor/appointment/${appointmentId}`).then((res) => {
      const store = new Appointment(globalStore, res.data as IAppointment);
      setCurrentAppointment(store);
    });
  }, []);

  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [view, setView] = useState<"chat" | "appointment">("appointment");
  const [doctorNotes, setDoctorNotes] = useState({});
  const [chatState] = useContext(ChatStore);
  const [isLoadingRestore, setIsLoadingRestore] = useState(false);

  useEffect(() => {
    !!currentAppointment &&
      setDoctorNotes({ ...doctorNotes, note: currentAppointment.notes });
  }, [currentAppointment]);

  const patient = currentAppointment?.patients[0];
  const titleChat = patient && (
    <>
      {patient?.personalInfo?.firstName} {patient?.personalInfo?.lastName}
    </>
  );

  const content =
    view === "appointment" ? (
      currentAppointment && (
        <AppointmentContent
          appointmentStore={currentAppointment}
          changeView={() => setView("chat")}
        />
      )
    ) : (
      <>
        <div className={b("header")}>
          <div
            onClick={() => {
              setView("appointment");
            }}
            className={b("header__backButton")}
          >
            <i className="fas fa-chevron-left" />
          </div>
          <TitlePage className={b("header__title")}>
            {titleChat || "Chat"}
          </TitlePage>
        </div>
        <div className={b("content")}>
          <Chat
            chat={{
              ...currentAppointment?.chat,
              appointment: currentAppointment,
            }}
          />
        </div>
      </>
    );

  return (
    <ErrorBoundary>
      <div className={`page ${b()} ${b("view", [view])}`}>
        <NavHeader />
        {(!!currentAppointment && content) || (
          <LoaderColored
            style={{
              alignSelf: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default observer(AppointmentPage);
