import { Appointment } from '$/stores/appointment';
import React from "react";
import bem from "bem-ts";

import ParticipantSelector from "$/components/participantSelector";
import AppointmentDateSelector from "$/components/appointmentDateSelector";
import AppointmentComments from "$/components/appointmentComments";
import AppointmentTitle from "$/components/appointmentTitle";
import LocationSelector from "$/components/locationSelector";

const b = new bem("appointmentForm", { strict: false });

const FormAdmin: React.FC<{appointmentStore: Appointment, disableAutoSetData?: boolean}> = ({ appointmentStore, ...props }) => {
  return (
    <div className={b("fieldset__container")}>
      <AppointmentTitle appointmentStore={appointmentStore} />
      <ParticipantSelector appointmentStore={appointmentStore} />
      <AppointmentDateSelector
        appointmentStore={appointmentStore}
        autoSet={!props.disableAutoSetData}
      />
      <LocationSelector appointmentStore={appointmentStore} />
      <AppointmentComments appointmentStore={appointmentStore} />
    </div>
  );
};

export default FormAdmin;
