import React from "react";
import bem from "bem-ts";
import { observer } from "mobx-react";

import PatientSelector from "$/components/patientSelector";
import SymptomsSelector from "$/components/symptomsSelector";
import AppointmentTypeSwitcher from "$/components/appointmentTypeSwitcher";
import { AppointmentTypeEnum, IPatient } from "$/models";
import TogglerNewVisit from "$/components/togglerNewVisit";
import ParticipantSelector from "$/components/participantSelector";
import AppointmentDateSelector from "$/components/appointmentDateSelector";
import AppointmentComments from "$/components/appointmentComments";
import PreparationSelector from "$/components/preparationSelector";
import LocationSelector from "$/components/locationSelector";
import { Appointment } from "$/stores/appointment";

const b = new bem("appointmentForm", { strict: false });

const FormPatient: React.FC<{ appointmentStore: Appointment, isEdit?: boolean }> = ({
  appointmentStore,
  ...props
}) => {
  return (
    <div className={b("fieldset__container")}>
      <PatientSelector appointmentStore={appointmentStore} />
      <SymptomsSelector appointmentStore={appointmentStore} isReason={true} />
      <AppointmentTypeSwitcher appointmentStore={appointmentStore} />
      {[AppointmentTypeEnum[0], AppointmentTypeEnum[1]].includes(
        appointmentStore.appointmentType
      ) && <TogglerNewVisit appointmentStore={appointmentStore} />}
      <ParticipantSelector appointmentStore={appointmentStore} />

      <AppointmentDateSelector
        appointmentStore={appointmentStore}
        autoSet={!props.isEdit}
      />
      <LocationSelector appointmentStore={appointmentStore} />
      <PreparationSelector appointmentStore={appointmentStore} />
      <AppointmentComments appointmentStore={appointmentStore} />
    </div>
  );
};

export default observer(FormPatient);
