import React, { createContext, useReducer } from "react";
import { CurrentUserProvider } from "$/contexts/currentUser";
import WebSocketStoreProvider from "$/contexts/webSocket";
import { ScheduleProvider } from "$/contexts/schedule";
import ChatProvider from "$/contexts/chat";
import NotificationsProvider from "$/contexts/notifications";
import GlobalProvider from "$/contexts/global";

const initialState = {
  photos: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_PHOTO":
      const { key } = action.payload;
      const { photo } = action.payload;
      const photos = { ...state.photos };
      photos[key] = photo;
      return {
        ...state,
        photos,
      };

    default:
      return { ...state };
  }
};

export const GeneralStore = createContext();

const GeneralProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);

  return (
    <GeneralStore.Provider value={value}>
      <CurrentUserProvider>
        <GlobalProvider>
          <WebSocketStoreProvider>
            <NotificationsProvider>
              <ScheduleProvider>
                <ChatProvider>{children}</ChatProvider>
              </ScheduleProvider>
            </NotificationsProvider>
          </WebSocketStoreProvider>
        </GlobalProvider>
      </CurrentUserProvider>
    </GeneralStore.Provider>
  );
};

export default GeneralProvider;
