export enum AppointmentTypeEnum {
  PHYSICAL,
  VIDEO,
  CALL,
}

export enum AppointmentStateEnum {
  BOOKED,
  CHECKED_IN,
  PAYED,
  DIAGNOSED,
  CANCELED,
  COMPLETED,
}

export enum TimeSlotsStatusEnum {
  WORK_TIME = 'WORK_TIME',
  PATIENT_APPOINTMENT = 'PATIENT_APPOINTMENT',
  ADMIN_APPOINTMENT = 'ADMIN_APPOINTMENT',
  VACATION = 'VACATION',
  LUNCHTIME = 'LUNCHTIME',
}

export enum OccupationEnum {
  HEAD_OF_DEPARTMENT = 'HEAD_OF_DEPARTMENT',
  DOCTOR = 'DOCTOR',
  NURSE = 'NURSE',
}

export interface IProfession {
  occupation: OccupationEnum;
  speciality: ISpeciality;
}

export interface ISpeciality {
  id: string;
  descriptions: ISpecialityDescription[];
}

export interface ISpecialityDescription {
  id: string;
  lang: string;
  term: string;
  typeId: string;
}

export interface IRoom {
  roomNumber: number;
  roomTitle?: string;
}

export interface IPersonalInfo {
  firstName: string;
  lastName: string;
  photo?: string; // in base64
  dateOfBirth: string;
  administrativeInfo: {
    personalId: string;
    familyStatus: string;
    [key: string]: any;
  };
}

export interface IPatient {
  personalInfo: IPersonalInfo;
  bankId: string;
}

export interface IPatientWithAvailability extends IPatient {
  availability: {
    isAvailable: boolean,
  }
}

export interface IDoctorInfo {
  sithsid: string;
  personalInfo: IPersonalInfo;
  profession: IProfession;
  clinic: any;
}

export interface IDoctorInfoWithAvailability extends IDoctorInfo {
  availability: {
    isAvailable: boolean,
  }
}

export interface IParticipantColumn {
  sithsid: string;
  title: string;
}

export interface IAppointmentLight {
  appointmentState: AppointmentStateEnum;
  appointmentTopic: string;
  appointmentType: AppointmentTypeEnum;
  endDate: string;
  id: string;
  isFirstVisit: boolean;
  startDate: string;
  status: TimeSlotsStatusEnum;
}

export interface IAppointment {
  id: string;
  appointmentState?: AppointmentStateEnum;
  appointmentTopic?: string;
  appointmentType?: AppointmentTypeEnum;
  description?: string;
  doctor: IDoctorInfo;
  place?: IRoom;
  startDate: string;
  endDate: string;
  events: {
    reasons: string[];
    symptoms: {
      id: string;
      descriptions: {
        id: string;
        [key: string]: any;
      };
    };
    observations: any;
    diagnosis: any;
    medicines: any;
  };
  isFirstVisit?: boolean;
  notes?: string;
  participants?: IDoctorInfo[];
  patient?: IPatient[];
  status: TimeSlotsStatusEnum;
  preparations?: string[];
  stateHistory: string[];
  chat?: any;
  patientChildInfo?: IPersonalInfo;
}

export interface IChat {
  chatId: string;
  date: string;
  author?: string;
  authorId?: string;
  topic?: string;
  messages: IChatMessage[];
  state?: string;
  unreadMessages?: {
    [key: string]: number;
  };
}

export interface IChatMessage {
  doctor: IDoctorInfo;
  patient: IPatient;
  message: string;
  patientMessage: boolean;
  dateTime?: string;
}

export type IAppointmentType = {
  id?: string;
  patients: IPatient[];
  participants: IDoctorInfo[];
  events: {
    reasons: [];
    symptoms: [];
    observations: [];
    diagnosis: [];
    medicines: [];
  };

  preparations: any[];
  appointmentType: string;

  appointmentState: string;
  isFirstVisit: boolean;
  startDate: string;
  endDate: string;
  status: string;
  place?: IRoom;

  appointmentTopic?: string;

  description?: string;

  patientChildInfo: any;
};
