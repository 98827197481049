import React from "react";
import bem from "bem-ts";
import classNames from "classnames";

const b = new bem("PhotoIcon", { strict: false });
import "./index.scss";
import { IDoctorInfo, IPatient } from "$/models";

interface IPhotoIconProps {
  person: IDoctorInfo | IPatient;
  className?: string;
}

const PhotoIcon: React.FC<IPhotoIconProps> = ({ person, ...props }) => {
  const id = person.bankId || person.sithsid;
  const isDoctor = !!person.sithsid;

  return (
    (!!person?.personalInfo?.photo && (
      <img
        className={classNames(b(), props.className)}
        src={`${process.env.APP_API_DOMAIN}${
          process.env.APP_API_BASE_PATH
        }/admin/${isDoctor ? "doctor" : "patient"}/${id}/photo`}
        height="40"
        width="40"
      />
    )) || (
      <svg
        height="40"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(b(), props.className)}
      >
        <g>
          <title>background</title>
          <rect
            fill="none"
            id="canvas_background"
            height="42"
            width="42"
            y="-1"
            x="-1"
          />
          <g
            display="none"
            overflow="visible"
            y="0"
            x="0"
            height="100%"
            width="100%"
            id="canvasGrid"
          >
            <rect
              fill="url(#gridpattern)"
              strokeWidth="0"
              y="0"
              x="0"
              height="100%"
              width="100%"
            />
          </g>
        </g>
        <g>
          <title>Layer 1</title>
          <ellipse
            ry="20"
            rx="20"
            id="svg_2"
            cy="20"
            cx="20"
            fill={
              isDoctor ? "var(--color-green-basic)" : "var(--color-blue-basic)"
            }
          />

          <text
            textAnchor="start"
            fontFamily="Helvetica, Arial, sans-serif"
            fontSize="16"
            fontWeight={"bold"}
            id="svg_4"
            x="50%" 
            y="52%" 
            dominantBaseline="middle" 
            textAnchor="middle"
            strokeWidth="0"
            fill="#ffffff"
          >
            {person.personalInfo.firstName[0]}
            {person.personalInfo.lastName[0]}
          </text>
        </g>
      </svg>
    )
  );
};

export default PhotoIcon;
