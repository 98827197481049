import * as React from "react";
import ErrorBoundary from "$/utils/ErrorBoundary/ErrorBoundary";
import NavHeader from "$/components/navHeader";
import { RouteComponentProps } from "@reach/router";

const Index: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <ErrorBoundary>
      <NavHeader />
      <div className="page pageProfile"></div>
    </ErrorBoundary>
  );
};
export default Index;
