import React, { useEffect, useState } from "react";
import { Combobox, ComboboxInput } from "@reach/combobox";
import { TimeSlotsStatusEnum } from "$/models";
import "./index.scss";
import bem from "bem-ts";

const WIDTH = 350;
const b = new bem("appointmentTitle", { strict: false });

const AppointmentTitle = ({ appointmentStore, ...props }) => {
  const [term, setTerm] = useState(appointmentStore.appointmentTopic || "");

  const handleChange = (event) => {
    setTerm(event.target.value);
  };

  useEffect(() => {
    appointmentStore.setField("appointmentTopic", term);
  }, [term]);

  const isPatient =
    appointmentStore.status === TimeSlotsStatusEnum.PATIENT_APPOINTMENT;
  const readOnly = false;
  const isDisplayed = true;

  return (
    <>
      {isDisplayed && (
        <div className={b("container")}>
          <div className={b("prefix")}>
            <i className={"far fa-text"} />
          </div>
          {readOnly ? (
            <span className={b("text")}>Artheritis group meeting</span>
          ) : (
            <Combobox style={{ width: WIDTH }}>
              <ComboboxInput
                className={b("input")}
                style={{ width: WIDTH - 60 }}
                placeholder={"Title"}
                onChange={handleChange}
                value={term}
              />
              {term.length > 0 && (
                <a
                  className={b("clear-input")}
                  onClick={() => {
                    setTerm("");
                  }}
                >
                  <i className={"far fa-times"} />
                </a>
              )}
            </Combobox>
          )}
        </div>
      )}
    </>
  );
};

export default AppointmentTitle;
