import React, {createContext, useReducer} from 'react'

const initialState = {
  isLoading: false,
  isLoggedIn: null,
  isError: false,
  currentUser: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return {...state, isLoading: true}
    case 'ERROR':
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        isError: true
      }
    case 'SET_AUTHORIZED':
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        isError: false,
        currentUser: Array.isArray(action.payload) ? action.payload[0] : action.payload
      }
    case 'SET_UNAUTHORIZED':
      return {
        ...state,
        isLoggedIn: false,
        currentUser: null
      }
    default:
      return state
  }
}

export const CurrentUserContext = createContext()

export const CurrentUserProvider = ({children}) => {
  const value = useReducer(reducer, initialState)

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  )
}
