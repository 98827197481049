import React, { useContext, useMemo, useRef } from "react";
import { IAppointment, TimeSlotsStatusEnum, IDoctorInfo } from "$/models";
import { ScheduleContext } from "$/contexts/schedule";
import moment from "moment";
import Appointment from "$/components/schedule/appointment";
import bem from "bem-ts";
import { CurrentUserContext } from "$/contexts/currentUser";
import { useRect } from "@reach/rect";
import FreeSlot from "$/components/schedule/column/freeSlot";
import { sortAppointmentsAsc, sortAppointmentsDesc } from '../../../utils/sort-appointments';

export interface IColumn {
  keyColumn: string;
  title: string;
  appointments: IAppointment[];
  date: string;
  isWeek?: boolean;
  participant?: IDoctorInfo;
}

const b = new bem("Schedule", { strict: false });

const Column: React.FC<IColumn> = (props) => {
  const [scheduleState, dispatchSchedule] = useContext(ScheduleContext);
  const [userState] = useContext(CurrentUserContext);
  const MAX_HOUR = scheduleState.maxHour;
  const MIN_HOUR = scheduleState.minHour;
  const during = scheduleState.zoom;
  const isWeek = scheduleState.view === "week";
  const refColumn = useRef();
  const rectColumn = useRect(refColumn);
  const isCompact = rectColumn?.width < 400;

  const renderCloseSlots = (closeSlots: any) => {
    if (closeSlots.length) {
      return closeSlots.map((slot, index: number) => {
        const { startTime, endTime } = slot;
        const countMinutesAfter = moment
          .utc(startTime)
          .local()
          .diff(moment(startTime).hour(MIN_HOUR).minute(0), "minutes");

        // height row - 40px, plus border-top size - 1 px;
        const sizeRow = 41;
        const countMinutes = moment(endTime).diff(moment(startTime), "minutes");

        const height = (sizeRow / scheduleState.zoom) * countMinutes;
        const top = (sizeRow / scheduleState.zoom) * countMinutesAfter;
        const style = {
          top: `${top}px`,
          minHeight: `${height}px`,
        };
        if (top < 0) {
          return <></>;
        }
        return (
          <div
            data-time={`${startTime} - ${endTime} (${countMinutesAfter})`}
            className={b("presentation__closeSlots")}
            style={style}
            key={moment(startTime).format("YYYY-DD-mm.HH:MM:ss") + `-${index}`}
          />
        );
      });
    }
  };

  const countRows = (MAX_HOUR - MIN_HOUR) * (60 / during);
  const heightColumn = countRows * 41;

  const closeSlots = useMemo(() => {
    return calculateCloseSlots(props.appointments, MIN_HOUR, MAX_HOUR);
  }, [props.appointments, scheduleState.zoom]);

  const removeColumn = () => {
    dispatchSchedule({
      type: "REMOVE_COLUMN",
      payload: props.keyColumn,
    });
  };

  const types = useMemo(() => {
    const results = [];
    sortAppointmentsDesc(props.appointments).forEach((appointment) => {
      if (appointment.appointmentType) {
        const time = moment(appointment.endDate).diff(
          moment(appointment.startDate),
          "minutes"
        );
        const height = (time / scheduleState.zoom) * 41;
        const countMinutesBefore = moment
          .utc(appointment.startDate)
          .local()
          .diff(
            moment(appointment.startDate).local().hour(MIN_HOUR).minute(0),
            "minutes"
          );

        const top = (countMinutesBefore * 41) / scheduleState.zoom;
        const appointmentType = appointment.status === "ADMIN_APPOINTMENT" ? null : appointment.appointmentType;

        results.push(
          <div
            key={`types-appointment-${appointment.id}`}
            data-time={`${moment(appointment.startDate).format(
              "YYYY-MM-DDTHH:mm:00"
            )} - ${moment(appointment.endDate).format("YYYY-MM-DDTHH:mm:00")}`}
            style={{ height: `${height}px`, top: `${top}px` }}
            className={`Schedule__presentation__column__type Schedule__presentation__column__type--${appointmentType?.toLowerCase()}`}
          />
        );
      }
    });
    return results;
  }, [props.appointments, scheduleState.zoom]);

  const freeSlots = useMemo(() => {
    const results = [];
    const timeSteps = getStepsByDuring(scheduleState.zoom);
    const appointmentsSorted = [...(props.appointments || [])].sort((a, b) => {
      if (a.startDate < b.startDate) return -1;
      else if (a.startDate > b.startDate) return 1;
      else return 0;
    });

    const startAppointment = {
      status: "WORK_TIME",
      appointmentType: "PHYSICAL",
      startDate: moment
        .utc(props.date)
        .local()
        .hour(scheduleState.minHour)
        .minute(0)
        .utc()
        .format("YYYY-MM-DDTHH:00:00"),
      endDate:
        (!!appointmentsSorted?.length &&
          moment
            .utc(appointmentsSorted[0].startDate)
            .format("YYYY-MM-DDTHH:00:00")) ||
        moment
          .utc(props.date)
          .local()
          .hour(scheduleState.maxHour)
          .minute(0)
          .utc()
          .format("YYYY-MM-DDTHH:00:00"),
    };

    const endAppointment = {
      status: "WORK_TIME",
      appointmentType: "PHYSICAL",
      startDate:
        (!!appointmentsSorted?.length &&
          moment
            .utc(appointmentsSorted[appointmentsSorted.length - 1].endDate)
            .format("YYYY-MM-DDTHH:00:00")) ||
        moment
          .utc(props.date)
          .local()
          .hour(scheduleState.maxHour)
          .minute(0)
          .utc()
          .format("YYYY-MM-DDTHH:00:00"),
      endDate: moment
        .utc(props.date)
        .local()
        .hour(scheduleState.maxHour)
        .minute(0)
        .utc()
        .format("YYYY-MM-DDTHH:00:00"),
    };

    let appointmentsInColumn = [
      startAppointment,
      ...appointmentsSorted,
      endAppointment,
    ].sort((a, b) => {
      if (a.startDate < b.startDate) return -1;
      else if (a.startDate > b.startDate) return 1;
      else return 0;
    });

    for (let i = 1; i < appointmentsInColumn.length; i++) {
      const app = appointmentsInColumn[i];
      const nextApp = appointmentsInColumn[i + 1] || {
        startDate: moment
          .utc(props.date)
          .local()
          .hour(scheduleState.maxHour)
          .minute(0)
          .utc()
          .format("YYYY-MM-DDTHH:00:00"),
      };
      if (nextApp && app.endDate !== nextApp.startDate) {
        const afterIndex = i + 1;
        appointmentsInColumn = [
          ...appointmentsInColumn.slice(0, afterIndex),
          {
            status: "WORK_TIME",
            appointmentType: "PHYSICAL",
            startDate: app.endDate,
            endDate: nextApp.startDate,
          },
          ...appointmentsInColumn.slice(afterIndex),
        ];
        i++;
      }
    }
    appointmentsInColumn.forEach((appointment, index) => {
      if (appointment.status === "WORK_TIME") {
        const condition = (i: moment.Moment) => {
          return (
            i.format("YYYY-MM-DDTHH:mm:SS") <
            moment.utc(appointment.endDate).format("YYYY-MM-DDTHH:mm:SS")
          );
        };

        const next = (i) => {
          if (!timeSteps.includes(i.minutes())) {
            const findMinute =
              timeSteps.find((minute: number, index, arr) => {
                return (
                  !!arr[index - 1] &&
                  i.minutes() < minute &&
                  i.minutes() > arr[index - 1]
                );
              }) || 0;

            i.minutes(findMinute);
          } else {
            i.add(scheduleState.zoom, "minutes");
          }
        };

        for (let i = moment.utc(appointment.startDate); condition(i); next(i)) {
          const appointmentForFreeSlot = {
            ...appointment,
            appointmentState: "BOOKED",
            participants: [props.participant],
            id: null,
            startDate: moment(i).format("YYYY-MM-DDTHH:mm:00"),
            endDate: moment(
              moment(i).add(scheduleState.zoom, "minutes")
            ).format("YYYY-MM-DDTHH:mm:00"),
          };

          if (
            timeSteps.includes(i.minutes()) &&
            moment.utc().isBefore(moment.utc(appointmentForFreeSlot.startDate))
          ) {
            const height = 41;
            const countMinutesBefore = moment(
              moment.utc(i).local().format("YYYY-MM-DDTHH:mm")
            ).diff(
              moment(
                moment(i).hour(MIN_HOUR).minute(0).format("YYYY-MM-DDTHH:mm")
              ),
              "minutes"
            );
            const top = (41 / scheduleState.zoom) * countMinutesBefore;
            results.push(
              <FreeSlot
                className={`Schedule__presentation__column__freeSlot appointment-type-${appointmentForFreeSlot.appointmentType}`}
                key={`column-${props.keyColumn}-freeSlot-${index}-${moment(
                  appointmentForFreeSlot.startDate
                ).format("YYYY-MM-DDTHH:mm:00")}-${moment(
                  appointmentForFreeSlot.endDate
                ).format("YYYY-MM-DDTHH:mm:00")}-t-${
                  appointmentForFreeSlot.appointmentType
                }`}
                height={height}
                top={top}
                dateTime={`${moment(i)
                  .local()
                  .format("YYYY-MM-DDTHH:mm:SS")} ${countMinutesBefore}`}
                appointment={{
                  ...appointmentForFreeSlot,
                  status: "PATIENT_APPOINTMENT",
                }}
              />
            );
          }
        }
      }
    });
    return results;
  }, [props.appointments, scheduleState.zoom]);
  return (
    <div
      className={b("presentation__column")}
      ref={refColumn}
      key={`presentation__column-${props.keyColumn}`}
      style={{ height: `${heightColumn}px` }}
    >
      <span className={b("presentation__column__title")}>
        {props.title}
        {!isWeek && userState.currentUser.sithsid !== props.keyColumn && (
          <i className={"icon far fa-times"} onClick={removeColumn} />
        )}
      </span>
      {types}
      <div
        className={`${b("presentation__column__container")}`}
        style={{ height: `${heightColumn}px` }}
      >
        {renderCloseSlots(closeSlots)}
        {freeSlots}
        {props.appointments.map((appointment) => {
          if (appointment.status !== TimeSlotsStatusEnum.WORK_TIME) {
            const startDate = moment.utc(appointment.startDate).local();
            const endDate = moment.utc(appointment.endDate).local();
            const countMinutesAfter = moment(endDate)
              .hour(MAX_HOUR)
              .minute(0)
              .diff(moment(endDate), "minutes");
            const countMinutesBefore = moment(endDate).diff(
              moment(endDate).hour(MIN_HOUR).minute(0),
              "minutes"
            );

            // height row - 40px, plus border-top size - 1 px;
            const sizeRow = 41;
            const countMinutes = moment(endDate).diff(
              moment(startDate),
              "minutes"
            );
            const top = (sizeRow / during) * countMinutesBefore;
            const height = (sizeRow / during) * countMinutes;
            const bottom = (sizeRow / during) * countMinutesAfter;
            const style = {
              bottom: `${bottom}px`,
              minHeight: `${height}px`,
            };
            return (
              <Appointment
                data={appointment}
                key={`appointment-${appointment.id}`}
                autoTop={true}
                sizeAfter={top}
                className={b("presentation__appointment")}
                style={style}
                isCompact={isCompact}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default React.memo(Column);

function calculateCloseSlots(
  appointments: IAppointment[],
  minHour: number,
  maxHour: number
) {
  const sortedAppointments = sortAppointmentsAsc(appointments).filter(a => a.appointmentState !== 'CANCELED' );

  const closeSlots = [];

  if (sortedAppointments.length) {
    let startTime = moment
      .utc(sortedAppointments[0].startDate)
      .local()
      .hour(minHour)
      .minute(0)
      .utc();

    sortedAppointments.forEach(
      (appointment: IAppointment, index: number, thisArr) => {
        let sizeCloseSlot = moment
          .utc(appointment.startDate)
          .diff(startTime, "minutes");

        if (sizeCloseSlot) {
          closeSlots.push({
            startTime: startTime.format("YYYY-MM-DDTHH:mm:00"),
            endTime: moment
              .utc(appointment.startDate)
              .format("YYYY-MM-DDTHH:mm:00"),
          });
        }

        if (index === thisArr.length - 1) {
          const endTime = moment
            .utc(appointment.endDate)
            .local()
            .hour(maxHour)
            .minute(0)
            .utc();

          sizeCloseSlot = endTime.diff(
            moment.utc(appointment.endDate),
            "minutes"
          );
          if (sizeCloseSlot) {
            closeSlots.push({
              startTime: moment
                .utc(appointment.endDate)
                .format("YYYY-MM-DDTHH:mm:00"),
              endTime: moment(appointment.endDate)
                .hour(maxHour)
                .minute(0)
                .utc()
                .format("YYYY-MM-DDTHH:mm:00"),
            });
          }
        }

        startTime = moment.utc(appointment.endDate);
      }
    );
  } else {
    closeSlots.push({
      startTime: moment()
        .hour(minHour)
        .minute(0)
        .utc()
        .format("YYYY-MM-DDTHH:mm:00"),
      endTime: moment()
        .hour(maxHour)
        .minute(0)
        .utc()
        .format("YYYY-MM-DDTHH:mm:00"),
    });
  }
  return closeSlots;
}

const getStepsByDuring = (during: number): number[] => {
  const result = [0];
  const hour = 60;
  const countSteps = hour / during;
  for (let i = 1; i < countSteps; i++) {
    result.push(i * during);
  }
  return result;
};
