import React, { useContext, useMemo, useState } from "react";
import "./index.scss";
import { AppointmentTypeEnum, IAppointment } from "$/models";

import bem from "bem-ts";
import moment from "moment";
import ScheduleEditingSlot from "$/components/scheduleEditingSlot";
import { ScheduleContext } from "$/contexts/schedule";
import FreeSlot from "$/components/scheduleEditingColumn/freeSlot";

const b = bem("ScheduleEditing-Column", { strict: false });

interface IColumnProps {
  title: string;
  slots: IAppointment[];
  height: number;
  onCreateFreeSlot: () => void;
  onEditAppointment: (a: IAppointment) => void;
  date?: string;
}

const Column: React.FC<IColumnProps> = (props) => {
  const [scheduleStore] = useContext(ScheduleContext);
  let minutesInDay = 0;

  const onAppointmentChange = props.onEditAppointment;

  const renderSlots = useMemo(() => {
    return (props.slots || []).map(
      (appointment: IAppointment, indexSlot: number) => {
        minutesInDay += moment(appointment.endDate).diff(
          moment(appointment.startDate),
          "minutes"
        );

        let icon = <i className="far fa-shoe-prints" />;
        const type = appointment.appointmentType;
        switch (type) {
          case AppointmentTypeEnum[0]:
            icon = <i className="far fa-shoe-prints" />;
            break;
          case AppointmentTypeEnum[2]:
            icon = <i className="far fa-phone-alt" />;
            break;
          case AppointmentTypeEnum[1]:
            icon = <i className="far fa-video" />;
            break;
        }

        return (
          <ScheduleEditingSlot
            appointment={appointment}
            onChange={onAppointmentChange}
            key={`slot-${indexSlot}-${moment(appointment.startDate).format(
              "YYYY-MM-DDTHH:mm:00"
            )}`}
          />
        );
      }
    );
  }, [props.slots, scheduleStore.zoom]);

  const renderFreeSlots = useMemo((): any[] => {
    const result: any[] = [];

    if (props.slots?.length || props.date) {
      let startTime = moment
        .utc( props.slots && props.slots[0] && props.slots[0].startDate || props.date)
        .local()
        .hour(scheduleStore.minHour)
        .minutes(0)
        .seconds(0);

      const endTime = moment
        .utc(props.slots && props.slots[0] && props.slots[0].startDate || props.date)
        .local()
        .hour(scheduleStore.maxHour)
        .minutes(0)
        .seconds(0)
        .format("YYYY-MM-DDTHH:mm:00");

      const timeSteps = getStepsByDuring(scheduleStore.zoom);

      [
        ...(props.slots || []),
        {
          startDate: endTime,
        },
      ].forEach((slot: IAppointment) => {
        const isInSchedule = startTime.isBefore(endTime);

        if (isInSchedule) {
          const condition = (i) => {
            return (
              i.format("YYYY-MM-DDTHH:mm:SS") <
              moment.utc(slot.startDate).local().format("YYYY-MM-DDTHH:mm:SS")
            );
          };

          const next = (i) => {
            if (!timeSteps.includes(i.minutes())) {
              const findMinute =
                timeSteps.find((minute: number, index, arr) => {
                  return (
                    !!arr[index - 1] &&
                    i.minutes() < minute &&
                    i.minutes() > arr[index - 1]
                  );
                }) || 0;

              i.minutes(findMinute);
            } else {
              i.add(scheduleStore.zoom, "minutes");
            }
          };
          !timeSteps.includes(startTime.minutes()) && next(startTime);

          const diff = moment
            .utc(slot.startDate)
            .local()
            .diff(moment(startTime), "minutes");

          if (diff >= scheduleStore.zoom) {
            for (let i = startTime; condition(i); next(i)) {
              const dataForSlot = {
                minTime: moment(startTime).utc().format("YYYY-MM-DDTHH:mm:00"),
                maxTime: moment
                  .utc(slot.startDate)
                  .format("YYYY-MM-DDTHH:mm:SS"),
                startDate: moment(i).utc().format("YYYY-MM-DDTHH:mm:00"),
                endDate: moment(i)
                  .utc()
                  .add(scheduleStore.zoom, "minutes")
                  .format("YYYY-MM-DDTHH:mm:00"),
                appointmentType: "PHYSICAL",
              };
              result.push(dataForSlot);
            }
          }
        }
        startTime = moment.utc(slot.endDate).local();
      });
    }
    return result;
  }, [props.slots, scheduleStore.zoom, props.date]);

  return (
    <div className={b()}>
      <span className={b("title")}>
        {props.title}
        &ensp;
        <span className={b("title__time")}>
          {minutesInDay === 0 ? "0" : (minutesInDay / 60).toFixed(2)}h
        </span>
      </span>

      <div style={{ height: `${props.height}px` }} className={b("content")}>
        {renderSlots}
        {renderFreeSlots.map((slot, index) => {
          return (
            <FreeSlot
              slot={slot}
              key={`slot-${index}-${moment(slot.startDate).format(
                "YYYY-MM-DDTHH:mm:00"
              )}-${moment(slot.endDate).format("YYYY-MM-DDTHH:mm:00")}`}
              onCreateFreeSlot={props.onCreateFreeSlot}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Column;

const getStepsByDuring = (during: number): number[] => {
  const result = [0];
  const hour = 60;
  const countSteps = hour / during;
  for (let i = 1; i < countSteps; i++) {
    result.push(i * during);
  }
  return result;
};
