import Button from '$/components/common/button';
import LoaderPlain from '$/components/loaderPlain';
import useFetch from '$/hooks/useFetch';
import { Combobox, ComboboxInput } from '@reach/combobox';
import bem from 'bem-ts';
import React, { useEffect, useState } from 'react';

const b = bem('Schedule-Editing-Templates', {strict: false});

interface INewTemplatesFormProps {
  currentWeek: number;

  onCancel: () => void;
}

export const NewTemplatesForm: React.FC<INewTemplatesFormProps> =
  (props: INewTemplatesFormProps) => {
    const {currentWeek, onCancel} = props;
    const [term, setTerm] = useState('');

    const [{response, isLoading}, fetchCreateTemplate] = useFetch(
      'admin/templates'
    );

    const onClick = () => {
      fetchCreateTemplate({
        url: `/doctor/template/create/for/week`,
        params: {
          name: term?.toString(),
          weekNo: currentWeek?.toString(),
        }
      });
    };

    useEffect(() => {
      if (response) {
        onCancel();
      }
    }, [response]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTerm(e.target.value);
    };

    return (
      <>
        <div className={b('content')}>
          <div className={b('newTemplate')}>
            <h2 className={b('newTemplate__title')}>
              {`New template from Week #${currentWeek}`}
            </h2>
            <div className={b('newTemplate__field')}>
              <i className={'far fa-text'}/>
              <Combobox autoFocus className={b('newTemplate__combobox')}>
                <ComboboxInput
                  autoFocus
                  onChange={onChange}
                  placeholder={'Write template\'s title here'}
                  style={{width: `381px`}}
                  value={term}
                />
              </Combobox>
            </div>
          </div>
        </div>
        <div className={b('footer')}>
          <Button
            className={b('footer__button')}
            view={'cancel'}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button className={b('footer__button')} onClick={onClick}>
            {isLoading ? <LoaderPlain/> : 'Save'}
          </Button>
        </div>
      </>
    );
  };