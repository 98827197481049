import React, { useState, useEffect, useContext } from "react";
import Button from "$/components/common/button";
import {
  Menu,
  MenuButton,
  MenuPopover,
  MenuItem,
  MenuList,
} from "@reach/menu-button";
import { Combobox, ComboboxInput } from "@reach/combobox";
import moment from "moment";
import InputMask from "react-input-mask";

import Calendar from "$/components/common/calendar";
import useFetch from "$/hooks/useFetch";

import "./index.scss";
import LoaderPlain from "$/components/loaderPlain";
import bem from "bem-ts";
import { ScheduleContext } from "$/contexts/schedule";
import { IAppointment } from "$/models";

const b = new bem("ScheduleEditingDateSelector", { strict: false });

const ScheduleEditingDateSelector = ({ field, form, ...props }) => {
  const init = {
    startTime:
      !!form.initialValues.startDate &&
      moment.utc(form.initialValues.startDate).local().toDate(),
    endTime:
      !!form.initialValues.endDate &&
      moment.utc(form.initialValues.endDate).local().toDate(),
    during:
      !!form.initialValues.startDate &&
      !!form.initialValues.endDate &&
      moment(form.initialValues.endDate).diff(
        moment(form.initialValues.startDate),
        "minutes"
      ),
  };

  const [scheduleStore] = useContext(ScheduleContext);
  const [date, setDate] = useState(
    (props.isEdit && init?.startTime) || moment().add(1, "day").toDate()
  );
  const [during, setDuring] = useState((props.isEdit && init?.during) || 15);
  const [duringTerm, setDuringTerm] = useState(
    `${(props.isEdit && init?.during) || 15} Min`
  );
  const [range, setRange] = useState({
    startTime:
      (props.isEdit && init?.startTime) ||
      moment.utc(date).hours(scheduleStore.minHour).minute(0).local().toDate(),
    endTime:
      (props.isEdit && init?.endTime) ||
      moment
        .utc(date)
        .hours(scheduleStore.minHour)
        .minute(0)
        .local()
        .add(during, "minutes")
        .toDate(),
  });
  const [slotsForCreate, setSlotForCreate] = useState([]);

  const [{ response, isLoading }, fetchAppointments] = useFetch(
    "/doctor/appointments"
  );

  useEffect(() => {
    form.setFieldValue(
      "startDate",
      moment(range.startTime).utc().format("YYYY-MM-DDTHH:mm:00")
    );
    setRange({
      ...range,
      endTime: moment(range.startTime).add(during, "minutes").toDate(),
    });
  }, [range.startTime]);

  useEffect(() => {
    form.setFieldValue(
      "endDate",
      moment(range.endTime).utc().format("YYYY-MM-DDTHH:mm:00")
    );

    const resultDuring = moment(range.endTime).diff(
      moment(range.startTime),
      "minutes"
    );
    if (resultDuring !== during) {
      setDuring(resultDuring);
      setDuringTerm(`${resultDuring} Min`);
    }
  }, [range.endTime]);

  useEffect(() => {
    if (
      moment(range.endTime).format("HH:mm:00") !==
      moment(range.startTime).add(during, "minutes").format("HH:mm:00")
    ) {
      setRange({
        ...range,
        endTime: moment(range.startTime).add(during, "minutes").toDate(),
      });
    }
  }, [during]);

  useEffect(() => {
    if (
      !props.isEdit &&
      fetchAppointments &&
      during &&
      date &&
      moment(date).isAfter(moment())
    ) {
      const requestDate = moment(date).format("YYYY-MM-DDT");
      fetchAppointments({
        url: `/doctor/appointments/${requestDate}00:00/${requestDate}23:59`,
      });
    }
  }, [fetchAppointments]);

  useEffect(() => {
    if (fetchAppointments && during && date && moment(date).isAfter(moment())) {
      const requestDate = moment(date).format("YYYY-MM-DDT");
      fetchAppointments({
        url: `/doctor/appointments/${requestDate}00:00/${requestDate}23:59`,
      });
    }
  }, [during, date]);

  useEffect(() => {
    if (response && response.length) {
      const joinedTime = [];
      response.forEach((appointment: IAppointment) => {
        if (!joinedTime.length) {
          joinedTime.push({
            startDate: appointment.startDate,
            endDate: appointment.endDate,
          });
        } else {
          const lastTime = joinedTime[joinedTime.length - 1];
          if (lastTime.endDate === appointment.startDate) {
            lastTime.endDate = appointment.endDate;
          } else if (lastTime.endDate < appointment.startDate) {
            joinedTime.push({
              startDate: appointment.startDate,
              endDate: appointment.endDate,
            });
          }
        }
      });

      let result = [];

      const splitAndJoin = (range: { startDate: string; endDate: string }) => {
        const resultSplitAndJoin = [];
        for (
          let i = moment(range.startDate);
          i < moment(range.endDate);
          i.add(during, "minutes")
        ) {
          if (moment(i).isAfter(moment().hour(scheduleStore.maxHour))) {
            const startTime = i.toDate();
            const endTime = moment(i).add(during, "minutes").toDate();
            resultSplitAndJoin.push({ startTime, endTime });
          }
        }
        return resultSplitAndJoin;
      };

      joinedTime?.forEach((time, index, joinedTime) => {
        const scheduleStartTime = moment(time.startDate)
          .hour(scheduleStore.minHour)
          .minutes(0)
          .second(0)
          .format("YYYY-MM-DDTHH:00:00");
        const scheduleEndTime = moment(time.startDate)
          .hour(scheduleStore.maxHour)
          .minutes(0)
          .second(0)
          .format("YYYY-MM-DDTHH:00:00");

        if (index === 0) {
          if (time.startDate !== scheduleStartTime) {
            result = [
              ...result,
              ...splitAndJoin({
                startDate: scheduleStartTime,
                endDate: time.startDate,
              }),
            ];
          } else if (joinedTime.length === 1) {
            result = [
              ...result,
              ...splitAndJoin({
                startDate: time.endDate,
                endDate: scheduleEndTime,
              }),
            ];
          }
        } else {
          result = [
            ...result,
            ...splitAndJoin({
              startDate: joinedTime[index - 1].endDate,
              endDate: time.startDate,
            }),
          ];

          if (
            joinedTime.length - 1 === index &&
            time.endDate < scheduleEndTime &&
            time.endDate !== scheduleEndTime
          ) {
            result = [
              ...result,
              ...splitAndJoin({
                startDate: time.endDate,
                endDate: scheduleEndTime,
              }),
            ];
          }
        }
      });

      !props.isEdit && props.autoSet && setRange(result[0]);

      setSlotForCreate(result);
    }
  }, [response]);

  const onChangeDate = (value) => {
    setDate(value);
    const startTime = moment.utc(range.startTime)
    const endTime = moment.utc(range.endTime)
    setRange({
      startTime: moment.utc(value).hours(startTime.hours()).minute(startTime.minutes()).local().toDate(),
      endTime: moment.utc(value).hours(endTime.hours()).minute(endTime.minutes()).local().toDate(),,
    });
  };

  return (
    <div className={b()}>
      <i className={`far fa-clock ${b("prefix")}`} />
      <div className={b("wrapper")}>
        <div className={b("fields")}>
          <div className={b("fields__during")}>
            <Menu>
              <MenuButton
                className={"button button--transparent"}
                style={{ width: "20px" }}
              >
                <i className="far fa-angle-down" />
              </MenuButton>

              <MenuPopover>
                {[5, 10, 15, 20, 25, 30].map((time) => {
                  return (
                    <MenuItem
                      className={b("fields__during__list__option")}
                      key={`${time}-during`}
                      onSelect={() => {
                        setDuring(time);
                      }}
                    >
                      {`${time} min`}
                    </MenuItem>
                  );
                })}
              </MenuPopover>
            </Menu>

            <Combobox className={b("fields__during__combobox")}>
              <ComboboxInput
                onFocus={(e) => {
                  setDuringTerm(e.target.value.split(" ")[0]);
                }}
                onChange={(e) => {
                  setDuringTerm(e.target.value);
                }}
                onBlur={(e) => {
                  setDuring(e.target.value.split(" ")[0]);
                  setDuringTerm(e.target.value + " Min");
                }}
                value={duringTerm}
                style={{ width: "54px" }}
              />
            </Combobox>
          </div>

          <div className={b("fields__range")}>
            <div
              className={`button button--transparent ${b(
                "fields__range__start"
              )}`}
            >
              {false ? (
                <LoaderPlain />
              ) : (
                (()=>{
                  const onChangeUpdate = (e) => {
                    const hour = e.target.value.split(":")[0];
                    const minute = e.target.value.split(":")[1];
                    setRange({
                      startTime: moment(range.startTime)
                        .hour(hour)
                        .minute(minute)
                        .toDate(),
                      endTime: moment(range.startTime)
                        .hour(hour)
                        .minute(minute)
                        .add(during, "minutes")
                        .toDate(),
                    });
                  };
                  return <InputMask
                    mask={"99:99"}
                    сlass={b("fields__range__start__input")}
                    onChange={onChangeUpdate}
                    onKeyPress={(e)=>{
                      if(e.charCode == 13) {
                        onChangeUpdate(e);
                      }
                    }}
                    value={moment(range.startTime).format("HH:mm")}
                  />
                })()
              )}
            </div>
            –
            <div className={`${b("fields__range__end")}`}>
              {/*{isLoading ? <LoaderPlain/> : (*/}
              {/*{range && moment(range.endTime).format('HH:mm')}*/}
              {/*)}*/}
              {(()=>{
                const onChangeSetRange = (term) => {
                  const hour = term.split(":")[0];
                  const minute = term.split(":")[1];
                  const startTime = moment(range.startTime);
                  const endTime = moment(range.endTime)
                    .hour(hour)
                    .minute(minute);
                  if (
                    endTime.format("YYYY-MM-DDTHH:mm") >
                    startTime.format("YYYY-MM-DDTHH:mm")
                  ) {
                    setRange({
                      ...range,
                      endTime: endTime.toDate(),
                    });
                  }
                };
                return <TimeField
                  сlassName={b("fields__range__end__input")}
                  onKeyPress={(e)=>{
                    if(e.charCode == 13) {
                      onChangeSetRange(e.target.value);
                    }
                  }}
                  onBlur={onChangeSetRange}
                  value={moment(range.endTime).format("HH:mm")}
                />
              })()}
            </div>
          </div>

          <div className={b("fields__date")}>
            <Menu>
              <MenuButton
                type={"transparent"}
                className={`${b(
                  "fields__date__label"
                )} button button--transparent`}
              >
                {moment(date).format("D MMM YYYY")}
              </MenuButton>
              <MenuPopover className={b("fields__date__popover")}>
                <div className={b("fields__date__calendar")}>
                  <Calendar value={date} onChange={onChangeDate} />
                </div>
              </MenuPopover>
            </Menu>
          </div>
        </div>
        {!props.isEdit && (
          <div className={b("suggestedTimes")}>
            <Menu>
              <MenuButton>
                <span className={b("suggestedTimes__button")}>
                  Suggested times <i className="far fa-chevron-down"></i>
                </span>
              </MenuButton>

              {!!slotsForCreate.length && (
                <MenuPopover className={b("suggestedTimes__list")}>
                  {slotsForCreate.map((range, index) => {
                    return (
                      <MenuItem
                        key={`${index}-of-times`}
                        className={`${b("suggestedTimes__list__option")}`}
                        onSelect={() => {
                          setRange(range);
                        }}
                      >
                        {`${moment(range.startTime)
                          .local()
                          .format("HH:mm, DD MMM YYYY")}`}
                      </MenuItem>
                    );
                  })}
                </MenuPopover>
              )}
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleEditingDateSelector;

const TimeField = (props) => {
  const [term, setTerm] = useState(props.value);

  useEffect(() => {
    setTerm(props.value);
  }, [props.value]);

  const onChange = (e) => {
    setTerm(e.target.value);
  };

  const onBlur = () => {
    props.onBlur(term);
  };

  const onKeyPress = props.onKeyPress;

  return (
    <InputMask
      mask={"99:99"}
      сlass={props.className}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      value={term}
    />
  );
};
