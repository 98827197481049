import React, { useContext, useEffect, useState } from "react";
import bem from "bem-ts";
import { Redirect } from "@reach/router";
import Button from "$/components/common/button";
import useFetch from "$/hooks/useFetch";
import useLocalStorage from "$/hooks/useLocalStorage";
import useSessionStorage from "$/hooks/useSessionStorage";
import ErrorBoundary from "$/utils/ErrorBoundary/ErrorBoundary";
import { CurrentUserContext } from "$/contexts/currentUser";
import LoaderColored from "$/components/loaderColored";
import LoaderPlain from "$/components/loaderPlain";
import LogoWithText from "$/components/common/logoWithText";

import PleaseScanYourCard from "./images/please_scan_your_card.svg";
import SomethingWentWrongTryLater from "./images/something_went_wrong_try_later.svg";
import SorryCouldntReadCertificateTryAgain from "./images/sorry_couldnt_read_certificate_try_again.svg";

import "./index.scss";

const ERROR_SPRING_INSUFFICIENT_AUTH =
  "org.springframework.security.authentication.InsufficientAuthenticationException";
// {"name":"org.springframework.security.authentication.InsufficientAuthenticationException","text":"Full authentication is required to access this resource"}
const b = new bem("pageLogin", { strict: false });

const Login: React.FC<any> = () => {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [currentUserState, dispatch] = useContext(CurrentUserContext);
  const [, setToken] = useLocalStorage("token");
  const [, setAuthorized] = useSessionStorage("authorized");

  const apiUrl = "/doctor/info";
  // @ts-ignore
  const [{ response, error, isLoading }, fetchUser] = useFetch(apiUrl);

  /**
   * Autologin 
   */
  useEffect(() => {
    // fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (!response) {
      setAuthorized('');
      return;
    } 
    setToken(response.SITHSid );
    setAuthorized('1');
    dispatch({ type: "SET_AUTHORIZED", payload: response });
    setLoginSuccess(true);
  }, [response]);

  if (loginSuccess) {
    return <Redirect to="/schedule" noThrow />;
  }

  const onClick = () => {
    fetchUser();
  };
  /*
    if (!error && response['SITHSid']) {
      // return <Redirect to="/" />
      setLoginSuccess(true)
    }
    else if (error) {
      setLoginError(error)
    }
  */

  return (
    <ErrorBoundary>
      <div className={`page ${b()}`}>
        <div className={b("content")}>
          <h1 className={b("message")}>
            {!error && "PleaseScanYourCard"}
            {error &&
              error.name === ERROR_SPRING_INSUFFICIENT_AUTH &&
              "Sorry, couldn't react certificate. Please, try again."}
            {error &&
              error.name &&
              (!response || !response["SITHSid"]) &&
              "Something went wrong. Please, try again."}
            {error && !error.name && "Something went wrong. Please, try again."}
          </h1>

          {(isLoading && <LoaderColored />) || (
            <Button className={b("button")} onClick={onClick}>
              Login
            </Button>
          )}
        </div>
        <LogoWithText className={"logo"} />
      </div>
    </ErrorBoundary>
  );
};

export default Login;