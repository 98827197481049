import React from "react";
import bem from "bem-ts";
import { observer } from "mobx-react";

import { AppointmentStateEnum, IDoctorInfo, IPatient } from "$/models";
import { Appointment } from "$/stores/appointment";
import sonIcon from "$/components/common/icons/son.svg";
import daughterIcon from "$/components/common/icons/daughter.svg";
import PhotoIcon from "$/components/common/photoIcon";
import StateButton from "$/components/appointmentStateButton";

import "./index.scss";

const b = bem("AppointmentPage-Member", { strict: false });

interface IMemberProps {
  person: IPatient | IDoctorInfo;
  state: AppointmentStateEnum;
  appointment: Appointment;
  isChild: boolean;
  openChat: () => void;
}

const Member: React.FC<IMemberProps> = ({
  person,
  state,
  appointment,
  isChild,
  openChat,
  ...props
}) => {
  let personalInfo = appointment.patientChildInfo || person.personalInfo;
  let photo =
    isChild &&
    (personalInfo.physicalData?.gender === "MALE" ? (
      <img src={sonIcon} />
    ) : (
      <img src={daughterIcon} />
    ));
  if (!isChild) {
    photo = <PhotoIcon person={person} />;
  }
  return (
    <div className={b()} key={`members-${Math.random()}`}>
      {photo}
      <div className={b("info")}>
        <div className={b("info__fullName")}>
          {personalInfo.firstName} {personalInfo.lastName}
        </div>
        <div className={b("info__bankID")}>{person.bankId}</div>
      </div>

      <div
        onClick={() => appointment.chat && openChat()}
        className={b("notification")}
      >
        <span className={b("notification__count")}>
          {appointment.chat?.messages.length || 0}
        </span>
      </div>

      {!!state && appointment.appointmentState !== "CANCELED" ? (
        <StateButton
          className={`${b("button")} ${b("state")}`}
          appointmentStore={appointment}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default observer(Member);
