import React, { createContext, useReducer } from "react";

const initialState = {
  notifications: {},
  notificationsAutoClear: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_NOTIFICATION":
      return {
        ...state,
        notifications: { ...state.notifications, ...action.payload },
        notificationsAutoClear: {
          ...state.notificationsAutoClear,
          ...action.payload,
        },
      };

    case "ADD_NOTIFICATION_AUTO_CLEAR":
      return {
        ...state,
        notificationsAutoClear: {
          ...state.notificationsAutoClear,
          ...action.payload,
        },
      };

    case "REMOVE_NOTIFICATION":
      const id = action.payload;
      const notifications = state.notifications;
      delete notifications[id];
      return { ...state, notifications };

    case "REMOVE_NOTIFICATION_AUTO_CLEAR":
      const key = action.payload;
      const notificationsAutoClear = { ...state.notificationsAutoClear };
      delete notificationsAutoClear[key];
      return { ...state, notificationsAutoClear };

    case "REMOVE_NOTIFICATION_CHAT":
      const chatId = action.payload;

      const newNotifications = Object.fromEntries(
        Object.entries(state.notifications).filter(
          ([k, v]) => {
            if(v.objectId === chatId && v.source === 'CHAT') {
              return false;
            }

            return true;
          }
        )
      );

      return { ...state, notifications: newNotifications };

    default:
      return { ...state };
  }
};

export const NotificationsStore = createContext();

const NotificationsProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);

  return (
    <NotificationsStore.Provider value={value}>
      {children}
    </NotificationsStore.Provider>
  );
};

export default NotificationsProvider;
