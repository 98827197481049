import React from "react";
import "./index.scss";
import bem from "bem-ts";
const b = new bem("Loader", { strict: false });

const LoaderColored = (props) => {
  return (
    <div className={`${b()} ${props.className || ''}`} style={props.style}>
      <span className={b("dote")} style={{ background: "#FB9B2A" }} />
      <span className={b("dote")} style={{ background: "#1283EB" }} />
      <span className={b("dote")} style={{ background: "#B44FCA" }} />
    </div>
  );
};

export default LoaderColored;
