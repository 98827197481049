import React, { useContext, useEffect, useMemo, useState } from "react";
import bem from "bem-ts";
import { Combobox, ComboboxInput } from "@reach/combobox";
import moment from "moment";
import { Dialog } from "@reach/dialog";
import { v4 as uuidv4 } from "uuid";

import "./index.scss";
import ErrorBoundary from "$/utils/ErrorBoundary/ErrorBoundary";
import NavHeader from "$/components/navHeader";
import LoaderColored from "$/components/loaderColored";
import Button from "$/components/common/button";
import SliderZoom from "$/components/sliderZoom";
import useFetch from "$/hooks/useFetch";
import { ScheduleContext } from "$/contexts/schedule";
import LoaderPlain from "$/components/loaderPlain";
import TemplateCreateForm from "$/components/templateCreateForm";
import Column from "$/components/templateEditingColumn";

const b = new bem("TemplateEditing", { strict: false });

const TemplateEditing = (props) => {
  const [scheduleStore] = useContext(ScheduleContext);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [discard, setDiscard] = useState(null);
  const [titleTerm, setTitleTerm] = useState("");
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [typeForCreate, setTypeForCreate] = useState(null);

  const openCreateForm = (e) => {
    setTypeForCreate(e.target.value);
    setShowCreateForm(true);
  };
  const closeCreateForm = () => {
    setShowCreateForm(false);
    setTypeForCreate(null);
  };

  const [{ response, isLoading }, fetchTemplate] = useFetch(
    `/admin/settings/templates`
  );

  const [{ responseSave, isLoadingSave }, fetchSaveTemplate] = useFetch(
    `/admin/settings/template`
  );

  useEffect(() => {
    props.templateId &&
      fetchTemplate({ url: `/doctor/template/?template=${props.templateId}` });
  }, [fetchTemplate, props.templateId]);

  useEffect(() => {
    if (response) {
      const template = response;
      const { schedule } = template;
      Object.entries(schedule).forEach(([day, slots]) => {
        schedule[day] = slots
          ?.map((slot) => ({ ...slot, id: uuidv4() }))
          .sort((a, b) => {
            if (a.startWorkTime > b.startWorkTime) return 1;
            if (a.startWorkTime < b.startWorkTime) return -1;
            else return 0;
          });
      });
      setCurrentTemplate({
        ...template,
        schedule,
      });

      setDiscard({ ...template });
      setTitleTerm(template?.scheduleName || "");
    }
  }, [response]);

  const onChangeTitle = (e) => {
    setTitleTerm(e.target.value);
  };

  const onBlurTitle = (e) => {
    setCurrentTemplate({ ...currentTemplate, scheduleName: e.target.value });
  };

  const renderHours = (zoom) => {
    const startTime = moment().hour(scheduleStore.minHour).minute(0).second(0);
    const endTime = moment().hour(scheduleStore.maxHour).minute(0).second(0);
    const times = [];
    for (
      let now = moment(startTime);
      now <= endTime;
      now.add(zoom || 15, "minutes")
    ) {
      times.push(moment(now));
    }
    return times?.map((time) => {
      return (
        <div className={b("schedule__hours__hour")} key={time.format("HH:mm")}>
          <span className={b("schedule__hours__hour__text")}>
            {time.format("HH:mm")}
          </span>
        </div>
      );
    });
  };

  const renderBackground = (zoom) => {
    const zoomTime = zoom;
    const countRows =
      (scheduleStore.maxHour - scheduleStore.minHour) * (60 / zoomTime);

    const renderRows: any[] = [];
    for (let i = 0; i <= countRows; i++) {
      renderRows.push(
        <div className={b("schedule__background__row")} key={`row-${i}`} />
      );
    }
    return renderRows;
  };

  const onAddSlot = ({ time, day, type }) => {
    const { schedule } = currentTemplate;
    const scheduleNew = schedule;
    scheduleNew[day].push({ ...time, appointmentType: type });
    scheduleNew[day] = scheduleNew[day].sort((a, b) => {
      if (a.startWorkTime > b.startWorkTime) return 1;
      if (a.startWorkTime < b.startWorkTime) return -1;
      else return 0;
    });
    setCurrentTemplate({ ...currentTemplate, schedule: scheduleNew });
  };

  const onEditSlot = (dayIndex: number, newSlot: any, oldSlot: any) => {
    const { schedule } = currentTemplate;

    setCurrentTemplate({
      ...currentTemplate,
      schedule: {
        ...schedule,
        [dayIndex]: schedule[dayIndex]
          .map((s) => (s === oldSlot ? newSlot : s))
          .sort((a, b) => {
            if (a.startWorkTime > b.startWorkTime) return 1;
            if (a.startWorkTime < b.startWorkTime) return -1;
            else return 0;
          }),
      },
    });
  };

  const onDeleteSlot = (dayIndex: number, slotId: string) => {
    const { schedule } = currentTemplate;

    setCurrentTemplate({
      ...currentTemplate,
      schedule: {
        ...schedule,
        [dayIndex]: schedule[dayIndex]
          .filter((s) => (s.id !== slotId))
          .sort((a, b) => {
            if (a.startWorkTime > b.startWorkTime) return 1;
            if (a.startWorkTime < b.startWorkTime) return -1;
            else return 0;
          }),
      },
    });
  };

  const renderColumns = useMemo(() => {
    const slots = currentTemplate?.schedule;
    if (!!slots) {
      const countMinutes = moment()
        .hour(scheduleStore.maxHour)
        .diff(moment().hour(scheduleStore.minHour), "minutes");
      const height = (countMinutes / scheduleStore.zoom) * 42;
      const momentWeek = moment();
      return [1, 2, 3, 4, 5, 6, 7].map((day: number, index: number) => {
        const currentSlots = slots[index + 1];
        return (
          <Column
            key={`column-day-${index + 1}`}
            day={index + 1}
            onCreateFreeSlot={onAddSlot}
            onEditSlot={onEditSlot.bind(this, index + 1)}
            onDeleteSlot={onDeleteSlot.bind(this, index + 1)}
            title={momentWeek.isoWeekday(day).format("ddd")}
            slots={currentSlots}
            templateState={[currentTemplate, setCurrentTemplate]}
            discardState={[discard, setDiscard]}
            height={height}
          />
        );
      });
    }
  }, [currentTemplate, currentTemplate?.schedule, scheduleStore.zoom]);

  const onClearWeek = () => {
    const schedule = currentTemplate.schedule;
    Object.entries(schedule).forEach(([day, slots]) => {
      schedule[day] = [];
    });
    setCurrentTemplate({ ...currentTemplate, schedule });
  };

  const onSaveChanges = () => {
    fetchSaveTemplate({
      data: currentTemplate,
      method: "POST",
    });
  };

  useEffect(() => {
    if (responseSave) {
      setDiscard({ ...currentTemplate });
    }
  }, [responseSave]);

  return (
    <ErrorBoundary>
      <div className={`page ${b()}`}>
        <NavHeader />
        {!isLoading && currentTemplate ? (
          <>
            <div className={b("header")}>
              <Combobox className={b("header__title")}>
                <ComboboxInput
                  value={titleTerm}
                  onChange={onChangeTitle}
                  onBlur={onBlurTitle}
                />
              </Combobox>
              <div className={b("header__menu")}>
                <SliderZoom />
              </div>
            </div>
            <div className={b("content")}>
              <div className={b("schedule")}>
                <div className={b("schedule__hours")}>
                  {renderHours(scheduleStore.zoom)}
                </div>
                <div className={b("schedule__background")}>
                  {renderBackground(scheduleStore.zoom)}
                </div>
                <div className={b("schedule__columns")}>
                  {isLoading ? <LoaderColored /> : renderColumns}
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoaderColored />
        )}
      </div>
      <div className={b("footer")}>
        <div className={b("footer__buttons")}>
          <Button
            className={b("footer__buttons__button", ["physical"])}
            value={"PHYSICAL"}
            onClick={openCreateForm}
          >
            Physical
          </Button>
          <Button
            className={b("footer__buttons__button", ["call"])}
            value={"CALL"}
            onClick={openCreateForm}
          >
            Call
          </Button>
          <Button
            className={b("footer__buttons__button", ["video"])}
            value={"VIDEO"}
            onClick={openCreateForm}
          >
            Video
          </Button>
          <span
            className={b("footer__buttons__button", ["clear"])}
            onClick={onClearWeek}
          >
            <i className={"icon far fa-eraser"} /> Clear week
          </span>
          {/*<span className={b('footer__buttons__button', ['discard'])}*/}
          {/*      onClick={onClickDiscard}>*/}
          {/*  Discard*/}
          {/*</span>*/}
          <Button
            className={b("footer__buttons__button", ["save"])}
            onClick={onSaveChanges}
            disabled={isLoadingSave}
          >
            {isLoadingSave ? <LoaderPlain /> : "Save changes"}
          </Button>
        </div>
      </div>

      {showCreateForm && (
        <Dialog aria-label={"CreateSlotTemplate"} onDismiss={closeCreateForm}>
          <TemplateCreateForm
            delete={props.delete}
            type={typeForCreate}
            template={currentTemplate}
            save={onAddSlot}
            dismissAction={closeCreateForm}
          />
        </Dialog>
      )}
    </ErrorBoundary>
  );
};

export default TemplateEditing;
