import React from 'react'
import './index.scss'
import bem from "bem-ts";
const b = new bem('Loader', {strict: false})

const LoaderPlain = (props) => {
  return (
      <div className={b()}>
        <span className={b('dote')} style={{background: props.color}}/>
        <span className={b('dote')} style={{background: props.color}}/>
        <span className={b('dote')} style={{background: props.color}}/>
      </div>
  )
}

export default LoaderPlain
