import bem from 'bem-ts';
import classNames from 'classnames';
import React from 'react';

const b = bem('badge-count');

export interface IBadgeCountProps {
  /**
   * @description Number to show in badge
   */
  count: number;

  /**
   * @description Max count to show
   *
   * @default 99
   */
  overflowCount?: number;

  /**
   * @description Customize Badge dot color
   *
   * @default #ff4d4f
   */
  color?: string;

  /**
   * @description Whether to show badge when `count` is zero
   *
   * @default false
   */
  showZero?: boolean;

  /**
   * @description Text to show when hovering over the badge
   */
  title?: string;

  className?: string;
  style?: React.CSSProperties;
}

export const BadgeCount: React.FC<IBadgeCountProps> = (props) => {
  const {
    count = 0,
    overflowCount = 99,
    color = '#ff4d4f',
    showZero = false,
    title = undefined,
    className = undefined,
    style = undefined,
  } = props;

  if (!showZero && count <= 0) return null;

  const countText = count > overflowCount ? `${overflowCount}+` : `${count}`;

  return <div className={classNames(b(), className)} style={{ background: color, ...style }} title={title}>
    <span className={b('text')}>{countText}</span>
  </div>;
};