import classNames from 'classnames';
import * as React from 'react';
import MyHealthLogo from "$/components/myHealthLogo";
import './index.scss';
import bem from "bem-ts";

interface ILogoProps {
    className?: string;
}

const b = bem('myhealthLogo', {strict: false})

const Logo:React.FunctionComponent<ILogoProps> = (props) => {
    return (
        <div className={classNames(b(), props.className)}>
            <MyHealthLogo />
            <span className={b('text')}>My.Health</span>
        </div>
    )
}

export default Logo;
