import daughterIcon from '$/components/common/icons/daughter.svg';
import sonIcon from '$/components/common/icons/son.svg';
import PhotoIcon from '$/components/common/photoIcon';
import ScrolledComboboxList from '$/components/common/scrolledComboboxList';
import { useThrottle } from '$/hooks/useThrottle';
import { IAppointmentType, IPatient, IPatientWithAvailability } from '$/models';
import { globalHeaders } from '$/services/http-client.service';
import { Appointment } from '$/stores/appointment';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptionText, ComboboxPopover, } from '@reach/combobox';
import '@reach/combobox/styles.css';
import axios from 'axios';
import bem from 'bem-ts';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import './index.scss';

const WIDTH = 350;
const b = bem('PatientSelector', {strict: false});

interface IPatientSelectorProps {
  appointmentStore: Appointment
}

const PatientSelector = ({appointmentStore, ...props}: IPatientSelectorProps) => {
  const currentPatients = (appointmentStore.patients?.map<IPatientWithAvailability>(patient => ({
    ...patient,
    availability: {isAvailable: true}
  })) || []);
  let [selectedPatients, setSelectedPatients] = useState(currentPatients);

  const inputRef = useRef<HTMLInputElement>(null);
  inputRef.current?.setAttribute('autocomplete', 'off');

  let [term, setTerm] = useState('');
  let [foundPage, setFoundPage] = useState(0);

  const foundPatients = usePatientMatch(term, appointmentStore, foundPage);

  useEffect(() => {
    const patients = selectedPatients.map((person) => {
      if (!!person.parent) {
        const child = person.personalInfo;
        appointmentStore.setField('patientChildInfo', child);
        return {
          ...person.parent,
        };
      } else {
        return person;
      }
    });
    appointmentStore.setField('patients', patients);
  }, [selectedPatients]);

  const noMoreItems = () => foundPatients.length > 1000;

  function loadMoreItems() {
    setFoundPage(foundPage + 1);
  }

  function getItems(): Promise<void> {
    return !noMoreItems()
      ? new Promise((res) => {
        setTimeout(() => res(loadMoreItems()), 500);
      })
      : Promise.resolve();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTerm(event.target.value);
    setFoundPage(0);
  }

  function addSelection(patient: IPatientWithAvailability) {
    if (!selectedPatients.find((sp) => sp.bankId === patient.bankId)) {
      setSelectedPatients([...selectedPatients, patient]);
    }
    setTerm('');
    setFoundPage(0);
  }

  function removePatient(patient: IPatientWithAvailability) {
    setSelectedPatients(
      selectedPatients.filter((selected) => selected !== patient)
    );
    return false;
  }

  return (
    <Fragment>
      <div className={b('container')} key={`contianer`}>
        <div className={b('prefix')}>
          <i className={'far fa-user'}/>
        </div>
        <Combobox openOnFocus style={{width: WIDTH}}>
          <ComboboxInput
            ref={inputRef}
            style={{width: WIDTH - 60}}
            placeholder={'Add patient by name or ID'}
            onChange={handleChange}
            value={term}
          />
          {term.length > 0 && (
            <a
              className={b('clear-input')}
              onClick={() => {
                setTerm('');
                return false;
              }}
            >
              <i className={'far fa-times'}/>
            </a>
          )}
          {term.length > 0 && foundPatients.length > 0 && (
            <ComboboxPopover
              portal={false}
              className={b('popover')}
              style={{width: WIDTH}}
            >
              <ScrolledComboboxList getItems={getItems}>
                {foundPatients.map((patient, index: number) => {
                  const isAvailable = patient.availability?.isAvailable;
                  const hasChildren = !!patient.personalInfo.administrativeInfo
                    ?.children.length;
                  let innerPhoto = `${patient.personalInfo.firstName[0]}${patient.personalInfo.lastName[0]}`;
                  const photo = <PhotoIcon person={patient}/>;
                  return (
                    <React.Fragment
                      key={`frag-${patient.bankId}`}>
                      <ComboboxOption
                        key={`option-${patient.bankId}`}
                        className={b('patientResult')}
                        value={`${patient.personalInfo.firstName} ${patient.personalInfo.lastName}`}
                        onClick={() => {
                          addSelection(patient);
                        }}
                      >
                        {photo}
                        <div
                          className={b('patientResult__info')}
                          key={`patientResult__info-${index}`}
                        >
                          <div
                            className={b('patientResult__fullName')}
                            key={`patientResult__fullName-${index}`}
                          >
                            <ComboboxOptionText
                              key={`comboboxOptionText-${index}`}
                            />
                            {
                              !isAvailable &&
                              <span className="busyMarker">(busy)</span>
                            }
                          </div>
                          <div
                            className={b('patientResult__bankID')}
                            key={`patientResult__bankID-${index}`}
                          >
                            {patient.bankId}
                          </div>
                        </div>
                      </ComboboxOption>

                      {hasChildren &&
                      patient.personalInfo.administrativeInfo.children.map(
                        (child, index: number) => {
                          return (
                            <ComboboxOption
                              key={`child-${patient.bankId}-${index}`}
                              className={b('patientResult', ['child'])}
                              value={`${child.firstName} ${child.lastName}`}
                              onClick={() => {
                                addSelection({
                                  personalInfo: child,
                                  parent: patient,
                                });
                              }}
                            >
                              {child.physicalData?.gender === 'MALE' ? (
                                <img
                                  className={b('patientResult__photo')}
                                  key={`child-patientResult__photo-${index}`}
                                  src={sonIcon}
                                  alt={'child-patient-photo-son'}/>
                              ) : (
                                <img
                                  className={b('patientResult__photo')}
                                  key={`child-patientResult__photo-${index}`}
                                  src={daughterIcon}
                                  alt={'child-patient-photo-daughter'}/>
                              )}
                              <div
                                className={b('patientResult__info')}
                                key={`child-patientResult__info-${index}`}
                              >
                                <div
                                  className={b('patientResult__fullName')}
                                  key={`child-patientResult__fullName-${index}`}
                                >
                                  <ComboboxOptionText/>
                                  {/*    !isAvailable &&*/}
                                  {/*    <span className="busyMarker">(busy)</span>*/}
                                  {/*}*/}
                                </div>
                                <div
                                  className={b('patientResult__bankID')}
                                  key={`child-patientResult__bankID-${index}`}
                                >
                                  {patient.bankId}
                                </div>
                              </div>
                            </ComboboxOption>
                          );
                        }
                      )}
                    </React.Fragment>
                  );
                })}
              </ScrolledComboboxList>
            </ComboboxPopover>
          )}
        </Combobox>
      </div>
      {!!selectedPatients.length && (
        <div className={b('selected')}>
          {selectedPatients.map((patient) => {
            const isAvailable =
              patient.availability && patient.availability.isAvailable;

            let photo = <PhotoIcon person={patient}/>;
            return (
              <div key={patient.bankId} className={b('patientSelected')}>
                <div className={b('patientResult')}>
                  {photo}
                  <div className={b('patientResult__info')}>
                    <div className={b('patientResult__fullName')}>
                      {patient.personalInfo.firstName}{' '}
                      {patient.personalInfo.lastName}
                    </div>
                    {/*    !isAvailable &&*/}
                    {/*    <span className="busyMarker">(busy)</span>*/}
                    {/*}*/}
                    <div className={b('patientResult__bankID')}>
                      {patient.bankId}
                    </div>
                  </div>
                </div>
                <a
                  className={b('patientSelected__remove')}
                  onClick={() => {
                    removePatient(patient);
                    return false;
                  }}
                >
                  <i className={'far fa-times'}/>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

function usePatientMatch(searchTerm: string, appointmentDates: Appointment, page = 0) {
  const throttledTerm = useThrottle(searchTerm, 800);

  let [foundPatients, setFoundPatients] = useState<IPatientWithAvailability[]>([]);
  useEffect(() => {
    if (throttledTerm.trim() !== '') {
      let isFresh = true;

      fetchPatients(throttledTerm, page)
        .then(([patientsTotal, newPagePatients]) => {
          return {
            patientsTotal,
            newPagePatients,
            patient2appointmentPromise: fetchAppointments(
              newPagePatients,
              appointmentDates.startDate,
              appointmentDates.endDate
            ),
          };
        })
        .then(({patientsTotal, newPagePatients, patient2appointmentPromise}) => {
          patient2appointmentPromise.then((patient2appointment) => {
            const newPagePatientsBankIds = newPagePatients.map(
              (newPagePatient) => newPagePatient.bankId
            );
            setFoundPatients(patientsTotal
              .filter((patient) =>
                newPagePatientsBankIds.includes(patient.bankId)
              ).map<IPatientWithAvailability>((patient) => ({
                ...patient,
                availability: {
                  isAvailable: !patient2appointment[patient.bankId],
                }
              })));
          });
        });

      return () => {
        isFresh = false;
      };
    }
  }, [throttledTerm, page]);
  return foundPatients;
}

const cache: { [key: string]: IPatient[] } = {};

function fetchPatients(term: string, page = 0): Promise<[IPatient[], IPatient[]]> {
  const size = 20;

  if (cache[`${term}_${page}`] && cache[`${term}`]) {
    return Promise.resolve([cache[`${term}`], cache[`${term}_${page}`]]);
  }
  return axios
    .get<IPatient[]>(
      `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/search/patient/?query=${term}&size=${size}&page=${page}`,
      {withCredentials: true}
    )
    .then((res) => res.data)
    .then((result) => {
      cache[`${term}_${page}`] = result;
      cache[`${term}`] = [...(cache[`${term}`] || []), ...result];
      return [cache[`${term}`], cache[`${term}_${page}`]];
    });
}

function fetchAppointments(patients: IPatient[], startDateTime: string, endDateTime: string) {
  const patientIds = patients.map((patient) => patient.bankId);
  const patient2Appointment: { [key: string]: IAppointmentType } = {};

  if (!startDateTime || !endDateTime) {
    // if appointment time limits not fully specified, then don't check on possible user appointments at all
    // check only completely specified time slots

    return Promise.resolve(patient2Appointment);
  } else {
    return axios
      .get<IAppointmentType[]>(
        `${process.env.APP_API_DOMAIN}${
          process.env.APP_API_BASE_PATH
        }/doctor/appointments/patient/${startDateTime}/${endDateTime}/?patients=${patientIds.join(
          ','
        )}`,
        {withCredentials: true, headers: globalHeaders}
      )
      .then((res) => res.data)
      .then((result) => {
        result.forEach((appointmentData) => {
          // appointmentData.startDate
          // appointmentData.endDate
          appointmentData.patients.forEach(
            (busyPatient) =>
              (patient2Appointment[
                busyPatient.bankId.toString()
                ] = appointmentData)
          );
        });
        return patient2Appointment;
      });
  }
}

export default observer(PatientSelector);
