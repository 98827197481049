import { BadgeCount, IBadgeCountProps } from '$/components/badge/BadgeCount';
import bem from 'bem-ts';
import classNames from 'classnames';
import React from 'react';
import './styles.scss';

const b = bem('badge');

interface IBadgeProps extends IBadgeCountProps {
  containerClassName?: string;
  children: React.ReactNode;
}

export const Badge: React.FC<IBadgeProps> = (props) => {
  const { containerClassName, children, ...rest } = props;

  return <div className={classNames(b(), containerClassName)}>
    {children}
    <BadgeCount {...rest}/>
  </div>;
};
