import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import LoaderPlain from "$/components/loaderPlain";
import Button from "$/components/common/button";

import "./index.scss";
import bem from "bem-ts";

const b = new bem("NewVisit-Toggler");

const TogglerNewVisit = ({ appointmentStore }) => {
  const [isNewVisit, setIsNewVisit] = useState(
    appointmentStore.isFirstVisit || true
  );

  function toggleNewVisit() {
    setIsNewVisit(!isNewVisit);
  }

  useEffect(() => {
    appointmentStore.setField("isFirstVisit", isNewVisit);
  }, [isNewVisit]);

  return (
    <div className={b("container")}>
      <i className={`far fa-flag ${b("icon")}`} />
      <Button
        className={b("button")}
        view={"transparent"}
        onClick={toggleNewVisit}
      >
        {isNewVisit ? "New Visit" : "Check Up"}
      </Button>
    </div>
  );
};

export default observer(TogglerNewVisit);