import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { AppointmentStateEnum, IAppointment } from "$/models";

import "./index.scss";
import { Menu, MenuButton, MenuPopover, MenuItem } from "@reach/menu-button";
import bem from "bem-ts";
import moment from "moment";
import useFetch from "$/hooks/useFetch";
import LoaderPlain from "$/components/loaderPlain";
import { Appointment } from "$/stores/appointment";

const b = new bem("appointmentStateButton", { strict: false });

interface IStateButtonProps {
  appointmentStore: Appointment;
  className?: string;
  isCompact?: boolean;
}

const StateButton: React.FunctionComponent<IStateButtonProps> = ({
  appointmentStore,
  ...props
}) => {
  const state = appointmentStore?.appointmentState || "BOOKED";

  const compactClass = props.isCompact
    ? "appointmentStateButton__isCompact--true"
    : "";
  const propsState = useMemo(() => {
    return getProperties([state])[0];
  }, [state]);

  const className = `${b("appointmentStateButton")} 
    ${propsState.stateClass}`;

  const onSelect = (event, val) => {
    event.stopPropagation();
    appointmentStore.changeStatus(val);
    return false;
  };
  const onClick = (event) => {
    event.stopPropagation();
  };

  let nextStates;
  let history = appointmentStore?.stateHistory?.map((object) => object.state);

  if (state === AppointmentStateEnum[0]) {
    nextStates = [AppointmentStateEnum[1], AppointmentStateEnum[2]];
  } else if (state === AppointmentStateEnum[1]) {
    if (!history.includes(AppointmentStateEnum[2])) {
      nextStates = [AppointmentStateEnum[2]];
    } else {
      nextStates = [AppointmentStateEnum[3]];
    }
  } else if (state === AppointmentStateEnum[2]) {
    if (!history.includes(AppointmentStateEnum[1])) {
      nextStates = [AppointmentStateEnum[1]];
    } else {
      nextStates = [AppointmentStateEnum[3]];
    }
  } else if (state === AppointmentStateEnum[3]) {
    nextStates = [AppointmentStateEnum[5]];
  }

  const propNextStates = !!nextStates?.length && getProperties(nextStates);
  return (
    <div className={`${b()} ${props.className} ${compactClass}`}>
      <Menu className={b("wrapper")}>
        <MenuButton
          className={b("menuButton") + " " + className}
          disable={`${state === AppointmentStateEnum[5]}`}
          onClick={onClick}
        >
          {appointmentStore?.isLoadingChangeStatus ? (
            <LoaderPlain />
          ) : (
            <>
              {propsState.icon}
              <span className={b("definition")}>&nbsp;{propsState.title}</span>
              {state !== "COMPLETED" && (
                <>
                  &nbsp;
                  <i className="arrow far fa-angle-down" />
                </>
              )}
            </>
          )}
        </MenuButton>
        {nextStates?.length && (
          <MenuPopover
            className={b("dropdown")}
            style={{ zIndex: 20, marginTop: "6px" }}
          >
            {propNextStates?.map((propNextState, index) => {
              return (
                <MenuItem
                  onSelect={() => {}}
                  key={`item-${index}`}
                  onClick={(e) => {
                    onSelect(e, nextStates[index]);
                  }}
                >
                  {propNextState.icon}
                  <span key={`options-${index}`} className={b("definition")}>
                    &nbsp;{propNextState.title}
                  </span>
                </MenuItem>
              );
            })}

            <div className={b("history")}>
              {appointmentStore?.stateHistory.map((state, index) => {
                const isToday =
                  moment().format("YYYY-MM-DD") ===
                  moment(state.changedTime).format("YYYY-MM-DD");
                const date = moment(state.changedTime).format(
                  isToday ? "HH:mm" : "DD.MM.YYYY, HH:mm"
                );
                let result = "";
                if (state.state === "BOOKED") {
                  result = `${date} Booked`;
                } else if (state.state === "PAYED") {
                  result = `${date} Payed`;
                } else if (state.state === "CHECKED_IN") {
                  result = `${date} Checked in`;
                } else if (state.state === "DIAGNOSED") {
                  result = `${date} Diagnosed by ${state.author}`;
                } else if (state.state === "COMPLETED") {
                  result = `${date} Completed by ${state.author}`;
                } else if (state.state === "CANCELED") {
                  result = `${date} Canceled by ${state.author}`;
                }
                return <span key={`history-${index}`}>{result}</span>;
              })}
            </div>
          </MenuPopover>
        )}
      </Menu>
    </div>
  );
};

export default observer(StateButton);

function getProperties(states: AppointmentStateEnum[]) {
  return states.map((state) => {
    switch (state) {
      case AppointmentStateEnum[0]:
        return {
          icon: <i className="icon far fa-book" />,
          stateClass: b("menuButton__state", { booked: true }),
          title: "Booked",
        };
      case AppointmentStateEnum[1]:
        return {
          icon: <i className="icon far fa-door-open" />,
          stateClass: b("menuButton__state", { checked: true }),
          title: "Check In",
        };
      case AppointmentStateEnum[2]:
        return {
          icon: <i className="icon far fa-credit-card-blank" />,
          stateClass: b("menuButton__state", { payed: true }),
          title: "Payed",
        };
      case AppointmentStateEnum[3]:
        return {
          icon: <i className="icon far fa-stethoscope" />,
          stateClass: b("menuButton__state", { diagnosed: true }),
          title: "Diagnosed",
        };
      case AppointmentStateEnum[5]:
        return {
          icon: <i className="icon far fa-stethoscope" />,
          stateClass: b("menuButton__state", { completed: true }),
          title: "Completed",
        };
      default:
        return {
          icon: <i className="icon far fa-book" />,
          stateClass: b("menuButton__state", { booked: true }),
          title: "Booked",
        };
    }
  });
}
