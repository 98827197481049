import React, { useContext, useEffect, useRef, useState } from "react";
import bem from "bem-ts";
import classNames from "classnames";
import { Resizable } from "re-resizable";
import moment from "moment";
import { Dialog } from "@reach/dialog";

import "./index.scss";

import AppointmentForm from "$/components/appointmentForm";
import { IAppointment } from "$/models";
import Button from "$/components/common/button";
import { ScheduleContext } from "$/contexts/schedule";
import { GlobalContext } from "$/contexts/global";
import { Appointment } from "$/stores/appointment";

const b = bem("FreeSlot", { strict: false });

interface IFreeSlotProps {
  height: number;
  appointment: IAppointment;
  className?: string;
  top: number;
  dateTime: string;
}

const FreeSlot: React.FC<IFreeSlotProps> = (props) => {
  const [scheduleStore, dispatchSchedule] = useContext(ScheduleContext);
  const globalStore = useContext(GlobalContext);
  const [editing, setEditing] = useState(false);
  const [height, setHeight] = useState(props.height);
  const [top, setTop] = useState(props.top);
  const [showButton, setShowButton] = useState(false);
  const [delayHandler, setDelayHandler] = useState<Timeout>(null);

  const [currentAppointment,setCurrentAppointment] = useState<Appointment|null>(null);
  const [showEditForm, setShowEditForm] = useState(false);


  const [styleResizeBox, setStyleResizeBox] = useState({
    position: "absolute",
  });

  const refSlot = useRef(null);
  const refCreate = useRef(null);
  const refCancel = useRef(null);
  const refWindow = useRef(null);
  const pixelsInMinute = 41 / scheduleStore.zoom;

  const handleMouseEnter = () => {
    if(delayHandler){
      clearTimeout(delayHandler);
    }
    !editing &&
      setDelayHandler(
        setTimeout(() => {
          setCurrentAppointment(currentAppointment || new Appointment(globalStore, props.appointment));
          setShowButton(true);
        }, 1000)
      );
  };

  const handleMouseLeave = () => {
    !editing && setShowButton(false);
    clearTimeout(delayHandler);
  };

  useEffect(() => {
    setTop(props.top);
  }, [props.top]);

  const openEditForm = () => setShowEditForm(true);

  const closeEditForm = () => {
    setShowButton(false);
    setShowEditForm(false);
    setEditing(false);
    setHeight(props.height);
    setTop(props.top);
  };

  const startEdit = () => {
    setEditing(true);
  };

  const stopEdit = (save: boolean = false) => {
    setEditing(false);
    setShowButton(false);
    if (!save) {
      // setCurrentAppointment(props.appointment);
      setHeight(props.height);
      setTop(props.top);
    }
  };

  useEffect(() => {
    const handleClickDocument = (event) => {
      if (showEditForm) {
        return;
      }
      if (
        !(refSlot?.current.contains(event.target) || refWindow?.current?.contains(event.target)) ||
        event.target == refCancel.current
      ) {
        stopEdit();
      }
    };

    if (editing) {
      document.addEventListener("click", handleClickDocument);
    } else {
      document.removeEventListener("click", handleClickDocument);
    }

    return () => {
      document.removeEventListener("click", handleClickDocument);
    };
  }, [editing, showEditForm]);

  const onResizeStart = (e, dir, refToElement) => {
    if (dir === "top") {
      setStyleResizeBox({ ...styleResizeBox, bottom: "0" });
    } else {
      setStyleResizeBox({ ...styleResizeBox, bottom: "auto" });
    }
  };

  const onResize = (e, direction, ref, d) => {
    const heightInMinutes = (scheduleStore.zoom / 41) * (height + d.height);
    if (direction === "top") {
      const newStartDate = moment(currentAppointment.endDate).subtract(
        Math.round(heightInMinutes),
        "minutes"
      );
      currentAppointment.setField(
        "startDate",
        newStartDate.format("YYYY-MM-DDTHH:mm:SS")
      );
    } else {
      const newEndDate = moment(currentAppointment.startDate).add(
        Math.round(heightInMinutes),
        "minutes"
      );
      currentAppointment.setField(
        "endDate",
        newEndDate.format("YYYY-MM-DDTHH:mm:SS")
      );
    }
  };

  const onResizeStop = (e, direction, ref, d) => {
    if (direction === "top") {
      setTop(top - d.height);
    }
    setHeight(height + d.height);
  };

  const onSend = (appointment) => {
    const keyColumn =
      scheduleStore.view === "day"
        ? appointment.doctor.sithsid
        : moment(appointment.startDate).format("ddd_D");

    dispatchSchedule({
      type: "ADD_APPOINTMENT",
      payload: {
        keyColumn,
        value: appointment,
      },
    });
    closeEditForm();
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      date-time={props.dateTime}
      style={{ height: `${height}px`, top: `${top}px` }}
      className={classNames(
        b([
          props.appointment.appointmentType?.toLowerCase(),
          editing && "editing",
        ]),
        props.className
      )}
      ref={refSlot}
    >
      {showButton && (
        <Resizable
          className={b("resizableBox")}
          enable={{ top: editing, bottom: editing }}
          minHeight={40}
          style={styleResizeBox}
          size={{ height }}
          grid={[0, pixelsInMinute * 5]}
          onResize={onResize}
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
        >
          <Button className={b("button", { editing })} onClick={startEdit}>
            {!editing && (
              <>
                <i className="far fa-plus-circle"></i> &ensp;New
              </>
            )}
            {editing && (
              <>
                <span className={b("button__output")}>
                  <span>
                    {moment
                      .utc(currentAppointment.startDate)
                      .local()
                      .format("HH:mm")}
                  </span>
                  -
                  <span>
                    {moment
                      .utc(currentAppointment.endDate)
                      .local()
                      .format("HH:mm")}
                  </span>
                </span>
                <span ref={refCreate} onClick={openEditForm}>
                  Create
                </span>
                <span ref={refCancel} onClick={closeEditForm}>
                  <i className={"far fa-times"} />
                </span>
              </>
            )}
          </Button>
        </Resizable>
      )}
      {showButton && (
        <Dialog
          isOpen={showEditForm}
          onDismiss={closeEditForm}
          aria-label="Appointment Form"
          ref={refWindow}
        >
          <AppointmentForm
            onSave={onSend}
            autoSetDate={true}
            appointmentStore={currentAppointment}
            dismissAction={closeEditForm}
          />
        </Dialog>
      )}
    </div>
  );
};

export default React.memo(FreeSlot);
