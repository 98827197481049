import React, { useContext, useEffect } from "react";
import { Formik } from "formik";
import bem from "bem-ts";
import axios from "axios";

import { initForm } from "$/components/appointmentForm";
import AppointmentTypeSwitcher from "$/components/appointmentTypeSwitcher";
import ScheduleEditingDateSelector from "$/components/scheduleEditingDateSelector";
import "./index.scss";
import Button from "$/components/common/button";
import LoaderPlain from "$/components/loaderPlain";
import useFetch from "$/hooks/useFetch";
import { CurrentUserContext } from "$/contexts/currentUser";
import { globalHeaders } from '../../services/http-client.service';

const b = new bem("ScheduleEditingForm", { strict: false });
const INIT_FORM = {
  ...initForm(),
  status: "WORK_TIME",
  appointmentState: null,
  isFirstVisit: null,
  appointmentDateRange: undefined,
};

export default (props) => {
  const [useState] = useContext(CurrentUserContext);

  const apiUrl = "/doctor/appointment/";

  const [{ response, isLoading }, fetchAppointment] = useFetch(apiUrl);

  useEffect(() => {
    if (response) {
      props.onSend(response);
      props.dismissAction();
    }
  }, [response]);

  const submit = (values, formikBag) => {
    const config = {
      method: "POST",
      data: { ...values, doctor: useState.currentUser },
    };
    fetchAppointment(config);
    // formikBag.setSubmitting(false);
    return false;
  };

  const deleteSlot = async () => {
    await axios.get(
      `${process.env.APP_API_DOMAIN}${process.env.APP_API_BASE_PATH}/doctor/appointment/${props.initialValues?.id}/delete`,
      { withCredentials: true, headers: globalHeaders}
    );

    props.onDelete();
    props.dismissAction();
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={{ ...INIT_FORM, ...props.initialValues }}
        onSubmit={submit}
      >
        {({ errors, touched, ...formikProps }) => {
          return (
            <form className={b()} onSubmit={formikProps.handleSubmit}>
              <div className={b("content")}>
                <h2>{props.isEdit ? "Edit slot" : "Add slot"}</h2>
                <div className={b("field", ["type"])}>
                  <AppointmentTypeSwitcher value={formikProps.values.appointmentType} onChange={(e)=>{
                      formikProps.setFieldValue("appointmentType", e)
                    }
                  } />
                </div>
                <div className={b("field", ["date"])}>
                  <ScheduleEditingDateSelector
                    autoSet={!props.initialValues}
                    form={formikProps}
                    isEdit={props.isEdit}
                  />
                </div>
              </div>
              <div className={b("footer")}>
                <div className={b("footer__buttons")}>
                  {props.isEdit && (
                    <Button
                      className={b("button", ["delete"])}
                      view="danger"
                      onClick={deleteSlot}
                    >
                      Delete
                    </Button>
                  )}

                  <Button
                    className={b("button", ["cancel"])}
                    view="cancel"
                    onClick={(e) => {
                      props.dismissAction();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className={b("button", ["create"])}
                    type={"submit"}
                    // view={!(!formikProps.isValidating && formikProps.isValid) && 'disabled'}
                  >
                    {isLoading ? (
                      <LoaderPlain />
                    ) : (
                      <>{props.isEdit ? "Save" : "Create"}</>
                    )}
                  </Button>
                </div>
                <div className={b("footer__errors")}>
                  {formikProps.dirty &&
                    Object.keys(errors).map((fieldName, index: number) => {
                      return (
                        <div
                          className={b("footer__formError")}
                          key={`formError-${index}`}
                        >
                          {errors[fieldName]}
                        </div>
                      );
                    })}
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
