import { IChat } from '$/models';
import moment from 'moment';
import React, { createContext, useReducer } from 'react';

type ISortBy = 'DEFAULT' | 'LATEST_MESSAGE' | 'PATIENT' | 'SYMPTOMS' | 'APPOINTMENT';
type IActionType =
  'SET_CURRENT_USER'
  | 'SET_SEARCHING'
  | 'SET_SORTING'
  | 'SET_LOADING_CHAT_LIST'
  | 'SET_ERROR_CHAT_LIST'
  | 'SET_CHAT_LIST'
  | 'SET_CHAT'
  | 'UPDATE_CHAT';

interface IChatWithAppointment extends IChat {
  appointment: any;
}

interface IChatState {
  currentUserId: string | null;
  readyForChat: boolean;
  chatList: Array<IChatWithAppointment>,
  totalNewMessages: number,
  isLoadingChatList: boolean,
  isErrorChatList: boolean,
  sortBy: ISortBy,
  searching: string,
}

const initialState: IChatState = {
  currentUserId: null,
  readyForChat: false,
  chatList: new Array<IChatWithAppointment>(),
  totalNewMessages: 0,
  isLoadingChatList: false,
  isErrorChatList: false,
  sortBy: 'DEFAULT',
  searching: '',
};

const sortChat = (chatList: Array<IChatWithAppointment>, sortBy: ISortBy, userId: string | null, readyForChat: boolean) => {
  switch (sortBy) {
    case 'DEFAULT':
    //  Fallthrough

    case 'LATEST_MESSAGE': {
      let sorted = chatList.sort((prev, next) => {
        const a = prev.messages[prev.messages.length - 1];
        const b = next.messages[next.messages.length - 1];

        if (!a.dateTime || !b.dateTime) return 0;
        return moment(b.dateTime).diff(moment(a.dateTime));
      });
      if (!userId) return sorted;

      // Sort by unread
      sorted = chatList.sort((prev, next) => {
        const aNurseRequest = readyForChat && prev.state === 'TO_NURSE';
        const bNurseRequest = readyForChat && next.state === 'TO_NURSE';

        const a = (prev.unreadMessages?.[userId] ?? 0) + (aNurseRequest ? 1 : 0);
        const b = (next.unreadMessages?.[userId] ?? 0) + (bNurseRequest ? 1 : 0);

        if (a > 0 && b > 0) return 0;
        if (a > 0) return -1;
        if (b > 0) return 1;
        return 0;
      });

      return sorted;
    }

    case 'PATIENT':
      return chatList.sort((prev, next) => {
        const prevLastMessage = prev.messages[prev.messages.length - 1];
        const nextLastMessage = next.messages[next.messages.length - 1];
        const prevPatient = `${prevLastMessage.patient.personalInfo.firstName} ${prevLastMessage.patient.personalInfo.lastName}`;
        const nextPatient = `${nextLastMessage.patient.personalInfo.firstName} ${nextLastMessage.patient.personalInfo.lastName}`;
        if (prevPatient > nextPatient) return -1;
        else if (prevPatient < nextPatient) return 1;
        else return 0;
      });

    case 'SYMPTOMS':
      return chatList.sort((prev, next) => {
        const prevAppointment = prev.appointment;
        const nextAppointment = next.appointment;

        if (
          prevAppointment &&
          nextAppointment &&
          (prevAppointment?.events?.symptoms || []).join(', ') >
          (nextAppointment?.events?.symptoms || []).join(', ')
        ) {
          return -1;
        } else if (
          prevAppointment &&
          nextAppointment &&
          (prevAppointment?.events?.symptoms || []).join(', ') <
          (nextAppointment?.events?.symptoms || []).join(', ')
        ) {
          return 1;
        } else return 0;
      });

    case 'APPOINTMENT':
      return chatList.sort((prev, next) => {
        const prevAppointment = prev.appointment;
        const nextAppointment = next.appointment;
        if (
          prevAppointment &&
          nextAppointment &&
          prevAppointment.startDate > nextAppointment.endDate
        ) {
          return -1;
        } else if (
          prevAppointment &&
          nextAppointment &&
          prevAppointment.startDate < nextAppointment.endDate
        ) {
          return 1;
        } else return 0;
      });
  }
};

const getUnreadMessageCount = (chatList: Array<IChatWithAppointment>, currentUserId: string | null | undefined): number => {
  if (!Array.isArray(chatList) || !chatList.length || !currentUserId) return 0;

  return chatList.reduce((result, chat: IChatWithAppointment) => {
    const userUnreadCount = chat.unreadMessages?.[currentUserId] || 0;
    return result + userUnreadCount;
  }, 0);
};

const reducer = (state: IChatState, action: { type: IActionType, payload: any }): IChatState => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return { ...state, currentUserId: action.payload.id || null, readyForChat: action.payload.readyForChat ?? false };

    case 'SET_SEARCHING':
      return { ...state, searching: action.payload };

    case 'SET_SORTING':
      const sortBy = action.payload;
      return {
        ...state,
        sortBy,
        chatList: sortChat(state.chatList, sortBy, state.currentUserId, state.readyForChat),
      };

    case 'SET_LOADING_CHAT_LIST':
      return { ...state, isLoadingChatList: action.payload };

    case 'SET_ERROR_CHAT_LIST':
      return { ...state, isErrorChatList: action.payload };

    case 'SET_CHAT_LIST':
      return {
        ...state,
        chatList: sortChat(action.payload, state.sortBy, state.currentUserId, state.readyForChat),
        totalNewMessages: getUnreadMessageCount(action.payload, state.currentUserId),
      };

    case 'SET_CHAT':
      const { chatId } = action.payload;
      const newValueOfChat = action.payload;
      const chatList = state.chatList.map((chat) => {
        return chat.chatId === chatId ? newValueOfChat : chat;
      });
      !chatList.length && chatList.push(newValueOfChat);

      return {
        ...state,
        chatList: sortChat(chatList, state.sortBy, state.currentUserId, state.readyForChat),
        totalNewMessages: getUnreadMessageCount(chatList, state.currentUserId),
      };

    case 'UPDATE_CHAT':
      return (() => {
        const { chatId } = action.payload;
        const newValueOfChat = action.payload;

        let newChatList = state.chatList ?? [];
        const isNewChat = !state.chatList.some(({ chatId: id }) => id === chatId);

        if (isNewChat) {
          newChatList.push(newValueOfChat);
        } else {
          newChatList = state.chatList.map((chat) => {
            return chat.chatId === chatId ? { ...chat, ...newValueOfChat } : chat;
          });
        }

        return {
          ...state,
          chatList: sortChat(newChatList, state.sortBy, state.currentUserId, state.readyForChat),
          totalNewMessages: getUnreadMessageCount(newChatList, state.currentUserId),
        };
      })();

    default:
      return { ...state };
  }
};

export const ChatStore = createContext(initialState as unknown as ReturnType<typeof React['useReducer']>);

export const ChatProvider: React.FC = ({ children }) => {
  const value = useReducer(reducer, initialState);

  return <ChatStore.Provider value={value}>{children}</ChatStore.Provider>;
};

export default ChatProvider;
