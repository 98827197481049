import { IAppointment } from "../models/index";
export const sortAppointmentsAsc = (appointments: IAppointment[]): IAppointment[] => {
  return [...appointments]
    .sort((a, b) => {
      if (a.startDate < b.startDate) {
        return -1;
      } else if (a.startDate > b.startDate) {
        return 1;
      } else {
        return 0;
      }
    })
    .sort((a, b) => {
      if (a.endDate < b.endDate) {
        return -1;
      } else if (a.endDate > b.endDate) {
        return 1;
      } else {
        return 0;
      }
    });
};

export const sortAppointmentsDesc = (appointments: IAppointment[]) => {
    return [...appointments]
      .sort((a, b) => {
        if (a.startDate < b.startDate) {
          return -1;
        } else if (a.startDate > b.startDate) {
          return 1;
        } else {
          return 0;
        }
      })
      .sort((a, b) => {
        if (a.endDate > b.endDate) {
          return -1;
        } else if (a.endDate < b.endDate) {
          return 1;
        } else {
          return 0;
        }
      });
  };
  